.video-banner {
  position: relative;
  width: 100vw;
  height: calc(99.9vh - 48px);
  padding-top: 48px;
  overflow: hidden;
  margin-bottom: 100px;

  .cursor-change {
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  video {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: 0.4s;

    &.desktop {
      display: block;
    }

    &.mobile {
      display: none;
    }
  }

  .text-wrapper {
    position: absolute;
    opacity: 0;

    &.start {
      animation: start-show-text 1.3s ease-in 0s normal;
      animation-fill-mode: forwards;
    }

    h2 {
      font-family: $Gosha-b;
      color: rgba(255, 255, 255, 0);
      font-size: 111px;
      text-transform: uppercase;
      line-height: 95px;
      letter-spacing: 2.3px;
      -webkit-text-stroke: 1px $white;

      &.glitch-it {
        position: relative;
        padding: 10px;

        &.animation {
          &:before {
            animation: glitch-it-before 1s linear 0s normal;
          }

          &:after{
            animation: glitch-it-after 1s linear 0s normal;
          }
        }

        &:before,
        &:after{
          content: attr(data-text);
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          color: $black;
          overflow: hidden;
          padding: 10px;
        }

        &:before {
          left: 2px;
          text-shadow: -2px 0 red;
          opacity: 0;
          -webkit-text-stroke: 1px $white;
        }

        &:after{
          left: -2px;
          text-shadow: -2px 0 blue;
          opacity: 0;
          -webkit-text-stroke: 1px $white;
        }
      }
    }

    p {
      font-size: 135px;
      line-height: 99px;
      letter-spacing: 3.3px;
      margin-top: 42px;
    }
  }

  @media screen and(max-width: 2000px) {
    video {
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and(max-width: 1800px) {
    .text-wrapper {
      bottom: 30px;
      left: 30px;

      h2 {
        font-size: 80px;
        line-height: 60px;
        letter-spacing: 2px;
      }
    }
  }
  @media screen and(max-width: 1199px) {
    .text-wrapper {
      h2 {
        font-size: 70px;
        line-height: 50px;
      }
    }
  }
  @media screen and(max-width: 991px) {
    .text-wrapper {
      h2 {
        font-size: 60px;
        line-height: 40px;
      }
    }
  }
  @media screen and(max-width: 768px) {
    margin: 0;

    video {
      &.desktop {
        display: none;
      }

      &.mobile {
        height: auto!important;
        width: 100%!important;
        display: block;
      }
    }

    .text-wrapper {
      h2 {
        font-size: 45px;
        line-height: 35px;
      }

      &.start {
        animation: start-show-text-mobile 1.3s ease-in 0s normal;
        animation-fill-mode: forwards;
      }
    }
  }
  @media screen and(max-width: 575px) {
    .text-wrapper {
      bottom: 50%;
      transform: translateY(50%);
      left: 25px;

      h2 {
        font-size: 34px;
        line-height: 30px;
        letter-spacing: 1px;
      }
    }
  }
}
