footer {
  padding: 167px 0 170px;

  &.white {
    background: $white;
    .container {
      .left-side {
        .navigation {
          li {
            a {
              color: $black;
            }
          }
        }
        .logo-copyright {
          &:after {
            background-color: $black;
          }
          .logo {
            font-weight: 600;
            p {
              color: $black;
            }
            span {
              border-color: $black;
            }
          }
        }
      }
      .social-block {
        .locate,
        .phone {
          color: $black;
        }
      }
    }
  }

  .container {
    justify-content: space-between;
    padding: 0 470px;
    width: calc(100% - 940px);

    .left-side {
      display: flex;

      .logo-copyright {
        padding-right: 94px;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          display: block;
          width: 2px;
          height: 100%;
          background: $white;
          right: 0;
          bottom: 0;
        }
        p {
          margin-top: 50px;
          font-size: 16px;
          font-family: $Gosha-r;
        }
        .logo {
          p {
            margin: 0;
            font-size: 32px;
            letter-spacing: 0.7px;
          }

          span {
            width: 28px;
            height: 28px;
            border: 2px solid $white;
            margin-bottom: -7px;
          }
        }
      }

      .navigation {
        margin-left: 90px;
        margin-top: 5px;

        li {
          margin-bottom: 15px;

          &:last-child {
            margin: 0;
          }

          a {
            font-size: 18px;
            font-family: $Gosha-r;
            color: $white;
          }
        }
      }
    }

    .social-block {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 5px;

      a,
      .locate {
        font-family: $Gosha-r;
        color: $white;
        font-size: 18px;
        display: block;
        margin-bottom: 15px;
      }

      .social-links {
        display: flex;
        margin: 30px -15px 0;

        .single-link {
          margin: 0 15px;
        }
      }

    }
  }

  @media screen and(max-width: 2000px) {
    .container {
      padding: 0;
      width: calc(100% - 90px);

      .left-side {
        margin-left: 230px;
      }

      .social-block {
        margin-right: 230px;
      }
    }
  }

  @media screen and(max-width: 1700px) {
    .container {
      .left-side {
        margin-left: 130px;
      }

      .social-block {
        margin-right: 130px;
      }
    }
  }

  @media screen and(max-width: 1500px) {
    padding: 142px 0 136px;
  }

  @media screen and(max-width: 1400px) {
    .container {
      .left-side {
        margin-left: 100px;
      }

      .social-block {
        margin-right: 100px;
      }
    }
  }

  @media screen and(max-width: 1300px) {
    .container {
      .left-side,
      .social-block {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media screen and(max-width: 991px) {
    display: none;
  }
}
