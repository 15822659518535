$transition: .3s ease;

//Fonts
$Gosha-b: Gosha-b, Verdana;
$Gosha-r: Gosha-r, Verdana;
$Gilroy-r: Gilroy-r, Verdana;

//stack colors
$white: #fff;
$black: #161516;

//Primary colors
$p-dark-grey: #252526;
$p-middle-grey: #808285;
$p-light-grey: #E6E7E8;
$p-gold: #BEAF87;
