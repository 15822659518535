p,
h1,
h2,
h3,
h4,
ul,
li,
span,
label,
input,
textarea,
a {
	margin: 0;
	padding: 0;
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}

textarea {
	resize: none;
}

input {
	outline: none;
}

ul {
	list-style: none;
}

html {
	overflow-x: hidden;
	&::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}

	&::-webkit-scrollbar-button {
		background-color: #666;
	}

	&::-webkit-scrollbar-track {
		background-color: #999;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: #ffffff;
	}

	&::-webkit-scrollbar-thumb {
		height: 50px;
		background-color: #666;
		border-radius: 3px;
	}

	&::-webkit-scrollbar-corner {
		background-color: #999;
	}

	&::-webkit-resizer {
		background-color: #666;
	}
}

body {
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: $Gosha-r;
	color: $white;
	background: $black;
	cursor: none !important;
	a {
		cursor: none !important;
	}
	&.white {
		background: $white;
		color: $black;
	}
}

.hide-this {
	position: absolute;
	left: -3000vw;
}
