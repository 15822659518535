.works-page {
  background: #fff;
  padding-top: 133px;

  header {
    position: absolute;
    left: 0;
    top: 48px;
    width: calc(100% - 90px);
    z-index: 2;
    padding: 0 45px;

    .logo {
      p {
        font-family: $Gosha-b;
        color: $black;
        font-size: 18px;
      }

      span {
        width: 16px;
        height: 16px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid $black;
        margin-left: 9px;
        margin-bottom: -3px;
      }
    }

    .burger-menu {
      width: 46px;
      height: 46px;
      border: 2px solid $black;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      z-index: 2;

      .rect {
        width: 16px;
        height: 16px;
        position: relative;
        transform: rotate(45deg);
      }

      .dot {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50px;
        background: $black;
        display: block;
        transition: 1s cubic-bezier(0, .36, 1, .63);

        &:nth-child(1) {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(2) {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        &:nth-child(3) {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(4) {
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      &.animate-cross {
        border: 2px solid $white;
        .dot {
          background: $white;
          &:nth-child(1) {
            height: 100%;
            width: 3px;
          }

          &:nth-child(2) {
            width: 100%;
            height: 3px;
          }

          &:nth-child(3) {
            height: 100%;
            width: 3px;
          }

          &:nth-child(4) {
            width: 100%;
            height: 3px;
          }
        }
      }
    }

    .navigation {
      position: absolute;
      background: $black;
      top: -50px;
      left: 200vw;
      width: 100vw;
      height: calc(100vh + 50px);
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      transform: rotate(-180deg);
      transition: 1s ease-in-out;

      &.show {
        left: 0;
      }

      .logo {
        display: none;
      }

      .close-cross {
        width: 46px;
        height: 46px;
        border: 2px solid #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 50px;
        left: 50px;
        z-index: 2;
        box-sizing: border-box;

        &:after,
        &:before {
          content: '';
          position: absolute;
          background: $white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          border-radius: 1px;
        }

        &:after {
          width: 16px;
          height: 4px;
        }

        &:before {
          width: 4px;
          height: 16px;
        }
      }

      .link-wrapper {
        writing-mode: tb;
        margin: 46px 80px 0;

        a {
          font-family: $Gosha-b;
          color: $white;
          font-size: 180px;
          text-transform: capitalize;
          transition: 0.4s;
          position: relative;

          &:after {
            content: '';
            width: 2px;
            position: absolute;
            height: calc(100% + 140px);
            background: $white;
            top: 0;
            right: 0;
          }

          &:hover {
            color: rgba(255, 255, 255, 0);
            -webkit-text-stroke: 1px $white;
          }
        }

        &:last-child {
          margin: 46px 80px 0 160px;

          a {
            &:before {
              content: '';
              width: 2px;
              position: absolute;
              height: calc(100% - 100px);
              background: $white;
              top: 0;
              left: -160px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 2000px) {
      .navigation {
        .link-wrapper {
          a {
            font-size: 120px;
          }
        }
      }
    }

    @media screen and(max-width: 1800px) {
      .navigation {
        .link-wrapper {
          margin: 40px 50px 0;

          a {
            font-size: 100px;

            &:after {
              left: -50px;
              height: calc(100% + 80px);
            }

            &:last-child {
              margin: 40px 50px 0;

              a {
                &:before {
                  left: -50px;
                  height: calc(100% - 60px);
                }
              }
            }
          }

          &:last-child {
            margin: 40px 50px 160px;
            a {
              &:before {
                left: -80px;
              }
            }
          }
        }
      }

      @media screen and(max-width: 1400px) {
        .navigation {
          .link-wrapper {
            a {
              font-size: 80px;
            }
          }
        }
      }

      @media screen and(max-width: 991px) {
        .navigation {
          .link-wrapper {
            margin: 40px 40px 0;

            &:last-child {
              margin: 40px 40px 0;

              a {
                &:before {
                  left: -40px;
                }
              }
            }

            a {
              font-size: 60px;
            }
          }
        }
      }

      @media screen and(max-width: 767px) {
        .navigation {
          transform: rotate(0);
          flex-direction: column;

          .close-cross {
            bottom: unset;
            left: unset;
            top: 50px;
            right: 50px;
          }

          .link-wrapper {
            writing-mode: initial;
            margin: 30px 0 30px 50px;

            &:last-child {
              a {
                &:before {
                  width: 100%;
                  height: 1.5px;
                  right: unset;
                  left: 0;
                  top: unset;
                  bottom: -40px;
                }
              }
            }

            a {
              &:after {
                left: 0;
                right: unset;
                width: 120%;
                height: 1px;
              }
            }
          }
        }
      }

      @media screen and(max-width: 575px) {
        top: 30px;
        width: calc(100% - 50px);
        padding: 0 25px;

        .navigation {
          overflow-y: auto;
          top: -30px;

          .logo {
            display: flex;
            margin: 0 0 70px 24px;
          }

          .close-cross {
            top: 28px;
            right: 24px;
          }

          .link-wrapper {
            margin: 20px 0 20px 24px;
            height: 70px;

            a {
              height: 70px;
            }

            &:last-child {
              margin: 20px 0 20px 24px;

              a {
                &:before {
                  bottom: -30px;
                }
              }
            }

            a {
              font-size: 48px;
            }

            &:hover {
              a {
                font-size: 58px;
              }
            }
          }
        }
      }
    }
  }


  .title-page {
    font-size: 105px;
    color: $black;
    font-family: Gosha-b, Verdana;
    padding-left: 222px;
    letter-spacing: 2px;
    @media screen and(max-width: 1600px) {
      padding-left: 200px;
      font-size: 80px;
    }
    @media screen and(max-width: 1500px) {
      padding-left: 150px;
    }
    @media screen and(max-width: 1400px) {
      padding-left: 100px;
    }
    @media screen and(max-width: 1300px) {
      padding-left: 80px;
    }
    @media screen and(max-width: 1200px) {
      padding-left: 0;
    }
    @media screen and(max-width: 991px) {
      max-width: 100%;
      font-size: 60px;
    }
  }

  .contact-form {
    width: 100vw;

    .container {
      padding: 272px 0;
      background: $black;

      .inner-wrapper {
        flex-wrap: wrap;
        padding: 0 230px;
        width: 100%;
      }
    }

    .title {
      color: $white;
      font-size: 36px;
      font-family: $Gosha-b;
      letter-spacing: 0;
      line-height: 49px;
    }

    .feedback-wrapper {
      width: 100%;
      display: flex;
      color: $black;
      margin-top: 61px;
      justify-content: space-between;

      .form {
        display: flex;
        flex-wrap: wrap;
        width: 904px;
        margin: 7px -20px 0;

        .input-holder {
          position: relative;
          height: 40px;
          display: flex;
          margin: 0 20px;
          width: calc(33.33% - 40px);

          label {
            position: absolute;
            left: 0;
            bottom: 12px;
            font-family: $Gosha-r;
            font-size: 18px;
            transition: 0.3s;
            color: $white;
          }

          input {
            color: $white;
            background: $black;
            border: none;
            border-bottom: 2px solid $white;
            margin: auto 0 0 0;
            width: 100%;
            font-family: $Gosha-r;
            font-size: 18px;
            &.itsFull {
              ~ label {
                font-size: 12px;
                bottom: 25px;
              }
            }
            &:focus {
              ~ label {
                font-size: 12px;
                bottom: 25px;
              }
            }
          }

          &.textarea {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 77px;

            textarea {
              outline: none;
              color: $white;
              background: $black;
              width: 100%;
              border: none;
              border-bottom: 2px solid $white;
              font-family: $Gosha-r;
              font-size: 18px;
              &.itsFull {
                ~ label {
                  font-size: 12px;
                  bottom: 45px;
                }
              }
              &:focus {
                ~ label {
                  font-size: 12px;
                  bottom: 45px;
                }
              }
            }
          }

          &.button {
            width: 150px;
            input {
              display: none;
            }

            label {
              position: unset;
              color: $white;
              text-transform: uppercase;
              font-family: $Gosha-b;
              font-size: 16px;
              letter-spacing: 2px;
              cursor: none;
            }

            border: none;
            padding: 0;

            &:hover {
              background: none;
              color: $black;
            }
          }
        }

        .button {
          color: $white;
          border: 2px solid $white;
          text-transform: uppercase;
          font-family: $Gosha-b;
          font-size: 16px;
          margin: 0 20px;
          letter-spacing: 3.9px;
          display: flex;
          align-items: center;
          padding: 0 30px;
          height: 68px;
          background: inherit;
          transition: 0.5s;
          cursor: none;

          &:hover {
            background: $black;
            color: $white
          }

          &.upload {
            border: none;
            padding: 0;
          }
        }
      }

      .contacts {
        width: 324px;

        .text {
          color: $white;
          font-size: 16px;
          font-family: $Gosha-r;
          letter-spacing: -0.32px;
          margin-bottom: 34px;
          line-height: 23px;
        }

        a,
        .locate {
          color: $white;
          font-size: 16px;
          font-family: $Gosha-b;
          letter-spacing: 0;
          margin-bottom: 10px;
          display: block;
        }

        .social {
          margin: 34px 0 18px;
        }

        .social-links {
          display: flex;
          margin: 0 -11px;

          .single-link {
            margin: 0 11px;
          }
        }
      }
    }

    @media screen and(max-width: 1800px) {
      .feedback-wrapper {
        .form {
          width: 850px;
        }

        .contacts {
          width: 240px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .inner-wrapper {
          padding: 0 180px;
        }
      }

      .feedback-wrapper {
        .form {
          width: 800px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .inner-wrapper {
          padding: 0 130px;
        }
      }
    }

    @media screen and(max-width: 1400px) {
      height: auto;

      .container {
        padding: 202px 0 217px;

        .inner-wrapper {
          padding: 0 100px;

          .title {
            font-size: 30px;
          }
        }
      }

      .feedback-wrapper {
        .form {
          width: 750px;
        }

        .contacts {
          margin-top: 14px;
          width: 268px;
        }
      }
    }

    @media screen and(max-width: 1300px) {
      .container {
        position: relative;
        padding: 202px 20px 217px;
        max-width: calc(100% - 130px);

        &:after {
          content: '';
          display: block;
          width: calc(100vw - 90px);
          height: calc(100% + 1px);
          background: $white;
          position: absolute;
          top: -1px;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
        .inner-wrapper {
          padding: 0;

          .title {
            br {
              display: none;
            }
          }
        }
      }

      .feedback-wrapper {
        .form {
          width: calc(100% - 268px);
        }

        .contacts {
          margin-top: 40px;
        }
      }
    }

    @media screen and(max-width: 991px) {
      .feedback-wrapper {
        flex-direction: column;

        .form {
          width: calc(100% + 40px);
        }
      }
    }
    @media screen and(max-width: 767px) {
      .feedback-wrapper {
        .form {
          width: 100%;
          margin: 0;

          .input-holder {
            width: 100%;
            margin: 0 0 40px;
          }

          .button {
            margin: 0 40px 0 0;
          }
        }
      }
    }

    @media screen and(max-width: 575px) {
      background: $black;

      .container {
        max-width: calc(100% - 48px);
        padding: 115px 0 90px;

        &:after {
          width: 100vw;
          top: 0;
        }
      }

      .title {
        font-size: 30px;
      }

      .feedback-wrapper {
        margin-top: 60px;

        .contacts {
          width: 100%;
        }

        .form {
          flex-direction: column;
          align-items: center;

          .button {
            width: 100%;
            align-items: center;
            justify-content: center;
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }
  
  .works-list {
    margin-top: 155px;
    margin-bottom: 192px;

    .works-wrapper {
      z-index: 1;
      max-width: 75%;
      width: 100%;
      margin-left: 226px;

      .single-work {
        transition: 0.6s;
        white-space: nowrap;
        height: unset;
        border-bottom: 3px solid $black;
        margin-bottom: 28px;
        padding-bottom: 24px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          font-family: $Gosha-b;
          font-size: 60px;
          line-height: 65px;
          color: $black;
          margin-bottom: 55px;
          transition: 0.5s;
        }

        .subtitle {
          font-family: $Gilroy-r;
          font-size: 16px;
          color: $black;
          transition: 1s;
          white-space: normal;
          max-width: 460px;
          margin-left: 5px;
        }

        .single-work-img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 718px;
          opacity: 0;
          transition: 0.3s;
          pointer-events: none;
          width: 820px;
          height: 820px;
          z-index: 0;
          overflow: hidden;

          .title {
            &.second_title {
              position: absolute;
              top: calc(50% - 36px);
              left: -715px;
              transform: translateY(-50%);
              -webkit-text-stroke: 1px $white;
            }
          }

          .subtitle {
            &.second_subtitle {
              display: none;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &.active {
          z-index: 2;
          .title {
            position: relative;
            font-size: 98px;
            letter-spacing: -1.6px;
            line-height: 0.9;
            margin-bottom: 32px;
            -webkit-text-stroke: 1px $black;
            color: rgba(0, 0, 0, 0);
          }
          .single-work-img {
            z-index: 1;
            opacity: 1;
          }
        }
      }
    }

    .single-work-img {
      position: absolute;
      top: 0;
      right: 45px;
      opacity: 1;
      transition: 0.2s;
      pointer-events: none;
      width: 820px;
      height: 820px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    @media screen and(max-width: 1600px) {
      .works-wrapper {
        margin-left: 200px;

        .single-work {
          .title {
            font-size: 50px;
            margin-bottom: 25px;
          }

          .single-work-img {
            left: 650px;
            .title.second_title {
              left: -647px;
            }
          }

          &.active {
            .title {
              font-size: 80px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 1500px) {
      .works-wrapper {
        margin-left: 150px;
      }
    }

    @media screen and(max-width: 1400px) {
      .works-wrapper {
        margin-left: 100px;
        .single-work {
          .single-work-img {
            width: 620px;
            height: 620px;
          }
        }
      }
    }

    @media screen and(max-width: 1300px) {
      .works-wrapper {
        margin-left: 80px;
      }
    }

    @media screen and(max-width: 1200px) {
      .works-wrapper {
        margin-left: 0;
        max-width: 100%;
      }
    }

    @media screen and(max-width: 1100px) {
      .works-wrapper {
        .single-work {
          .title {
            font-size: 45px;
          }

          .single-work-img {
            left: 550px;
            .title.second_title {
              left: -550px;
            }
          }

          &.active {
            .title {
              font-size: 70px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 991px) {
      .works-wrapper {
        .single-work {
          .title {
            font-size: 40px;
          }

          .single-work-img {
            left: 450px;
            width: 500px;
            height: 500px;
            .title.second_title {
              left: -450px;
            }
          }

          &.active {
            .title {
              font-size: 65px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 768px) {
      .works-wrapper {
        .single-work {
          .title {
            font-size: 40px;
            letter-spacing: -1.6px;
            line-height: 0.9;
          }

          .single-work-img {
            left: 387px;
            top: calc(50% + 2px);
            .title.second_title {
              color: $white;
              left: -387px;
              -webkit-text-stroke: unset;
            }
          }

          &.active {
            .title {
              letter-spacing: -1.6px;
              line-height: 0.9;
              margin-bottom: 25px;
              font-size: 40px;
              color: $black;
              -webkit-text-stroke: unset;
            }
          }
        }
      }
    }

    @media screen and(max-width: 685px) {
      .works-wrapper {
        .single-work {
          .title {
            font-size: 20px;
          }

          .single-work-img {
            left: 270px;
            .title.second_title {
              left: -270px;
            }
            .subtitle {
              &.second_subtitle {
                color: $white;
                position: absolute;
                top: calc(50% + 8px);
                transform: translateY(-50%);
                left: -270px;
                display: block;
              }
            }
          }

          &.active {
            .title {
              font-size: 20px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 475px) {
      margin-top: 102px;
      margin-bottom: 108px;

      .works-wrapper {
        .single-work {
          margin-bottom: 70px;
          .title {
            width: 272px;
            letter-spacing: -0.1px;
          }
          .subtitle {
            width: 272px;
            margin: 0;
          }

          .single-work-img {
            width: 277px;
            height: 277px;
            left: 157px;
            .title {
              &.second_title {
                left: -157px;
                top: calc(50% - 45.2px);
              }
            }
            .subtitle {
              &.second_subtitle {
                width: 272px;
                left: -157px;
              }
            }
          }
          &.active {
            .title {
              letter-spacing: -0.1px;
            }
          }
        }
      }
    }
  }

  footer {
    padding: 167px 0 170px;
    background: $white;

    .container {
      justify-content: space-between;
      padding: 0 470px;
      width: calc(100% - 940px);

      .left-side {
        display: flex;

        .logo-copyright {
          padding-right: 94px;
          position: relative;

          &:after {
            position: absolute;
            content: '';
            display: block;
            width: 2px;
            height: 100%;
            background-color: $black;
            right: 0;
            bottom: 0;
          }
          p {
            margin-top: 50px;
            font-size: 16px;
            font-family: $Gosha-r;
          }
          .logo {
            font-weight: 600;
            p {
              color: $black;
              margin: 0;
              font-size: 32px;
              letter-spacing: 0.7px;
            }

            span {
              width: 28px;
              height: 28px;
              border: 2px solid $black;
              margin-bottom: -7px;
            }
          }
        }

        .navigation {
          margin-left: 90px;
          margin-top: 5px;

          li {
            margin-bottom: 15px;

            &:last-child {
              margin: 0;
            }

            a {
              font-size: 18px;
              font-family: $Gosha-r;
              color: $black;
            }
          }
        }
      }

      .social-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 5px;

        a,
        .locate {
          font-family: $Gosha-r;
          color: $black;
          font-size: 18px;
          display: block;
          margin-bottom: 15px;
        }

        .social-links {
          display: flex;
          margin: 30px -15px 0;

          .single-link {
            margin: 0 15px;
          }
        }

      }
    }

    @media screen and(max-width: 2000px) {
      .container {
        padding: 0;
        width: calc(100% - 90px);

        .left-side {
          margin-left: 230px;
        }

        .social-block {
          margin-right: 230px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .left-side {
          margin-left: 130px;
        }

        .social-block {
          margin-right: 130px;
        }
      }
    }

    @media screen and(max-width: 1500px) {
      padding: 142px 0 136px;
    }

    @media screen and(max-width: 1400px) {
      .container {
        .left-side {
          margin-left: 100px;
        }

        .social-block {
          margin-right: 100px;
        }
      }
    }

    @media screen and(max-width: 1300px) {
      .container {
        .left-side,
        .social-block {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    @media screen and(max-width: 991px) {
      display: none;
    }
  }

}
