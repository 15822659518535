.development-page {
  background: #fff;
  padding-top: 133px;

  header {
    position: absolute;
    left: 0;
    top: 48px;
    width: calc(100% - 90px);
    z-index: 2;
    padding: 0 45px;

    .logo {
      p {
        font-family: $Gosha-b;
        color: $black;
        font-size: 18px;
      }

      span {
        width: 16px;
        height: 16px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid $black;
        margin-left: 9px;
        margin-bottom: -3px;
      }
    }

    .burger-menu {
      width: 46px;
      height: 46px;
      border: 2px solid $black;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      z-index: 2;

      .rect {
        width: 16px;
        height: 16px;
        position: relative;
        transform: rotate(45deg);
      }

      .dot {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50px;
        background: $black;
        display: block;
        transition: 1s cubic-bezier(0, .36, 1, .63);

        &:nth-child(1) {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(2) {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        &:nth-child(3) {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(4) {
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      &.animate-cross {
        border: 2px solid $white;
        .dot {
          background: $white;
          &:nth-child(1) {
            height: 100%;
            width: 3px;
          }

          &:nth-child(2) {
            width: 100%;
            height: 3px;
          }

          &:nth-child(3) {
            height: 100%;
            width: 3px;
          }

          &:nth-child(4) {
            width: 100%;
            height: 3px;
          }
        }
      }
    }

    .navigation {
      position: absolute;
      background: $black;
      top: -50px;
      left: 200vw;
      width: 100vw;
      height: calc(100vh + 50px);
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      transform: rotate(-180deg);
      transition: 1s ease-in-out;

      &.show {
        left: 0;
      }

      .logo {
        display: none;
      }

      .close-cross {
        width: 46px;
        height: 46px;
        border: 2px solid #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 50px;
        left: 50px;
        z-index: 2;
        box-sizing: border-box;

        &:after,
        &:before {
          content: '';
          position: absolute;
          background: $white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          border-radius: 1px;
        }

        &:after {
          width: 16px;
          height: 4px;
        }

        &:before {
          width: 4px;
          height: 16px;
        }
      }

      .link-wrapper {
        writing-mode: tb;
        margin: 46px 80px 0;

        a {
          font-family: $Gosha-b;
          color: $white;
          font-size: 180px;
          text-transform: capitalize;
          transition: 0.4s;
          position: relative;

          &:after {
            content: '';
            width: 2px;
            position: absolute;
            height: calc(100% + 140px);
            background: $white;
            top: 0;
            right: 0;
          }

          &:hover {
            color: rgba(255, 255, 255, 0);
            -webkit-text-stroke: 1px $white;
          }
        }

        &:last-child {
          margin: 46px 80px 0 160px;

          a {
            &:before {
              content: '';
              width: 2px;
              position: absolute;
              height: calc(100% - 100px);
              background: $white;
              top: 0;
              left: -160px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 2000px) {
      .navigation {
        .link-wrapper {
          a {
            font-size: 120px;
          }
        }
      }
    }

    @media screen and(max-width: 1800px) {
      .navigation {
        .link-wrapper {
          margin: 40px 50px 0;

          a {
            font-size: 100px;

            &:after {
              left: -50px;
              height: calc(100% + 80px);
            }

            &:last-child {
              margin: 40px 50px 0;

              a {
                &:before {
                  left: -50px;
                  height: calc(100% - 60px);
                }
              }
            }
          }

          &:last-child {
            margin: 40px 50px 160px;
            a {
              &:before {
                left: -80px;
              }
            }
          }
        }
      }

      @media screen and(max-width: 1400px) {
        .navigation {
          .link-wrapper {
            a {
              font-size: 80px;
            }
          }
        }
      }

      @media screen and(max-width: 991px) {
        .navigation {
          .link-wrapper {
            margin: 40px 40px 0;

            &:last-child {
              margin: 40px 40px 0;

              a {
                &:before {
                  left: -40px;
                }
              }
            }

            a {
              font-size: 60px;
            }
          }
        }
      }

      @media screen and(max-width: 767px) {
        .navigation {
          transform: rotate(0);
          flex-direction: column;

          .close-cross {
            bottom: unset;
            left: unset;
            top: 50px;
            right: 50px;
          }

          .link-wrapper {
            writing-mode: initial;
            margin: 30px 0 30px 50px;

            &:last-child {
              a {
                &:before {
                  width: 100%;
                  height: 1.5px;
                  right: unset;
                  left: 0;
                  top: unset;
                  bottom: -40px;
                }
              }
            }

            a {
              &:after {
                left: 0;
                right: unset;
                width: 120%;
                height: 1px;
              }
            }
          }
        }
      }

      @media screen and(max-width: 575px) {
        top: 30px;
        width: calc(100% - 50px);
        padding: 0 25px;

        .navigation {
          overflow-y: auto;
          top: -30px;

          .logo {
            display: flex;
            margin: 0 0 70px 24px;
          }

          .close-cross {
            top: 28px;
            right: 24px;
          }

          .link-wrapper {
            margin: 20px 0 20px 24px;
            height: 70px;

            a {
              height: 70px;
            }

            &:last-child {
              margin: 20px 0 20px 24px;

              a {
                &:before {
                  bottom: -30px;
                }
              }
            }

            a {
              font-size: 48px;
            }

            &:hover {
              a {
                font-size: 58px;
              }
            }
          }
        }
      }
    }
  }

  .contact-form {
    width: 100vw;

    .container {
      padding: 272px 0;
      background: $black;

      .inner-wrapper {
        flex-wrap: wrap;
        padding: 0 230px;
        width: 100%;
      }
    }

    .title {
      color: $white;
      font-size: 36px;
      font-family: $Gosha-b;
      letter-spacing: 0;
      line-height: 49px;
    }

    .feedback-wrapper {
      width: 100%;
      display: flex;
      color: $black;
      margin-top: 61px;
      justify-content: space-between;

      .form {
        display: flex;
        flex-wrap: wrap;
        width: 904px;
        margin: 7px -20px 0;

        .input-holder {
          position: relative;
          height: 40px;
          display: flex;
          margin: 0 20px;
          width: calc(33.33% - 40px);

          label {
            position: absolute;
            left: 0;
            bottom: 12px;
            font-family: $Gosha-r;
            font-size: 18px;
            transition: 0.3s;
            color: $white;
          }

          input {
            color: $white;
            background: $black;
            border: none;
            border-bottom: 2px solid $white;
            margin: auto 0 0 0;
            width: 100%;
            font-family: $Gosha-r;
            font-size: 18px;
            &.itsFull {
              ~ label {
                font-size: 12px;
                bottom: 25px;
              }
            }
            &:focus {
              ~ label {
                font-size: 12px;
                bottom: 25px;
              }
            }
          }

          &.textarea {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 77px;

            textarea {
              outline: none;
              color: $white;
              background: $black;
              width: 100%;
              border: none;
              border-bottom: 2px solid $white;
              font-family: $Gosha-r;
              font-size: 18px;
              &.itsFull {
                ~ label {
                  font-size: 12px;
                  bottom: 45px;
                }
              }
              &:focus {
                ~ label {
                  font-size: 12px;
                  bottom: 45px;
                }
              }
            }
          }

          &.button {
            width: 150px;
            input {
              display: none;
            }

            label {
              position: unset;
              color: $white;
              text-transform: uppercase;
              font-family: $Gosha-b;
              font-size: 16px;
              letter-spacing: 2px;
              cursor: none;
            }

            border: none;
            padding: 0;

            &:hover {
              background: none;
              color: $black;
            }
          }
        }

        .button {
          color: $white;
          border: 2px solid $white;
          text-transform: uppercase;
          font-family: $Gosha-b;
          font-size: 16px;
          margin: 0 20px;
          letter-spacing: 3.9px;
          display: flex;
          align-items: center;
          padding: 0 30px;
          height: 68px;
          background: inherit;
          transition: 0.5s;
          cursor: none;

          &:hover {
            background: $black;
            color: $white
          }

          &.upload {
            border: none;
            padding: 0;
          }
        }
      }

      .contacts {
        width: 324px;

        .text {
          color: $white;
          font-size: 16px;
          font-family: $Gosha-r;
          letter-spacing: -0.32px;
          margin-bottom: 34px;
          line-height: 23px;
        }

        a,
        .locate {
          color: $white;
          font-size: 16px;
          font-family: $Gosha-b;
          letter-spacing: 0;
          margin-bottom: 10px;
          display: block;
        }

        .social {
          margin: 34px 0 18px;
        }

        .social-links {
          display: flex;
          margin: 0 -11px;

          .single-link {
            margin: 0 11px;
          }
        }
      }
    }

    @media screen and(max-width: 1800px) {
      .feedback-wrapper {
        .form {
          width: 850px;
        }

        .contacts {
          width: 240px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .inner-wrapper {
          padding: 0 180px;
        }
      }

      .feedback-wrapper {
        .form {
          width: 800px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .inner-wrapper {
          padding: 0 130px;
        }
      }
    }

    @media screen and(max-width: 1400px) {
      height: auto;

      .container {
        padding: 202px 0 217px;

        .inner-wrapper {
          padding: 0 100px;

          .title {
            font-size: 30px;
          }
        }
      }

      .feedback-wrapper {
        .form {
          width: 750px;
        }

        .contacts {
          margin-top: 14px;
          width: 268px;
        }
      }
    }

    @media screen and(max-width: 1300px) {
      .container {
        position: relative;
        padding: 202px 20px 217px;
        max-width: calc(100% - 130px);

        &:after {
          content: '';
          display: block;
          width: calc(100vw - 90px);
          height: calc(100% + 1px);
          background: $white;
          position: absolute;
          top: -1px;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
        .inner-wrapper {
          padding: 0;

          .title {
            br {
              display: none;
            }
          }
        }
      }

      .feedback-wrapper {
        .form {
          width: calc(100% - 268px);
        }

        .contacts {
          margin-top: 40px;
        }
      }
    }

    @media screen and(max-width: 991px) {
      .feedback-wrapper {
        flex-direction: column;

        .form {
          width: calc(100% + 40px);
        }
      }
    }
    @media screen and(max-width: 767px) {
      .feedback-wrapper {
        .form {
          width: 100%;
          margin: 0;

          .input-holder {
            width: 100%;
            margin: 0 0 40px;
          }

          .button {
            margin: 0 40px 0 0;
          }
        }
      }
    }

    @media screen and(max-width: 575px) {
      background: $black;

      .container {
        max-width: calc(100% - 48px);
        padding: 115px 0 90px;

        &:after {
          width: 100vw;
          top: 0;
        }
      }

      .title {
        font-size: 30px;
      }

      .feedback-wrapper {
        margin-top: 60px;

        .contacts {
          width: 100%;
        }

        .form {
          flex-direction: column;
          align-items: center;

          .button {
            width: 100%;
            align-items: center;
            justify-content: center;
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }

  footer {
    padding: 167px 0 170px;
    background: $white;

    .container {
      justify-content: space-between;
      padding: 0 470px;
      width: calc(100% - 940px);

      .left-side {
        display: flex;

        .logo-copyright {
          padding-right: 94px;
          position: relative;

          &:after {
            position: absolute;
            content: '';
            display: block;
            width: 2px;
            height: 100%;
            background-color: $black;
            right: 0;
            bottom: 0;
          }
          p {
            margin-top: 50px;
            font-size: 16px;
            font-family: $Gosha-r;
          }
          .logo {
            font-weight: 600;
            p {
              color: $black;
              margin: 0;
              font-size: 32px;
              letter-spacing: 0.7px;
            }

            span {
              width: 28px;
              height: 28px;
              border: 2px solid $black;
              margin-bottom: -7px;
            }
          }
        }

        .navigation {
          margin-left: 90px;
          margin-top: 5px;

          li {
            margin-bottom: 15px;

            &:last-child {
              margin: 0;
            }

            a {
              font-size: 18px;
              font-family: $Gosha-r;
              color: $black;
            }
          }
        }
      }

      .social-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 5px;

        a,
        .locate {
          font-family: $Gosha-r;
          color: $black;
          font-size: 18px;
          display: block;
          margin-bottom: 15px;
        }

        .social-links {
          display: flex;
          margin: 30px -15px 0;

          .single-link {
            margin: 0 15px;
          }
        }

      }
    }

    @media screen and(max-width: 2000px) {
      .container {
        padding: 0;
        width: calc(100% - 90px);

        .left-side {
          margin-left: 230px;
        }

        .social-block {
          margin-right: 230px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .left-side {
          margin-left: 130px;
        }

        .social-block {
          margin-right: 130px;
        }
      }
    }

    @media screen and(max-width: 1500px) {
      padding: 142px 0 136px;
    }

    @media screen and(max-width: 1400px) {
      .container {
        .left-side {
          margin-left: 100px;
        }

        .social-block {
          margin-right: 100px;
        }
      }
    }

    @media screen and(max-width: 1300px) {
      .container {
        .left-side,
        .social-block {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    @media screen and(max-width: 991px) {
      display: none;
    }
  }

  .main-banner {
    margin-bottom: 150px;
    margin-top: 20px;
    .banner-wrapper {
      min-width: calc(100vw - 90px);
      padding: 0 45px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      min-height: 780px;
      .left-side {
        padding-left: 180px;
        margin-right: 56px;
        height: 360px;
        .title, .text, .button {
          color: $black;
        }
        .title {
          margin-bottom: 8px;
          margin-left: -2px;
          font-size: 112px;
          color: rgba(255,255,255,0);
          -webkit-text-stroke: 1px $black;
        }
        .text {
          margin-bottom: 60px;
          font-size: 24px;
          line-height: 1.6;
        }
        .button {
          display: inline-block;
          font-size: 16px;
          line-height: 1;
          font-weight: 600;
          letter-spacing: 3px;
          text-transform: uppercase;
          padding: 25px 34px;
          border: 2px solid $black;
        }
      }
      .right-side {
        position: relative;
        .img-holder {
          z-index: -1;
          width: 1174px;
          height: 1174px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
            z-index: 1;
            &.decoration {
              z-index: 0;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              width: 106%;
              height: 106%;
            }
          }
        }
      }
    }
    @media screen and(max-width: 1700px) {
      margin-bottom: 130px;
      .banner-wrapper {
        .right-side {
          .img-holder {
            width: 1000px;
            height: 1000px;
          }
        }
      }
    }
    @media screen and(max-width: 1600px) {
      .banner-wrapper {
        .right-side {
          .img-holder {
            width: 900px;
            height: 900px;
          }
        }
      }
    }
    @media screen and(max-width: 1500px) {
      .banner-wrapper {
        .left-side {
          padding-left: 100px;
        }
      }
    }
    @media screen and(max-width: 1400px) {
      .banner-wrapper {
        .left-side {
          padding-left: 0;
        }
        .right-side {
          .img-holder {
            width: 800px;
            height: 800px;
          }
        }
      }
    }
    @media screen and(max-width: 1300px) {
      margin-bottom: 110px;
      .banner-wrapper {
        .left-side {
          .title {
            font-size: 90px;
          }
          .text {
            font-size: 20px;
          }
        }
        .right-side {
          .img-holder {
            width: 750px;
            height: 750px;
          }
        }
      }
    }
    @media screen and(max-width: 1200px) {
      .banner-wrapper {
        min-height: 600px;
        .left-side {
          .title {
            font-size: 85px;
          }
          .text {
            font-size: 18px;
          }
        }
        .right-side {
          .img-holder {
            right: -25px;
            width: 700px;
            height: 700px;
          }
        }
      }
    }
    @media screen and(max-width: 991px) {
      margin-bottom: 100px;
      .banner-wrapper {
        min-height: 550px;
        padding: 0;
        .left-side {
          height: 250px;
          .title {
            font-size: 70px;
          }
          .text {
            font-size: 16px;
          }
        }
        .right-side {
          .img-holder {
            right: -110px;
            width: 650px;
            height: 560px;
          }
        }
      }
    }
    @media screen and(max-width: 767px) {
      .banner-wrapper {
        flex-direction: column;
        min-height: unset;
        padding: 0;
        align-items: unset;
        .left-side {
          height: unset;
          margin: 0;
        }
        .right-side {
          .img-holder {
            position: relative;
            right: unset;
            top: unset;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: 100vw;
          }
        }
      }
    }
    @media screen and(max-width: 575px) {
      margin-bottom: 80px;
      .banner-wrapper {
        .left-side {
          .title {
            font-size: 47px;
          }
          .text {
            font-size: 16px;
          }
          .button {
            width: calc(100% - 72px);
            text-align: center;
          }
        }
      }
    }
  }

  .departments {
    margin-bottom: 100px;
    padding: 180px 0;
    background: #fff;
    #black-circle {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5000;
      mix-blend-mode: difference;
      pointer-events: none;
      opacity: 0;
      transition: 0.4s;
    }
    .departments-wrapper {
      position: relative;
      width: 1370px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .single-department {
        width: 400px;
        height: 400px;
        border: 2px solid #000;
        padding: 160px 60px 60px;
        display: flex;
        box-sizing: border-box;
        .text {
          color: $black;
          .count {
            font-size: 18px;
            margin-bottom: 30px;
          }
          .title {
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 40px;
          }
          .description {
            font-size: 16px;
          }
        }
      }
    }
    @media screen and(max-width: 1350px) {
      margin-bottom: 250px;
      .departments-wrapper {
        width: 100%;
        .single-department {
          padding: 100px 30px 30px;
          width: 320px;
          height: 320px;
          .text {
            .count, .title {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
    @media screen and(max-width: 1100px) {
      margin-bottom: 200px;
      .departments-wrapper {
        .single-department {
          padding: 120px 20px 20px;
          width: 300px;
          height: 300px;
          .text {
            .count, .title {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    @media screen and(max-width: 991px) {
      margin-bottom: 150px;
      .departments-wrapper {
        flex-direction: column;
        .single-department {
          padding: 100px 30px 30px;
          width: 100%;
          height: 320px;
          margin-bottom: 30px;
          .text {
            .count, .title {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
    @media screen and(max-width: 575px) {
      margin-bottom: 80px;
      #black-circle {
        display: none;
      }
      .departments-wrapper {
        .single-department {
          padding: 80px 30px 30px;
          height: 270px;
          .text {
            .count {
              font-size: 10px;
              margin-bottom: 40px;
            }
            .title {
              font-size: 20px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  .ticker {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 277px;

    .single-row {
      display: flex;
      animation: ticker 100s linear 0s infinite alternate;
      margin-top: 10px;
      width: fit-content;
      &:hover {
        animation-play-state:paused;
      }

      &:nth-child(odd) {
        animation: ticker 90s linear 0s infinite alternate-reverse;
        &:hover {
          animation-play-state:paused;
        }
      }

      &:nth-child(3) {
        animation: ticker 150s linear 0s infinite alternate-reverse;
        &:hover {
          animation-play-state:paused;
        }
      }

      span {
        font-family: $Gosha-b;
        font-size: 200px;
        font-weight: 300;
        line-height: 165px;
        -webkit-text-stroke: 1px $black;
        margin: 0 40px;
        display: inline-block;
        white-space: nowrap;
        text-transform: uppercase;
        transition: 0.5s;

        &:hover {
          color: $black;
        }
      }
    }

    @media screen and(max-width: 1200px) {
      .single-row {
        span {
          font-size: 180px;
          line-height: 150px;
        }
      }
    }
    @media screen and(max-width: 991px) {
      .single-row {
        span {
          font-size: 160px;
          line-height: 140px;
        }
      }
    }
    @media screen and(max-width: 767px) {
      height: auto;
      margin: 0 0 170px;
      .single-row {
        span {
          font-size: 130px;
          line-height: 120px;
          margin: 0 30px;
        }
      }
    }
    @media screen and(max-width: 575px) {
      margin: 0 0 80px;
      .single-row {
        animation: ticker 35s linear 0s infinite alternate;

        span {
          font-size: 80px;
          line-height: 70px;
          margin: 0 20px;
        }

        &:nth-child(odd) {
          animation: ticker 50s linear 0s infinite alternate-reverse;
        }

        &:nth-child(3) {
          animation: ticker 75s linear 0s infinite alternate-reverse;
        }
      }
    }
  }

  .milestones {
    margin-bottom: 140px;
    .title {
      font-size: 72px;
      color: $black;
      padding-left: 230px;
    }
    .wrapper {
      padding-left: 100px;
      color: $black;
      height: 640px;
      position: relative;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .img-holder {
        width: 1617px;
        height: 311px;
        margin: 180px 0 0 auto;
      }
      .item {
        display: inline-block;
        width: 200px;
        text-align: center;
        position: absolute;
        pointer-events: none;
        user-select: none;
        &.item-1 {
          top: 220px;
          .num {
            font-size: 56px;
          }
        }
        &.item-2 {
          top: 306px;
          .num {
            font-size: 68px;
          }
        }
        &.item-3 {
          top: 190px;
          .num {
            font-size: 74px;
          }
        }
        &.item-4 {
          top: 300px;
          .num {
            font-size: 96px;
          }
        }
        &.item-5 {
          top: 150px;
          .num {
            font-size: 109px;
          }
        }
        &.item-6 {
          top: 320px;
          .num {
            font-size: 140px;
          }
        }
        &.item-7 {
          top: 60px;
          .num {
            font-size: 162px;
          }
        }
        .num {
          display: inline-block;
          color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 1px $black;
          transition: 0.3s;
        }
        .text {
          display: inline-block;
          line-height: 1;
          margin-top: 35px;
          font-size: 18px;
        }
        &.filled {
          .num {
            color: $black;
          }
        }
      }
    }
    @media screen and(max-width: 1500px) {
      .title {
        padding-left: 150px;
        font-size: 60px;
      }
    }
    @media screen and(max-width: 1400px) {
      .title {
        padding-left: 45px;
      }
    }
    @media screen and(max-width: 991px) {
      .title {
        padding-left: 0;
        font-size: 40px;
      }
    }
    @media screen and(max-width: 575px) {
      .title {
        font-size: 20px;
      }
    }
  }

  .projects {
    margin-bottom: 285px;
    position: relative;
    .click-area {
      position: absolute;
      top: 0;
      width: 10%;
      height: 100%;
      z-index: 10;
      &.button-left {
        left: 0;
      }
      &.button-right {
        right: 0;
      }
    }
    .project-list {
      display: flex;
      justify-content: space-between;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .project-item {
        overflow: hidden;
        min-width: calc(100vw - 90px);
        padding: 0 45px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        .left-side {
          padding-left: 230px;
          margin-right: 56px;
          .subtitle {
            display: flex;
            margin-bottom: 60px;
            p {
              color: $black;
              font-size: 18px;
              &:first-child {
                margin-right: 100px;
              }
            }
          }
          .title, .text, .button {
            color: $black;
          }
          .title {
            margin-bottom: 65px;
            font-size: 60px;
          }
          .text {
            margin-bottom: 70px;
            font-size: 16px;
            line-height: 1.6;
          }
          .button {
            display: inline-block;
            font-size: 16px;
            line-height: 1;
            font-weight: 600;
            letter-spacing: 3px;
            text-transform: uppercase;
            padding: 25px 34px;
            border: 2px solid $black;
          }
        }
        .right-side {
          .img-holder {
            width: 820px;
            height: 820px;
            position: relative;
            img {
              position: relative;
              width: 100%;
              height: 100%;
              object-fit: contain;
              z-index: 1;
              &.decoration {
                z-index: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 106%;
                height: 106%;
              }
            }
          }
        }
      }
    }
    @media screen and(max-width: 1500px) {
      .project-list {
        .project-item {
          .left-side {
            padding-left: 160px;
          }
        }
      }
    }
    @media screen and(max-width: 1400px) {
      .project-list {
        .project-item {
          .left-side {
            padding-left: 100px;
          }
        }
      }
    }
    @media screen and(max-width: 1300px) {
      .project-list {
        .project-item {
          .left-side {
            box-sizing: border-box;
            width: 50%;
            margin-right: 0;
            padding-right: 50px;
          }
          .right-side {
            width: 50%;
            .img-holder {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    @media screen and(max-width: 1200px) {
      .project-list {
        .project-item {
          .left-side {
            padding-left: 0;
          }
        }
      }
    }
    @media screen and(max-width: 991px) {
      .project-list {
        .project-item {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column-reverse;
          .left-side,
          .right-side {
            width: 100%;
          }
          .left-side {
            margin-top: 55px;
            .subtitle {
              display: none;
            }
          }
        }
      }
    }
    @media screen and(max-width: 575px) {
      margin-bottom: 80px;
      .project-list {
        .project-item {
          padding: 0 24px;
          min-width: calc(100vw - 48px);
          .left-side {
            margin-top: 45px;
            .title {
              font-size: 30px;
              margin-bottom: 40px;
            }
            .text {
              font-size: 16px;
              margin-bottom: 30px;
              line-height: 18px;
            }
            .button {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  .customer-review {
    margin-bottom: 210px;
    .title {
      font-size: 180px;
      color: $black;
      text-transform: uppercase;
      white-space: nowrap;
      padding-left: 230px;
      margin-bottom: 120px;
    }
    .main-wrapper {
      position: relative;
      &:after {
        content: "";
        display: block;
        height: 100%;
        width: 20vw;
        position: absolute;
        left: 80vw;
        top: 0;
        pointer-events: none;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.8) 30%,  rgba(255,255,255,1) 100%);
      }
    }
    .reviews-wrapper {
      padding-left: 230px;
      padding-bottom: 50px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .reviews {
        display: flex;
        width: max-content;
        .single-review {
          width: 555px;
          color: $black;
          margin-right: 130px;
          .avatar {
            width: 66px;
            height: 66px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 25px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .position,
          .review,
          .link {
            font-size: 16px;
          }
          .name {
            font-size: 36px;
            margin: 0 0 20px;
          }
          .position {
            margin-bottom: 30px;
          }
          .review {
            margin-bottom: 40px;
          }
          .link {
            color: $black;
          }
        }
      }
    }
    @media screen and(max-width: 1500px) {
      .title {
        padding-left: 145px;
      }
      .reviews-wrapper {
        padding-left: 145px;
      }
    }
    @media screen and(max-width: 1400px) {
      margin-bottom: 200px;
      .title {
        padding-left: 45px;
      }
      .reviews-wrapper {
        padding-left: 45px;
      }
    }
    @media screen and(max-width: 1200px) {
      .title {
        font-size: 120px;
      }
    }
    @media screen and(max-width: 991px) {
      margin-bottom: 150px;
      .title {
        padding-left: 0;
        font-size: 100px;
      }
      .reviews-wrapper {
        padding-left: 24px;
        .reviews {
          .single-review {
            width: 400px;
          }
        }
      }
    }
    @media screen and(max-width: 575px) {
      margin-bottom: 70px;
      .title {
        font-size: 67px;
        margin-bottom: 70px;
      }
      .main-wrapper {
        position: relative;
        &:after {
          display: none;
        }
      }
      .reviews-wrapper {
        .reviews {
          .single-review {
            width: 270px;
            margin-right: 30px;
          }
        }
      }
    }
  }
}
