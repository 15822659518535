@font-face {
	font-family: 'Gosha-b';
	src: url('../fonts/Gosha/GoshaBold.eot') format('embedded-opentype'),
	url('../fonts/Gosha/GoshaBold.woff2') format('woff2'),
	url('../fonts/Gosha/GoshaBold.woff') format('woff'),
	url('../fonts/Gosha/GoshaBold.ttf') format('truetype'),
	url('../fonts/Gosha/GoshaBold.svg#OakesBold') format('svg');
	font-weight: 700;
}

@font-face {
	font-family: 'Gosha-r';
	src: url('../fonts/Gosha/GoshaRegular.eot') format('embedded-opentype'),
	url('../fonts/Gosha/GoshaRegular.woff2') format('woff2'),
	url('../fonts/Gosha/GoshaRegular.woff') format('woff'),
	url('../fonts/Gosha/GoshaRegular.ttf') format('truetype'),
	url('../fonts/Gosha/GoshaRegular.svg#OakesRegular') format('svg');
	font-weight: 300;
}

@font-face {
	font-family: 'Gilroy-r';
	src: url('../fonts/Gilroy/GilroyRegular.eot') format('embedded-opentype'),
	url('../fonts/Gilroy/GilroyRegular.woff2') format('woff2'),
	url('../fonts/Gilroy/GilroyRegular.woff') format('woff'),
	url('../fonts/Gilroy/GilroyRegular.ttf') format('truetype'),
	url('../fonts/Gilroy/GilroyRegular.svg#OakesRegular') format('svg');
	font-weight: 300;
}

