.contact-form {
  width: 100vw;

  .container {
    padding: 272px 0;
    background: $white;

    .inner-wrapper {
      flex-wrap: wrap;
      padding: 0 230px;
      width: 100%;
    }
  }

  .title {
    color: $black;
    font-size: 36px;
    font-family: $Gosha-b;
    letter-spacing: 0;
    line-height: 49px;
  }

  .feedback-wrapper {
    width: 100%;
    display: flex;
    color: $black;
    margin-top: 61px;
    justify-content: space-between;

    .form {
      display: flex;
      flex-wrap: wrap;
      width: 904px;
      margin: 7px -20px 0;

      .input-holder {
        position: relative;
        height: 40px;
        display: flex;
        margin: 0 20px;
        width: calc(33.33% - 40px);

        label {
          position: absolute;
          left: 0;
          bottom: 12px;
          font-family: $Gosha-r;
          font-size: 18px;
          transition: 0.3s;
        }

        input {
          border: none;
          border-bottom: 2px solid $black;
          margin: auto 0 0 0;
          width: 100%;
          font-family: $Gosha-r;
          font-size: 18px;
          &.itsFull {
            ~ label {
              font-size: 12px;
              bottom: 25px;
            }
          }
          &:focus {
            ~ label {
              font-size: 12px;
              bottom: 25px;
            }
          }
        }

        &.textarea {
          width: 100%;
          margin-top: 50px;
          margin-bottom: 77px;

          textarea {
            width: 100%;
            border: none;
            border-bottom: 2px solid $black;
            font-family: $Gosha-r;
            font-size: 18px;
            &.itsFull {
              ~ label {
                font-size: 12px;
                bottom: 45px;
              }
            }
            &:focus {
              ~ label {
                font-size: 12px;
                bottom: 45px;
              }
            }
          }
        }

        &.button {
          width: 150px;
          input {
            display: none;
          }

          label {
            position: unset;
            color: $black;
            text-transform: uppercase;
            font-family: $Gosha-b;
            font-size: 16px;
            letter-spacing: 2px;
            cursor: none;
          }

          border: none;
          padding: 0;

          &:hover {
            background: none;
            color: $black;
          }
        }
      }

      .button {
        color: $black;
        text-transform: uppercase;
        font-family: $Gosha-b;
        font-size: 16px;
        margin: 0 20px;
        letter-spacing: 3.9px;
        border: 2px solid $black;
        display: flex;
        align-items: center;
        padding: 0 30px;
        height: 68px;
        background: inherit;
        transition: 0.5s;
        cursor: none;

        &:hover {
          background: $black;
          color: $white
        }

        &.upload {
          border: none;
          padding: 0;
        }
      }
    }

    .contacts {
      width: 324px;

      .text {
        color: $black;
        font-size: 16px;
        font-family: $Gosha-r;
        letter-spacing: -0.32px;
        margin-bottom: 34px;
        line-height: 23px;
      }

      a,
      .locate {
        color: $black;
        font-size: 16px;
        font-family: $Gosha-b;
        letter-spacing: 0;
        margin-bottom: 10px;
        display: block;
      }

      .social {
        margin: 34px 0 18px;
      }

      .social-links {
        display: flex;
        margin: 0 -11px;

        .single-link {
          margin: 0 11px;
        }
      }
    }
  }

  @media screen and(max-width: 1800px) {
    .feedback-wrapper {
      .form {
        width: 850px;
      }

      .contacts {
        width: 240px;
      }
    }
  }

  @media screen and(max-width: 1700px) {
    .container {
      .inner-wrapper {
        padding: 0 180px;
      }
    }

    .feedback-wrapper {
      .form {
        width: 800px;
      }
    }
  }

  @media screen and(max-width: 1700px) {
    .container {
      .inner-wrapper {
        padding: 0 130px;
      }
    }
  }

  @media screen and(max-width: 1400px) {
    height: auto;

    .container {
      padding: 202px 0 217px;

      .inner-wrapper {
        padding: 0 100px;

        .title {
          font-size: 30px;
        }
      }
    }

    .feedback-wrapper {
      .form {
        width: 750px;
      }

      .contacts {
        margin-top: 14px;
        width: 268px;
      }
    }
  }

  @media screen and(max-width: 1300px) {
    .container {
      position: relative;
      padding: 202px 20px 217px;
      max-width: calc(100% - 130px);

      &:after {
        content: '';
        display: block;
        width: calc(100vw - 90px);
        height: calc(100% + 1px);
        background: $white;
        position: absolute;
        top: -1px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
      }
      .inner-wrapper {
        padding: 0;

        .title {
          br {
            display: none;
          }
        }
      }
    }

    .feedback-wrapper {
      .form {
        width: calc(100% - 268px);
      }

      .contacts {
        margin-top: 40px;
      }
    }
  }

  @media screen and(max-width: 991px) {
    .feedback-wrapper {
      flex-direction: column;

      .form {
        width: calc(100% + 40px);
      }
    }
  }
  @media screen and(max-width: 767px) {
    .feedback-wrapper {
      .form {
        width: 100%;
        margin: 0;

        .input-holder {
          width: 100%;
          margin: 0 0 40px;
        }

        .button {
          margin: 0 40px 0 0;
        }
      }
    }
  }

  @media screen and(max-width: 575px) {
    .container {
      max-width: calc(100% - 48px);
      padding: 115px 0 90px;

      &:after {
        width: 100vw;
        top: 0;
      }
    }

    .title {
      font-size: 30px;
    }

    .feedback-wrapper {
      margin-top: 60px;

      .contacts {
        width: 100%;
      }

      .form {
        flex-direction: column;
        align-items: center;

        .button {
          width: 100%;
          align-items: center;
          justify-content: center;
          margin: 0 0 15px 0;
        }
      }
    }
  }
}
