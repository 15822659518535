.d-flex {
  display: flex!important;
  justify-content: space-between!important;
}

.align-center {
  align-items: center!important;
}

.no-scroll {
  overflow-y: hidden!important;
  padding-right: 3px;
}

.logo {
  p {
    font-family: $Gosha-b;
    color: $white;
    font-size: 18px;
  }

  span {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid $white;
    margin-left: 9px;
    margin-bottom: -3px;
  }
}

.img-holder {
  line-height: 0;
  display: block;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.container {
  display: flex;
  width: 100%;
  max-width: 1830px;
  margin: 0 auto;
  padding: 0 45px;

  @media screen and(max-width: 2010px) {
    max-width: calc(100% - 90px);
    margin: 0 45px;
    padding: 0;
  }

  @media screen and(max-width: 575px) {
    max-width: calc(100% - 48px);
    margin: 0 24px;
  }
}
