.our-projects {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: $black;

  .click-area {
    position: absolute;
    height: 100%;
    width: 15%;
    z-index: 1;
    top: 0;

    &.button-left {
      left: 0;
    }

    &.button-right {
      right: 0;
    }
  }

  .projects-list {
    position: absolute;
    top: 0;
    display: flex;
    width: 300vw;
    height: 100vh;
    align-items: center;
    will-change: translate;

    &.active {
      position: fixed;
      left: 0;
    }

    .single-project {
      display: flex;
      width: 100vw;
      overflow: hidden;

      .text-wrapper {
        max-width: 685px;
        width: 100%;
        box-sizing: border-box;
        margin-left: 230px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          font-family: $Gosha-b;
          font-size: 77px;
          letter-spacing: 1.4px;
          -webkit-text-stroke: 1px $white;
          color: rgba(255, 255, 255, 0);
          white-space: nowrap;
        }

        .description {
          font-family: $Gilroy-r;
          font-size: 16px;
          margin: 65px 0 80px;
          line-height: 22px;
          position: relative;
          max-width: 627px;
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -27px;
            right: 0;
            height: 2px;
            width: 100vw;
            background: #fff;
          }
        }

        .more {
          font-family: $Gosha-b;
          font-size: 16px;
          text-transform: uppercase;
          color: $white;
          display: block;
          width: max-content;
        }
      }

      .media-wrapper {
        width: 818px;
        height: 818px;
        margin: 0 0 0 auto;
        position: relative;

        .img-holder {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  @media screen and(max-width: 1600px) {
    .projects-list {
      .single-project {
        .text-wrapper {
          margin-left: 200px;
          max-width: 450px;

          .title {
            font-size: 55px;
          }
        }

        .media-wrapper {
          width: 700px;
          height: 700px;
        }
      }
    }
  }

  @media screen and(max-width: 1500px) {
    .projects-list {
      .single-project {
        .text-wrapper {
          margin-left: 150px;
          max-width: 450px;
        }

        .media-wrapper {
          width: 630px;
          height: 630px;
        }
      }
    }
  }

  @media screen and(max-width: 1400px) {
    .projects-list {
      .single-project {
        .text-wrapper {
          margin-left: 113px;
          margin-top: 70px;
          max-width: 460px;

          .title {
            font-size: 51px;
          }

          .description {
            margin: 55px 0 80px;
          }
        }

        .media-wrapper {
          width: 550px;
          height: 550px;
        }
      }
    }
  }

  @media screen and(max-width: 1230px) {
    .projects-list {
      .single-project {
        .text-wrapper {
          margin-left: 0;
          margin-top: 70px;
          max-width: 430px;
        }

        .media-wrapper {
          width: 450px;
          height: 450px;
        }
      }
    }
  }

  @media screen and(max-width: 991px) {
    height: unset;
    min-height: 100vh;

    .container {
      flex-direction: column-reverse;
      justify-content: flex-end;
    }

    .projects-list {
      .single-project {
        .text-wrapper {
          max-width: unset;

          .title {
            color: $white;
            -webkit-text-stroke: unset;
          }

          .description {
            margin: 37px 0;

            &::after {
              bottom: -20px;
              right: 50%;
              transform: translateX(50%);
            }
          }

          .more {
            margin-top: 38px;
            line-height: 1;
            height: 50px;
          }
        }

        .media-wrapper {
          width: 818px;
          height: 818px;
          margin: 0 auto;
        }
      }
    }
  }

  @media screen and(max-width: 908px) {
    .projects-list {
      .single-project {
        .media-wrapper {
          width: 100%;
          height: unset;

          .img-holder {
            height: unset;
            position: unset;
            transform: none;
            left: unset;
            top: unset;
          }

          video {
            height: auto;
          }
        }
      }
    }
  }

  @media screen and(max-width: 575px) {
    .projects-list {
      .single-project {
        .text-wrapper {
          margin-top: 20px;

          .title {
            font-size: 36px;
          }

          .description {
            font-size: 14px;
            margin: 20px 0;
            line-height: 18px;

            &::after {
              bottom: -20px;
            }
          }

          .more {
            font-size: 16px;
            line-height: 20px;
            height: 20px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
