.list-services {
  width: 100vw;
  height: 99.9vh;
  display: flex;
  align-items: center;

  .container {
    position: relative;
  }

  .services-wrapper {
    z-index: 1;
    max-width: 30%;
    width: 100%;
    margin-left: 230px;

    .single-service {
      transition: 0.6s;
      white-space: nowrap;
      margin-bottom: 50px;
      height: 130px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        display: none;
      }

      .title {
        font-family: $Gosha-b;
        font-size: 60px;
        line-height: 65px;
        color: $white;
        margin-bottom: 15px;
        transition: 0.5s;
      }

      .subtitle {
        font-family: $Gilroy-r;
        font-size: 18px;
        color: $white;
        transition: 1s;
        white-space: normal;
        max-width: 460px;
      }

      &.active {
        padding-left: 160px;
        margin-right: -160px;

        .title {
          font-size: 126px;
          margin-bottom: 50px;
          -webkit-text-stroke: 1px $white;
          color: rgba(255, 255, 255, 0);
        }

        .subtitle {
          padding-left: 10px;
        }
      }
    }
  }

  .wrapper {
    position: absolute;
    right: 0;

  }

  .single-service-img {
    position: absolute;
    top: calc(50% + 30px);
    transform: translateY(-50%);
    right: 45px;
    opacity: 0;
    transition: 0.2s;
    pointer-events: none;

    .number {
      position: absolute;
      right: -75px;
      top: -170px;

      &.two {
        right: -78px;
        top: -144px;
      }

      &.three {
        right: -140px;
        top: -125px;
      }
    }

    &.show-img {
      opacity: 1;
      animation: changeImg 0.4s ease-in-out 0s normal;
      animation-fill-mode: forwards;
      pointer-events: auto;
    }

    img {
      object-fit: none;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-size: contain;
      z-index: 1;
      opacity: 0;
    }



    &:hover {
      &:before {
        left: 4px;
        opacity: 0.7;
      }

      &:after {
        left: -4px;
        opacity: 0.9;
      }

      &.development {
        &:before,
        &:after {
          background-image: url(../../../media/img/list-services/development.png);
        }

        &:before {
          animation: image-glitch-before-dev 2s linear 0s infinite alternate-reverse;
        }

        &:after {
          animation: image-glitch-after-dev 7s linear 0s infinite alternate-reverse;
        }
      }

      &.design {
        &:before,
        &:after {
          background-image: url(../../../media/img/list-services/design.png);
        }

        &:before {
          animation: image-glitch-before-des 2s linear 0s infinite alternate-reverse;
        }

        &:after {
          animation: image-glitch-after-des 7s linear 0s infinite alternate-reverse;
        }
      }

      &.marketing {
        &:before,
        &:after {
          background-image: url(../../../media/img/list-services/marketing.png);
        }

        &:before {
          animation: image-glitch-before-mar 2s linear 0s infinite alternate-reverse;
        }

        &:after {
          animation: image-glitch-after-mar 7s linear 0s infinite alternate-reverse;
        }
      }

      &.consulting {
        &:before,
        &:after {
          background-image: url(../../../media/img/list-services/consulting.png);
        }

        &:before {
          animation: image-glitch-before-con 2s linear 0s infinite alternate-reverse;
        }

        &:after {
          animation: image-glitch-after-con 7s linear 0s infinite alternate-reverse;
        }
      }
    }
  }

  @media screen and(max-width: 2010px) {
    .wrapper {
      transform: scale(0.95);
    }

    .single-service-img {
      right: 0;
    }
  }

  @media screen and(max-width: 1900px) {
    .wrapper {
      transform: scale(0.86);
    }
  }

  @media screen and(max-width: 1800px) {
    .wrapper {
      transform: scale(0.8);
    }
  }

  @media screen and(max-width: 1700px) {
    .wrapper {
      transform: scale(0.74);
    }

    .single-service-img {
      top: 50%;
    }
  }

  @media screen and(max-width: 1600px) {
    .services-wrapper {
      margin-left: 200px;
      .single-service {
        margin-bottom: 50px;
        height: 110px;

        .title {
          font-size: 54px;
          line-height: 45px;
        }

        &.active {
          padding-left: 100px;
          margin-right: -100px;

          .title {
            font-size: 80px;
          }
        }
      }
    }
  }

  @media screen and(max-width: 1500px) {
    .services-wrapper {
      margin-left: 150px;
    }
  }

  @media screen and(max-width: 1400px) {
    .services-wrapper {
      margin-left: 100px;
      .single-service {
        .title {
          margin-bottom: 20px;
        }

        .subtitle {
          font-size: 15px;
        }

        &.active {
          padding-left: 50px;
          margin-right: -50px;

          .title {
            font-size: 70px;
          }
        }
      }
    }
  }

  @media screen and(max-width: 1300px) {
    .wrapper {
      transform: scale(0.65);
    }

    .services-wrapper {
      margin-left: 80px;
      max-width: 33%;

      .single-service {
        &.active {
          padding-left: 35px;
          margin-right: -35px;
        }
      }
    }
  }

  @media screen and(max-width: 1200px) {
    .wrapper {
      transform: scale(0.64);
    }

    .services-wrapper {
      margin-left: 0;

      .single-service {
        &.active {
          padding-left: 35px;
          margin-right: -35px;
        }
      }
    }
  }

  @media screen and(max-width: 1100px) {
    .wrapper {
      transform: scale(0.55);
    }
  }

  @media screen and(max-width: 991px) {
    .wrapper {
      transform: scale(0.3);
    }

    .services-wrapper {
      max-width: 100%;

      .single-service {
        height: unset;

        .title {
          font-size: 60px;
          line-height: 65px;
          margin-bottom: 25px;
        }

        .subtitle {
          font-size: 18px;
        }

        &.active {
          padding-left: 35px;
          margin-right: -35px;

          .title {
            font-size: 100px;
          }
        }
      }
    }
  }

  @media screen and(max-width: 767px) {
    height: auto;

    .wrapper {
      display: none;
    }

    .services-wrapper {
      .single-service {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 120px;

        .text-wrapper {
          width: 260px;
        }

        img {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: -1;
          &.dev {
            width: 240px;
            right: -10px;
          }

          &.des {
            width: 256px;
            right: -26px;
          }

          &.mark {
            width: 260px;
            right: -30px;
          }

          &.cons {
            width: 246px;
            right: -16px;
          }
        }

        .title {
          font-size: 45px;
          line-height: 50px;
          margin-bottom: 20px;
        }

        .subtitle {
          font-size: 18px;
        }

        &.active {
          padding-left: 0;
          margin-right: 0;

          .title {
            font-size: 50px;
            margin-bottom: 20px;
          }

          .subtitle {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and(max-width: 575px) {
    .services-wrapper {
      .single-service {
        margin-bottom: 50px;

        .text-wrapper {
          width: calc(100% - 140px);
        }

        img {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: -1;
          &.dev {
            width: 120px;
            right: -6px;
          }

          &.des {
            width: 123px;
            right: -13px;
          }

          &.mark {
            width: 130px;
            right: -15px;
          }

          &.cons {
            width: 123px;
            right: -8px;
          }
        }

        .title {
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 15px;
        }

        .subtitle {
          font-size: 12px;
          line-height: 12px;
          white-space: normal;
        }

        &.active {
          padding-left: 0;
          margin-right: 0;

          .title {
            font-size: 24px;
            margin-bottom: 15px;
          }

          .subtitle {
            padding: 0;
          }
        }
      }
    }
  }
}
