.our-clients {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
  cursor: none;
  margin-top: 210px;
  &:hover {
    .back {
      opacity: 1;
    }
  }

  .back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    mix-blend-mode: difference;
    pointer-events: none;
    opacity: 0;
    transition: 0.4s;
  }

  .logo-wrapper {
    display: flex;
    flex-wrap: wrap;
    border: 2px solid $white;
    position: relative;
    box-sizing: border-box;

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 300px;
      width: calc(100% + 2px);
      left: -1px;
      background: $white;
      top: 100%;
    }

    .container {
      flex-wrap: wrap;
      margin-top: 260px;
      padding: 0 180px 155px;
    }

    .single-logo {
      width: 25%;
      height: 104px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 95px;

      &:nth-child(4n + 5),
      &:first-child {
        justify-content: flex-start;
      }

      &:nth-child(4n+4) {
        justify-content: flex-end;
      }
    }
  }

  @media screen and(max-width: 1800px) {

  }

  @media screen and(max-width: 1600px) {
    height: 824px;

    .logo-wrapper {
      .container {
        margin-top: 190px;
        padding: 0 55px 140px;
      }

      .single-logo {
        margin-bottom: 60px;
        transform: scale(0.87);
      }
    }
  }

  @media screen and(max-width: 1300px) {
    .logo-wrapper {
      .container {
        margin-top: 150px;
        padding: 0 0 80px;
      }
    }
  }

  @media screen and(max-width: 1200px) {
    .logo-wrapper {
      .single-logo {
        img {
          transform: scale(0.7);
        }
      }
    }
  }

  @media screen and(max-width: 991px) {
    display: none;
  }
}
