.ticker {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .single-row {
    display: flex;
    animation: ticker 100s linear 0s infinite alternate;
    margin-top: 10px;
    width: fit-content;
    &:hover {
      animation-play-state:paused;
    }

    &:nth-child(odd) {
      animation: ticker 90s linear 0s infinite alternate-reverse;
      &:hover {
        animation-play-state:paused;
      }
    }

    &:nth-child(3) {
      animation: ticker 150s linear 0s infinite alternate-reverse;
      &:hover {
        animation-play-state:paused;
      }
    }

    span {
      font-family: $Gosha-b;
      font-size: 200px;
      font-weight: 300;
      line-height: 165px;
      -webkit-text-stroke: 1px $white;
      color: rgba(255, 255, 255, 0);
      margin: 0 40px;
      display: inline-block;
      white-space: nowrap;
      text-transform: uppercase;
      transition: 0.5s;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  @media screen and(max-width: 1200px) {
    .single-row {
      span {
        font-size: 180px;
        line-height: 150px;
      }
    }
  }
  @media screen and(max-width: 991px) {
    .single-row {
      span {
        font-size: 160px;
        line-height: 140px;
      }
    }
  }
  @media screen and(max-width: 767px) {
    height: auto;
    margin: 170px 0;
    .single-row {
      span {
        font-size: 130px;
        line-height: 120px;
        margin: 0 30px;
      }
    }
  }
  @media screen and(max-width: 575px) {
    .single-row {
      animation: ticker 35s linear 0s infinite alternate;

      span {
        font-size: 80px;
        line-height: 70px;
        margin: 0 20px;
      }

      &:nth-child(odd) {
        animation: ticker 50s linear 0s infinite alternate-reverse;
      }

      &:nth-child(3) {
        animation: ticker 75s linear 0s infinite alternate-reverse;
      }
    }
  }
}
