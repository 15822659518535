@font-face {
  font-family: 'Gosha-b';
  src: url("../fonts/Gosha/GoshaBold.eot") format("embedded-opentype"), url("../fonts/Gosha/GoshaBold.woff2") format("woff2"), url("../fonts/Gosha/GoshaBold.woff") format("woff"), url("../fonts/Gosha/GoshaBold.ttf") format("truetype"), url("../fonts/Gosha/GoshaBold.svg#OakesBold") format("svg");
  font-weight: 700;
}

@font-face {
  font-family: 'Gosha-r';
  src: url("../fonts/Gosha/GoshaRegular.eot") format("embedded-opentype"), url("../fonts/Gosha/GoshaRegular.woff2") format("woff2"), url("../fonts/Gosha/GoshaRegular.woff") format("woff"), url("../fonts/Gosha/GoshaRegular.ttf") format("truetype"), url("../fonts/Gosha/GoshaRegular.svg#OakesRegular") format("svg");
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy-r';
  src: url("../fonts/Gilroy/GilroyRegular.eot") format("embedded-opentype"), url("../fonts/Gilroy/GilroyRegular.woff2") format("woff2"), url("../fonts/Gilroy/GilroyRegular.woff") format("woff"), url("../fonts/Gilroy/GilroyRegular.ttf") format("truetype"), url("../fonts/Gilroy/GilroyRegular.svg#OakesRegular") format("svg");
  font-weight: 300;
}

p,
h1,
h2,
h3,
h4,
ul,
li,
span,
label,
input,
textarea,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

p:hover,
h1:hover,
h2:hover,
h3:hover,
h4:hover,
ul:hover,
li:hover,
span:hover,
label:hover,
input:hover,
textarea:hover,
a:hover {
  text-decoration: none;
}

textarea {
  resize: none;
}

input {
  outline: none;
}

ul {
  list-style: none;
}

html {
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

html::-webkit-scrollbar-button {
  background-color: #666;
}

html::-webkit-scrollbar-track {
  background-color: #999;
}

html::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
}

html::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}

html::-webkit-scrollbar-corner {
  background-color: #999;
}

html::-webkit-resizer {
  background-color: #666;
}

body {
  margin: 0;
  position: relative;
  overflow-x: hidden;
  font-family: Gosha-r, Verdana;
  color: #fff;
  background: #161516;
  cursor: none !important;
}

body a {
  cursor: none !important;
}

body.white {
  background: #fff;
  color: #161516;
}

.hide-this {
  position: absolute;
  left: -3000vw;
}

@keyframes glitch-it-before {
  0% {
    clip: rect(93px, 824px, 19px, 0px);
    opacity: 1;
  }
  6.66667% {
    clip: rect(82px, 824px, 103px, 0px);
    opacity: 1;
  }
  13.33333% {
    clip: rect(102px, 824px, 95px, 0px);
    opacity: 1;
  }
  20% {
    clip: rect(23px, 824px, 37px, 0px);
    opacity: 1;
  }
  26.66667% {
    clip: rect(90px, 824px, 14px, 0px);
    opacity: 1;
  }
  33.33333% {
    clip: rect(103px, 824px, 71px, 0px);
    opacity: 1;
  }
  40% {
    clip: rect(24px, 824px, 65px, 0px);
    opacity: 1;
  }
  46.66667% {
    clip: rect(13px, 824px, 21px, 0px);
    opacity: 1;
  }
  53.33333% {
    clip: rect(100px, 824px, 59px, 0px);
    opacity: 1;
  }
  60% {
    clip: rect(31px, 824px, 107px, 0px);
    opacity: 1;
  }
  66.66667% {
    clip: rect(105px, 824px, 111px, 0px);
    opacity: 1;
  }
  73.33333% {
    clip: rect(42px, 824px, 110px, 0px);
    opacity: 1;
  }
  80% {
    clip: rect(113px, 824px, 113px, 0px);
    opacity: 1;
  }
  86.66667% {
    clip: rect(65px, 824px, 80px, 0px);
    opacity: 1;
  }
  93.33333% {
    clip: rect(36px, 824px, 112px, 0px);
    opacity: 1;
  }
}

@keyframes glitch-it-after {
  0% {
    clip: rect(38px, 824px, 87px, 0px);
    opacity: 1;
  }
  10% {
    clip: rect(89px, 824px, 26px, 0px);
    opacity: 1;
  }
  20% {
    clip: rect(64px, 824px, 50px, 0px);
    opacity: 1;
  }
  30% {
    clip: rect(8px, 824px, 10px, 0px);
    opacity: 1;
  }
  40% {
    clip: rect(33px, 824px, 97px, 0px);
    opacity: 1;
  }
  50% {
    clip: rect(105px, 824px, 78px, 0px);
    opacity: 1;
  }
  60% {
    clip: rect(42px, 824px, 60px, 0px);
    opacity: 1;
  }
  70% {
    clip: rect(101px, 824px, 28px, 0px);
    opacity: 1;
  }
  80% {
    clip: rect(22px, 824px, 55px, 0px);
    opacity: 1;
  }
  90% {
    clip: rect(47px, 824px, 95px, 0px);
    opacity: 1;
  }
}

@keyframes start-show-text {
  0% {
    opacity: 0;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%) scale(0.5);
  }
  7% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(1.2);
  }
  17% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(1);
  }
  100% {
    opacity: 1;
    bottom: 56px;
    left: 45px;
    transform: translate(0, 0) scale(1);
  }
}

@keyframes start-show-text-mobile {
  0% {
    opacity: 0;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%) scale(0.5);
  }
  7% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(1.2);
  }
  17% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(1);
  }
  100% {
    opacity: 1;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%) scale(1);
  }
}

@keyframes changeImg {
  0% {
    opacity: 0.3;
    transform: translateY(-50%) scale(0.99);
  }
  50% {
    transform: translateY(-50%) scale(1.01);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-83%);
  }
}

@keyframes image-glitch-before-dev {
  0% {
    clip: rect(464px, 1196px, 874px, 0px);
  }
  5% {
    clip: rect(939px, 1196px, 839px, 0px);
  }
  10% {
    clip: rect(141px, 1196px, 959px, 0px);
  }
  15% {
    clip: rect(534px, 1196px, 716px, 0px);
  }
  20% {
    clip: rect(343px, 1196px, 78px, 0px);
  }
  25% {
    clip: rect(588px, 1196px, 422px, 0px);
  }
  30% {
    clip: rect(259px, 1196px, 761px, 0px);
  }
  35% {
    clip: rect(188px, 1196px, 87px, 0px);
  }
  40% {
    clip: rect(56px, 1196px, 792px, 0px);
  }
  45% {
    clip: rect(494px, 1196px, 292px, 0px);
  }
  50% {
    clip: rect(999px, 1196px, 862px, 0px);
  }
  55% {
    clip: rect(570px, 1196px, 315px, 0px);
  }
  60% {
    clip: rect(158px, 1196px, 617px, 0px);
  }
  65% {
    clip: rect(98px, 1196px, 418px, 0px);
  }
  70% {
    clip: rect(782px, 1196px, 401px, 0px);
  }
  75% {
    clip: rect(726px, 1196px, 193px, 0px);
  }
  80% {
    clip: rect(898px, 1196px, 347px, 0px);
  }
  85% {
    clip: rect(961px, 1196px, 226px, 0px);
  }
  90% {
    clip: rect(216px, 1196px, 1053px, 0px);
  }
  95% {
    clip: rect(444px, 1196px, 871px, 0px);
  }
}

@keyframes image-glitch-after-dev {
  0% {
    clip: rect(500px, 1169px, 408px, 0px);
  }
  5% {
    clip: rect(626px, 1169px, 110px, 0px);
  }
  10% {
    clip: rect(281px, 1169px, 13px, 0px);
  }
  15% {
    clip: rect(376px, 1169px, 166px, 0px);
  }
  20% {
    clip: rect(207px, 1169px, 132px, 0px);
  }
  25% {
    clip: rect(782px, 1169px, 817px, 0px);
  }
  30% {
    clip: rect(186px, 1169px, 556px, 0px);
  }
  35% {
    clip: rect(457px, 1169px, 1013px, 0px);
  }
  40% {
    clip: rect(721px, 1169px, 1011px, 0px);
  }
  45% {
    clip: rect(427px, 1169px, 777px, 0px);
  }
  50% {
    clip: rect(326px, 1169px, 274px, 0px);
  }
  55% {
    clip: rect(657px, 1169px, 741px, 0px);
  }
  60% {
    clip: rect(939px, 1169px, 980px, 0px);
  }
  65% {
    clip: rect(656px, 1169px, 671px, 0px);
  }
  70% {
    clip: rect(854px, 1169px, 1013px, 0px);
  }
  75% {
    clip: rect(407px, 1169px, 329px, 0px);
  }
  80% {
    clip: rect(5px, 1169px, 451px, 0px);
  }
  85% {
    clip: rect(318px, 1169px, 186px, 0px);
  }
  90% {
    clip: rect(405px, 1169px, 193px, 0px);
  }
  95% {
    clip: rect(970px, 1169px, 437px, 0px);
  }
}

@keyframes image-glitch-before-des {
  0% {
    clip: rect(916px, 1239px, 204px, 0px);
  }
  5% {
    clip: rect(167px, 1239px, 717px, 0px);
  }
  10% {
    clip: rect(299px, 1239px, 74px, 0px);
  }
  15% {
    clip: rect(864px, 1239px, 733px, 0px);
  }
  20% {
    clip: rect(308px, 1239px, 1016px, 0px);
  }
  25% {
    clip: rect(1034px, 1239px, 474px, 0px);
  }
  30% {
    clip: rect(977px, 1239px, 763px, 0px);
  }
  35% {
    clip: rect(551px, 1239px, 833px, 0px);
  }
  40% {
    clip: rect(4px, 1239px, 79px, 0px);
  }
  45% {
    clip: rect(215px, 1239px, 706px, 0px);
  }
  50% {
    clip: rect(287px, 1239px, 388px, 0px);
  }
  55% {
    clip: rect(952px, 1239px, 599px, 0px);
  }
  60% {
    clip: rect(680px, 1239px, 458px, 0px);
  }
  65% {
    clip: rect(1051px, 1239px, 482px, 0px);
  }
  70% {
    clip: rect(951px, 1239px, 951px, 0px);
  }
  75% {
    clip: rect(844px, 1239px, 532px, 0px);
  }
  80% {
    clip: rect(441px, 1239px, 675px, 0px);
  }
  85% {
    clip: rect(474px, 1239px, 86px, 0px);
  }
  90% {
    clip: rect(19px, 1239px, 454px, 0px);
  }
  95% {
    clip: rect(450px, 1239px, 827px, 0px);
  }
}

@keyframes image-glitch-after-des {
  0% {
    clip: rect(835px, 1239px, 719px, 0px);
  }
  5% {
    clip: rect(193px, 1239px, 871px, 0px);
  }
  10% {
    clip: rect(673px, 1239px, 715px, 0px);
  }
  15% {
    clip: rect(473px, 1239px, 538px, 0px);
  }
  20% {
    clip: rect(812px, 1239px, 976px, 0px);
  }
  25% {
    clip: rect(171px, 1239px, 130px, 0px);
  }
  30% {
    clip: rect(258px, 1239px, 659px, 0px);
  }
  35% {
    clip: rect(668px, 1239px, 797px, 0px);
  }
  40% {
    clip: rect(993px, 1239px, 813px, 0px);
  }
  45% {
    clip: rect(688px, 1239px, 925px, 0px);
  }
  50% {
    clip: rect(209px, 1239px, 961px, 0px);
  }
  55% {
    clip: rect(937px, 1239px, 139px, 0px);
  }
  60% {
    clip: rect(142px, 1239px, 483px, 0px);
  }
  65% {
    clip: rect(782px, 1239px, 277px, 0px);
  }
  70% {
    clip: rect(343px, 1239px, 898px, 0px);
  }
  75% {
    clip: rect(695px, 1239px, 143px, 0px);
  }
  80% {
    clip: rect(400px, 1239px, 95px, 0px);
  }
  85% {
    clip: rect(764px, 1239px, 980px, 0px);
  }
  90% {
    clip: rect(362px, 1239px, 813px, 0px);
  }
  95% {
    clip: rect(417px, 1239px, 413px, 0px);
  }
}

@keyframes image-glitch-before-mar {
  0% {
    clip: rect(426px, 1282px, 366px, 0px);
  }
  5% {
    clip: rect(398px, 1282px, 832px, 0px);
  }
  10% {
    clip: rect(826px, 1282px, 376px, 0px);
  }
  15% {
    clip: rect(189px, 1282px, 943px, 0px);
  }
  20% {
    clip: rect(479px, 1282px, 881px, 0px);
  }
  25% {
    clip: rect(13px, 1282px, 149px, 0px);
  }
  30% {
    clip: rect(857px, 1282px, 247px, 0px);
  }
  35% {
    clip: rect(232px, 1282px, 778px, 0px);
  }
  40% {
    clip: rect(939px, 1282px, 831px, 0px);
  }
  45% {
    clip: rect(248px, 1282px, 612px, 0px);
  }
  50% {
    clip: rect(672px, 1282px, 881px, 0px);
  }
  55% {
    clip: rect(548px, 1282px, 22px, 0px);
  }
  60% {
    clip: rect(683px, 1282px, 498px, 0px);
  }
  65% {
    clip: rect(132px, 1282px, 327px, 0px);
  }
  70% {
    clip: rect(880px, 1282px, 178px, 0px);
  }
  75% {
    clip: rect(645px, 1282px, 814px, 0px);
  }
  80% {
    clip: rect(495px, 1282px, 161px, 0px);
  }
  85% {
    clip: rect(328px, 1282px, 488px, 0px);
  }
  90% {
    clip: rect(153px, 1282px, 184px, 0px);
  }
  95% {
    clip: rect(206px, 1282px, 53px, 0px);
  }
}

@keyframes image-glitch-after-mar {
  0% {
    clip: rect(112px, 1282px, 636px, 0px);
  }
  5% {
    clip: rect(847px, 1282px, 208px, 0px);
  }
  10% {
    clip: rect(920px, 1282px, 845px, 0px);
  }
  15% {
    clip: rect(699px, 1282px, 289px, 0px);
  }
  20% {
    clip: rect(369px, 1282px, 830px, 0px);
  }
  25% {
    clip: rect(294px, 1282px, 814px, 0px);
  }
  30% {
    clip: rect(757px, 1282px, 4px, 0px);
  }
  35% {
    clip: rect(21px, 1282px, 919px, 0px);
  }
  40% {
    clip: rect(153px, 1282px, 904px, 0px);
  }
  45% {
    clip: rect(967px, 1282px, 757px, 0px);
  }
  50% {
    clip: rect(983px, 1282px, 59px, 0px);
  }
  55% {
    clip: rect(602px, 1282px, 162px, 0px);
  }
  60% {
    clip: rect(303px, 1282px, 768px, 0px);
  }
  65% {
    clip: rect(333px, 1282px, 611px, 0px);
  }
  70% {
    clip: rect(382px, 1282px, 836px, 0px);
  }
  75% {
    clip: rect(82px, 1282px, 859px, 0px);
  }
  80% {
    clip: rect(3px, 1282px, 249px, 0px);
  }
  85% {
    clip: rect(135px, 1282px, 236px, 0px);
  }
  90% {
    clip: rect(871px, 1282px, 311px, 0px);
  }
  95% {
    clip: rect(817px, 1282px, 297px, 0px);
  }
}

@keyframes image-glitch-before-con {
  0% {
    clip: rect(732px, 1195px, 966px, 0px);
  }
  5% {
    clip: rect(761px, 1195px, 516px, 0px);
  }
  10% {
    clip: rect(905px, 1195px, 916px, 0px);
  }
  15% {
    clip: rect(256px, 1195px, 953px, 0px);
  }
  20% {
    clip: rect(794px, 1195px, 814px, 0px);
  }
  25% {
    clip: rect(997px, 1195px, 577px, 0px);
  }
  30% {
    clip: rect(935px, 1195px, 816px, 0px);
  }
  35% {
    clip: rect(864px, 1195px, 158px, 0px);
  }
  40% {
    clip: rect(698px, 1195px, 490px, 0px);
  }
  45% {
    clip: rect(576px, 1195px, 757px, 0px);
  }
  50% {
    clip: rect(107px, 1195px, 886px, 0px);
  }
  55% {
    clip: rect(708px, 1195px, 996px, 0px);
  }
  60% {
    clip: rect(970px, 1195px, 824px, 0px);
  }
  65% {
    clip: rect(84px, 1195px, 304px, 0px);
  }
  70% {
    clip: rect(681px, 1195px, 286px, 0px);
  }
  75% {
    clip: rect(506px, 1195px, 264px, 0px);
  }
  80% {
    clip: rect(314px, 1195px, 227px, 0px);
  }
  85% {
    clip: rect(114px, 1195px, 256px, 0px);
  }
  90% {
    clip: rect(987px, 1195px, 45px, 0px);
  }
  95% {
    clip: rect(475px, 1195px, 247px, 0px);
  }
}

@keyframes image-glitch-after-con {
  0% {
    clip: rect(205px, 1195px, 907px, 0px);
  }
  5% {
    clip: rect(649px, 1195px, 611px, 0px);
  }
  10% {
    clip: rect(312px, 1195px, 962px, 0px);
  }
  15% {
    clip: rect(877px, 1195px, 675px, 0px);
  }
  20% {
    clip: rect(796px, 1195px, 931px, 0px);
  }
  25% {
    clip: rect(714px, 1195px, 100px, 0px);
  }
  30% {
    clip: rect(460px, 1195px, 339px, 0px);
  }
  35% {
    clip: rect(310px, 1195px, 190px, 0px);
  }
  40% {
    clip: rect(580px, 1195px, 274px, 0px);
  }
  45% {
    clip: rect(273px, 1195px, 899px, 0px);
  }
  50% {
    clip: rect(816px, 1195px, 63px, 0px);
  }
  55% {
    clip: rect(964px, 1195px, 223px, 0px);
  }
  60% {
    clip: rect(954px, 1195px, 320px, 0px);
  }
  65% {
    clip: rect(25px, 1195px, 918px, 0px);
  }
  70% {
    clip: rect(899px, 1195px, 922px, 0px);
  }
  75% {
    clip: rect(959px, 1195px, 757px, 0px);
  }
  80% {
    clip: rect(714px, 1195px, 376px, 0px);
  }
  85% {
    clip: rect(712px, 1195px, 516px, 0px);
  }
  90% {
    clip: rect(38px, 1195px, 122px, 0px);
  }
  95% {
    clip: rect(271px, 1195px, 781px, 0px);
  }
}

.d-flex {
  display: flex !important;
  justify-content: space-between !important;
}

.align-center {
  align-items: center !important;
}

.no-scroll {
  overflow-y: hidden !important;
  padding-right: 3px;
}

.logo p {
  font-family: Gosha-b, Verdana;
  color: #fff;
  font-size: 18px;
}

.logo span {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #fff;
  margin-left: 9px;
  margin-bottom: -3px;
}

.img-holder {
  line-height: 0;
  display: block;
}

.img-holder img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.container {
  display: flex;
  width: 100%;
  max-width: 1830px;
  margin: 0 auto;
  padding: 0 45px;
}

@media screen and (max-width: 2010px) {
  .container {
    max-width: calc(100% - 90px);
    margin: 0 45px;
    padding: 0;
  }
}

@media screen and (max-width: 575px) {
  .container {
    max-width: calc(100% - 48px);
    margin: 0 24px;
  }
}

footer {
  padding: 167px 0 170px;
}

footer.white {
  background: #fff;
}

footer.white .container .left-side .navigation li a {
  color: #161516;
}

footer.white .container .left-side .logo-copyright:after {
  background-color: #161516;
}

footer.white .container .left-side .logo-copyright .logo {
  font-weight: 600;
}

footer.white .container .left-side .logo-copyright .logo p {
  color: #161516;
}

footer.white .container .left-side .logo-copyright .logo span {
  border-color: #161516;
}

footer.white .container .social-block .locate,
footer.white .container .social-block .phone {
  color: #161516;
}

footer .container {
  justify-content: space-between;
  padding: 0 470px;
  width: calc(100% - 940px);
}

footer .container .left-side {
  display: flex;
}

footer .container .left-side .logo-copyright {
  padding-right: 94px;
  position: relative;
}

footer .container .left-side .logo-copyright:after {
  position: absolute;
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background: #fff;
  right: 0;
  bottom: 0;
}

footer .container .left-side .logo-copyright p {
  margin-top: 50px;
  font-size: 16px;
  font-family: Gosha-r, Verdana;
}

footer .container .left-side .logo-copyright .logo p {
  margin: 0;
  font-size: 32px;
  letter-spacing: 0.7px;
}

footer .container .left-side .logo-copyright .logo span {
  width: 28px;
  height: 28px;
  border: 2px solid #fff;
  margin-bottom: -7px;
}

footer .container .left-side .navigation {
  margin-left: 90px;
  margin-top: 5px;
}

footer .container .left-side .navigation li {
  margin-bottom: 15px;
}

footer .container .left-side .navigation li:last-child {
  margin: 0;
}

footer .container .left-side .navigation li a {
  font-size: 18px;
  font-family: Gosha-r, Verdana;
  color: #fff;
}

footer .container .social-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
}

footer .container .social-block a,
footer .container .social-block .locate {
  font-family: Gosha-r, Verdana;
  color: #fff;
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
}

footer .container .social-block .social-links {
  display: flex;
  margin: 30px -15px 0;
}

footer .container .social-block .social-links .single-link {
  margin: 0 15px;
}

@media screen and (max-width: 2000px) {
  footer .container {
    padding: 0;
    width: calc(100% - 90px);
  }
  footer .container .left-side {
    margin-left: 230px;
  }
  footer .container .social-block {
    margin-right: 230px;
  }
}

@media screen and (max-width: 1700px) {
  footer .container .left-side {
    margin-left: 130px;
  }
  footer .container .social-block {
    margin-right: 130px;
  }
}

@media screen and (max-width: 1500px) {
  footer {
    padding: 142px 0 136px;
  }
}

@media screen and (max-width: 1400px) {
  footer .container .left-side {
    margin-left: 100px;
  }
  footer .container .social-block {
    margin-right: 100px;
  }
}

@media screen and (max-width: 1300px) {
  footer .container .left-side,
  footer .container .social-block {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 991px) {
  footer {
    display: none;
  }
}

header {
  position: absolute;
  left: 0;
  top: 48px;
  width: calc(100% - 90px);
  z-index: 1;
  padding: 0 45px;
}

header .burger-menu {
  width: 46px;
  height: 46px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  z-index: 2;
}

header .burger-menu .rect {
  width: 16px;
  height: 16px;
  position: relative;
  transform: rotate(45deg);
}

header .burger-menu .dot {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background: #fff;
  display: block;
  transition: 1s cubic-bezier(0, 0.36, 1, 0.63);
}

header .burger-menu .dot:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

header .burger-menu .dot:nth-child(2) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

header .burger-menu .dot:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

header .burger-menu .dot:nth-child(4) {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

header .burger-menu.animate-cross .dot:nth-child(1) {
  height: 100%;
  width: 3px;
}

header .burger-menu.animate-cross .dot:nth-child(2) {
  width: 100%;
  height: 3px;
}

header .burger-menu.animate-cross .dot:nth-child(3) {
  height: 100%;
  width: 3px;
}

header .burger-menu.animate-cross .dot:nth-child(4) {
  width: 100%;
  height: 3px;
}

header .navigation {
  position: absolute;
  background: #161516;
  top: -50px;
  left: 200vw;
  width: 100vw;
  height: calc(100vh + 50px);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  transform: rotate(-180deg);
  transition: 1s ease-in-out;
}

header .navigation.show {
  left: 0;
}

header .navigation .logo {
  display: none;
}

header .navigation .close-cross {
  width: 46px;
  height: 46px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 50px;
  left: 50px;
  z-index: 2;
  box-sizing: border-box;
}

header .navigation .close-cross:after, header .navigation .close-cross:before {
  content: '';
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 1px;
}

header .navigation .close-cross:after {
  width: 16px;
  height: 4px;
}

header .navigation .close-cross:before {
  width: 4px;
  height: 16px;
}

header .navigation .link-wrapper {
  writing-mode: tb;
  margin: 46px 80px 0;
}

header .navigation .link-wrapper a {
  font-family: Gosha-b, Verdana;
  color: #fff;
  font-size: 180px;
  text-transform: capitalize;
  transition: 0.4s;
  position: relative;
}

header .navigation .link-wrapper a:after {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(100% + 140px);
  background: #fff;
  top: 0;
  right: 0;
}

header .navigation .link-wrapper a:hover {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px #fff;
}

header .navigation .link-wrapper:last-child {
  margin: 46px 80px 0 160px;
}

header .navigation .link-wrapper:last-child a:before {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(100% - 100px);
  background: #fff;
  top: 0;
  left: -160px;
}

@media screen and (max-width: 2000px) {
  header .navigation .link-wrapper a {
    font-size: 120px;
  }
}

@media screen and (max-width: 1800px) {
  header .navigation .link-wrapper {
    margin: 40px 50px 0;
  }
  header .navigation .link-wrapper a {
    font-size: 100px;
  }
  header .navigation .link-wrapper a:after {
    left: -50px;
    height: calc(100% + 80px);
  }
  header .navigation .link-wrapper a:last-child {
    margin: 40px 50px 0;
  }
  header .navigation .link-wrapper a:last-child a:before {
    left: -50px;
    height: calc(100% - 60px);
  }
  header .navigation .link-wrapper:last-child {
    margin: 40px 50px 160px;
  }
  header .navigation .link-wrapper:last-child a:before {
    left: -80px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 1400px) {
  header .navigation .link-wrapper a {
    font-size: 80px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 991px) {
  header .navigation .link-wrapper {
    margin: 40px 40px 0;
  }
  header .navigation .link-wrapper:last-child {
    margin: 40px 40px 0;
  }
  header .navigation .link-wrapper:last-child a:before {
    left: -40px;
  }
  header .navigation .link-wrapper a {
    font-size: 60px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 767px) {
  header .navigation {
    transform: rotate(0);
    flex-direction: column;
  }
  header .navigation .close-cross {
    bottom: unset;
    left: unset;
    top: 50px;
    right: 50px;
  }
  header .navigation .link-wrapper {
    writing-mode: initial;
    margin: 30px 0 30px 50px;
  }
  header .navigation .link-wrapper:last-child a:before {
    width: 100%;
    height: 1.5px;
    right: unset;
    left: 0;
    top: unset;
    bottom: -40px;
  }
  header .navigation .link-wrapper a:after {
    left: 0;
    right: unset;
    width: 120%;
    height: 1px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 575px) {
  header {
    top: 30px;
    width: calc(100% - 50px);
    padding: 0 25px;
  }
  header .navigation {
    overflow-y: auto;
    top: -30px;
  }
  header .navigation .logo {
    display: flex;
    margin: 0 0 70px 24px;
  }
  header .navigation .close-cross {
    top: 28px;
    right: 24px;
  }
  header .navigation .link-wrapper {
    margin: 20px 0 20px 24px;
    height: 70px;
  }
  header .navigation .link-wrapper a {
    height: 70px;
  }
  header .navigation .link-wrapper:last-child {
    margin: 20px 0 20px 24px;
  }
  header .navigation .link-wrapper:last-child a:before {
    bottom: -30px;
  }
  header .navigation .link-wrapper a {
    font-size: 48px;
  }
  header .navigation .link-wrapper:hover a {
    font-size: 58px;
  }
}

.white header .burger-menu {
  border: 2px solid #000;
}

.white header .burger-menu .rect .dot {
  background: #000;
}

.home-page .video-banner {
  position: relative;
  width: 100vw;
  height: calc(99.9vh - 48px);
  padding-top: 48px;
  overflow: hidden;
  margin-bottom: 100px;
}

.home-page .video-banner .cursor-change {
  width: 100%;
  height: 60%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.home-page .video-banner video {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: 0.4s;
}

.home-page .video-banner video.desktop {
  display: block;
}

.home-page .video-banner video.mobile {
  display: none;
}

.home-page .video-banner .text-wrapper {
  position: absolute;
  opacity: 0;
}

.home-page .video-banner .text-wrapper.start {
  animation: start-show-text 1.3s ease-in 0s normal;
  animation-fill-mode: forwards;
}

.home-page .video-banner .text-wrapper h2 {
  font-family: Gosha-b, Verdana;
  color: rgba(255, 255, 255, 0);
  font-size: 111px;
  text-transform: uppercase;
  line-height: 95px;
  letter-spacing: 2.3px;
  -webkit-text-stroke: 1px #fff;
}

.home-page .video-banner .text-wrapper h2.glitch-it {
  position: relative;
  padding: 10px;
}

.home-page .video-banner .text-wrapper h2.glitch-it.animation:before {
  animation: glitch-it-before 1s linear 0s normal;
}

.home-page .video-banner .text-wrapper h2.glitch-it.animation:after {
  animation: glitch-it-after 1s linear 0s normal;
}

.home-page .video-banner .text-wrapper h2.glitch-it:before, .home-page .video-banner .text-wrapper h2.glitch-it:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  color: #161516;
  overflow: hidden;
  padding: 10px;
}

.home-page .video-banner .text-wrapper h2.glitch-it:before {
  left: 2px;
  text-shadow: -2px 0 red;
  opacity: 0;
  -webkit-text-stroke: 1px #fff;
}

.home-page .video-banner .text-wrapper h2.glitch-it:after {
  left: -2px;
  text-shadow: -2px 0 blue;
  opacity: 0;
  -webkit-text-stroke: 1px #fff;
}

.home-page .video-banner .text-wrapper p {
  font-size: 135px;
  line-height: 99px;
  letter-spacing: 3.3px;
  margin-top: 42px;
}

@media screen and (max-width: 2000px) {
  .home-page .video-banner video {
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1800px) {
  .home-page .video-banner .text-wrapper {
    bottom: 30px;
    left: 30px;
  }
  .home-page .video-banner .text-wrapper h2 {
    font-size: 80px;
    line-height: 60px;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 1199px) {
  .home-page .video-banner .text-wrapper h2 {
    font-size: 70px;
    line-height: 50px;
  }
}

@media screen and (max-width: 991px) {
  .home-page .video-banner .text-wrapper h2 {
    font-size: 60px;
    line-height: 40px;
  }
}

@media screen and (max-width: 768px) {
  .home-page .video-banner {
    margin: 0;
  }
  .home-page .video-banner video.desktop {
    display: none;
  }
  .home-page .video-banner video.mobile {
    height: auto !important;
    width: 100% !important;
    display: block;
  }
  .home-page .video-banner .text-wrapper h2 {
    font-size: 45px;
    line-height: 35px;
  }
  .home-page .video-banner .text-wrapper.start {
    animation: start-show-text-mobile 1.3s ease-in 0s normal;
    animation-fill-mode: forwards;
  }
}

@media screen and (max-width: 575px) {
  .home-page .video-banner .text-wrapper {
    bottom: 50%;
    transform: translateY(50%);
    left: 25px;
  }
  .home-page .video-banner .text-wrapper h2 {
    font-size: 34px;
    line-height: 30px;
    letter-spacing: 1px;
  }
}

.home-page .list-services {
  width: 100vw;
  height: 99.9vh;
  display: flex;
  align-items: center;
}

.home-page .list-services .container {
  position: relative;
}

.home-page .list-services .services-wrapper {
  z-index: 1;
  max-width: 30%;
  width: 100%;
  margin-left: 230px;
}

.home-page .list-services .services-wrapper .single-service {
  transition: 0.6s;
  white-space: nowrap;
  margin-bottom: 50px;
  height: 130px;
}

.home-page .list-services .services-wrapper .single-service:last-child {
  margin-bottom: 0;
}

.home-page .list-services .services-wrapper .single-service img {
  display: none;
}

.home-page .list-services .services-wrapper .single-service .title {
  font-family: Gosha-b, Verdana;
  font-size: 60px;
  line-height: 65px;
  color: #fff;
  margin-bottom: 15px;
  transition: 0.5s;
}

.home-page .list-services .services-wrapper .single-service .subtitle {
  font-family: Gilroy-r, Verdana;
  font-size: 18px;
  color: #fff;
  transition: 1s;
  white-space: normal;
  max-width: 460px;
}

.home-page .list-services .services-wrapper .single-service.active {
  padding-left: 160px;
  margin-right: -160px;
}

.home-page .list-services .services-wrapper .single-service.active .title {
  font-size: 126px;
  margin-bottom: 50px;
  -webkit-text-stroke: 1px #fff;
  color: rgba(255, 255, 255, 0);
}

.home-page .list-services .services-wrapper .single-service.active .subtitle {
  padding-left: 10px;
}

.home-page .list-services .wrapper {
  position: absolute;
  right: 0;
}

.home-page .list-services .single-service-img {
  position: absolute;
  top: calc(50% + 30px);
  transform: translateY(-50%);
  right: 45px;
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;
}

.home-page .list-services .single-service-img .number {
  position: absolute;
  right: -75px;
  top: -170px;
}

.home-page .list-services .single-service-img .number.two {
  right: -78px;
  top: -144px;
}

.home-page .list-services .single-service-img .number.three {
  right: -140px;
  top: -125px;
}

.home-page .list-services .single-service-img.show-img {
  opacity: 1;
  animation: changeImg 0.4s ease-in-out 0s normal;
  animation-fill-mode: forwards;
  pointer-events: auto;
}

.home-page .list-services .single-service-img img {
  object-fit: none;
}

.home-page .list-services .single-service-img:before, .home-page .list-services .single-service-img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: contain;
  z-index: 1;
  opacity: 0;
}

.home-page .list-services .single-service-img:hover:before {
  left: 4px;
  opacity: 0.7;
}

.home-page .list-services .single-service-img:hover:after {
  left: -4px;
  opacity: 0.9;
}

.home-page .list-services .single-service-img:hover.development:before, .home-page .list-services .single-service-img:hover.development:after {
  background-image: url(../../../media/img/list-services/development.png);
}

.home-page .list-services .single-service-img:hover.development:before {
  animation: image-glitch-before-dev 2s linear 0s infinite alternate-reverse;
}

.home-page .list-services .single-service-img:hover.development:after {
  animation: image-glitch-after-dev 7s linear 0s infinite alternate-reverse;
}

.home-page .list-services .single-service-img:hover.design:before, .home-page .list-services .single-service-img:hover.design:after {
  background-image: url(../../../media/img/list-services/design.png);
}

.home-page .list-services .single-service-img:hover.design:before {
  animation: image-glitch-before-des 2s linear 0s infinite alternate-reverse;
}

.home-page .list-services .single-service-img:hover.design:after {
  animation: image-glitch-after-des 7s linear 0s infinite alternate-reverse;
}

.home-page .list-services .single-service-img:hover.marketing:before, .home-page .list-services .single-service-img:hover.marketing:after {
  background-image: url(../../../media/img/list-services/marketing.png);
}

.home-page .list-services .single-service-img:hover.marketing:before {
  animation: image-glitch-before-mar 2s linear 0s infinite alternate-reverse;
}

.home-page .list-services .single-service-img:hover.marketing:after {
  animation: image-glitch-after-mar 7s linear 0s infinite alternate-reverse;
}

.home-page .list-services .single-service-img:hover.consulting:before, .home-page .list-services .single-service-img:hover.consulting:after {
  background-image: url(../../../media/img/list-services/consulting.png);
}

.home-page .list-services .single-service-img:hover.consulting:before {
  animation: image-glitch-before-con 2s linear 0s infinite alternate-reverse;
}

.home-page .list-services .single-service-img:hover.consulting:after {
  animation: image-glitch-after-con 7s linear 0s infinite alternate-reverse;
}

@media screen and (max-width: 2010px) {
  .home-page .list-services .wrapper {
    transform: scale(0.95);
  }
  .home-page .list-services .single-service-img {
    right: 0;
  }
}

@media screen and (max-width: 1900px) {
  .home-page .list-services .wrapper {
    transform: scale(0.86);
  }
}

@media screen and (max-width: 1800px) {
  .home-page .list-services .wrapper {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 1700px) {
  .home-page .list-services .wrapper {
    transform: scale(0.74);
  }
  .home-page .list-services .single-service-img {
    top: 50%;
  }
}

@media screen and (max-width: 1600px) {
  .home-page .list-services .services-wrapper {
    margin-left: 200px;
  }
  .home-page .list-services .services-wrapper .single-service {
    margin-bottom: 50px;
    height: 110px;
  }
  .home-page .list-services .services-wrapper .single-service .title {
    font-size: 54px;
    line-height: 45px;
  }
  .home-page .list-services .services-wrapper .single-service.active {
    padding-left: 100px;
    margin-right: -100px;
  }
  .home-page .list-services .services-wrapper .single-service.active .title {
    font-size: 80px;
  }
}

@media screen and (max-width: 1500px) {
  .home-page .list-services .services-wrapper {
    margin-left: 150px;
  }
}

@media screen and (max-width: 1400px) {
  .home-page .list-services .services-wrapper {
    margin-left: 100px;
  }
  .home-page .list-services .services-wrapper .single-service .title {
    margin-bottom: 20px;
  }
  .home-page .list-services .services-wrapper .single-service .subtitle {
    font-size: 15px;
  }
  .home-page .list-services .services-wrapper .single-service.active {
    padding-left: 50px;
    margin-right: -50px;
  }
  .home-page .list-services .services-wrapper .single-service.active .title {
    font-size: 70px;
  }
}

@media screen and (max-width: 1300px) {
  .home-page .list-services .wrapper {
    transform: scale(0.65);
  }
  .home-page .list-services .services-wrapper {
    margin-left: 80px;
    max-width: 33%;
  }
  .home-page .list-services .services-wrapper .single-service.active {
    padding-left: 35px;
    margin-right: -35px;
  }
}

@media screen and (max-width: 1200px) {
  .home-page .list-services .wrapper {
    transform: scale(0.64);
  }
  .home-page .list-services .services-wrapper {
    margin-left: 0;
  }
  .home-page .list-services .services-wrapper .single-service.active {
    padding-left: 35px;
    margin-right: -35px;
  }
}

@media screen and (max-width: 1100px) {
  .home-page .list-services .wrapper {
    transform: scale(0.55);
  }
}

@media screen and (max-width: 991px) {
  .home-page .list-services .wrapper {
    transform: scale(0.3);
  }
  .home-page .list-services .services-wrapper {
    max-width: 100%;
  }
  .home-page .list-services .services-wrapper .single-service {
    height: unset;
  }
  .home-page .list-services .services-wrapper .single-service .title {
    font-size: 60px;
    line-height: 65px;
    margin-bottom: 25px;
  }
  .home-page .list-services .services-wrapper .single-service .subtitle {
    font-size: 18px;
  }
  .home-page .list-services .services-wrapper .single-service.active {
    padding-left: 35px;
    margin-right: -35px;
  }
  .home-page .list-services .services-wrapper .single-service.active .title {
    font-size: 100px;
  }
}

@media screen and (max-width: 767px) {
  .home-page .list-services {
    height: auto;
  }
  .home-page .list-services .wrapper {
    display: none;
  }
  .home-page .list-services .services-wrapper .single-service {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 120px;
  }
  .home-page .list-services .services-wrapper .single-service .text-wrapper {
    width: 260px;
  }
  .home-page .list-services .services-wrapper .single-service img {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
  .home-page .list-services .services-wrapper .single-service img.dev {
    width: 240px;
    right: -10px;
  }
  .home-page .list-services .services-wrapper .single-service img.des {
    width: 256px;
    right: -26px;
  }
  .home-page .list-services .services-wrapper .single-service img.mark {
    width: 260px;
    right: -30px;
  }
  .home-page .list-services .services-wrapper .single-service img.cons {
    width: 246px;
    right: -16px;
  }
  .home-page .list-services .services-wrapper .single-service .title {
    font-size: 45px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  .home-page .list-services .services-wrapper .single-service .subtitle {
    font-size: 18px;
  }
  .home-page .list-services .services-wrapper .single-service.active {
    padding-left: 0;
    margin-right: 0;
  }
  .home-page .list-services .services-wrapper .single-service.active .title {
    font-size: 50px;
    margin-bottom: 20px;
  }
  .home-page .list-services .services-wrapper .single-service.active .subtitle {
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 575px) {
  .home-page .list-services .services-wrapper .single-service {
    margin-bottom: 50px;
  }
  .home-page .list-services .services-wrapper .single-service .text-wrapper {
    width: calc(100% - 140px);
  }
  .home-page .list-services .services-wrapper .single-service img {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
  .home-page .list-services .services-wrapper .single-service img.dev {
    width: 120px;
    right: -6px;
  }
  .home-page .list-services .services-wrapper .single-service img.des {
    width: 123px;
    right: -13px;
  }
  .home-page .list-services .services-wrapper .single-service img.mark {
    width: 130px;
    right: -15px;
  }
  .home-page .list-services .services-wrapper .single-service img.cons {
    width: 123px;
    right: -8px;
  }
  .home-page .list-services .services-wrapper .single-service .title {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .home-page .list-services .services-wrapper .single-service .subtitle {
    font-size: 12px;
    line-height: 12px;
    white-space: normal;
  }
  .home-page .list-services .services-wrapper .single-service.active {
    padding-left: 0;
    margin-right: 0;
  }
  .home-page .list-services .services-wrapper .single-service.active .title {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .home-page .list-services .services-wrapper .single-service.active .subtitle {
    padding: 0;
  }
}

.home-page .ticker {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.home-page .ticker .single-row {
  display: flex;
  animation: ticker 100s linear 0s infinite alternate;
  margin-top: 10px;
  width: fit-content;
}

.home-page .ticker .single-row:hover {
  animation-play-state: paused;
}

.home-page .ticker .single-row:nth-child(odd) {
  animation: ticker 90s linear 0s infinite alternate-reverse;
}

.home-page .ticker .single-row:nth-child(odd):hover {
  animation-play-state: paused;
}

.home-page .ticker .single-row:nth-child(3) {
  animation: ticker 150s linear 0s infinite alternate-reverse;
}

.home-page .ticker .single-row:nth-child(3):hover {
  animation-play-state: paused;
}

.home-page .ticker .single-row span {
  font-family: Gosha-b, Verdana;
  font-size: 200px;
  font-weight: 300;
  line-height: 165px;
  -webkit-text-stroke: 1px #fff;
  color: rgba(255, 255, 255, 0);
  margin: 0 40px;
  display: inline-block;
  white-space: nowrap;
  text-transform: uppercase;
  transition: 0.5s;
}

.home-page .ticker .single-row span:hover {
  color: white;
}

@media screen and (max-width: 1200px) {
  .home-page .ticker .single-row span {
    font-size: 180px;
    line-height: 150px;
  }
}

@media screen and (max-width: 991px) {
  .home-page .ticker .single-row span {
    font-size: 160px;
    line-height: 140px;
  }
}

@media screen and (max-width: 767px) {
  .home-page .ticker {
    height: auto;
    margin: 170px 0;
  }
  .home-page .ticker .single-row span {
    font-size: 130px;
    line-height: 120px;
    margin: 0 30px;
  }
}

@media screen and (max-width: 575px) {
  .home-page .ticker .single-row {
    animation: ticker 35s linear 0s infinite alternate;
  }
  .home-page .ticker .single-row span {
    font-size: 80px;
    line-height: 70px;
    margin: 0 20px;
  }
  .home-page .ticker .single-row:nth-child(odd) {
    animation: ticker 50s linear 0s infinite alternate-reverse;
  }
  .home-page .ticker .single-row:nth-child(3) {
    animation: ticker 75s linear 0s infinite alternate-reverse;
  }
}

.home-page .our-works {
  width: 100vw;
  margin: 0 auto;
  height: 3000px;
  position: relative;
  overflow: hidden;
  background: #161516;
}

.home-page .our-works canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.home-page .our-works canvas.active {
  position: fixed;
}

.home-page .our-works .img-holder {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .our-works .img-holder img {
  height: 98%;
  margin-top: 1px;
  object-fit: cover;
}

.home-page .our-works .img-holder.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 1s transform;
  will-change: transform, top;
  transform: translate3d(0, 0, 0);
}

.home-page .our-works .img-holder.mask svg,
.home-page .our-works .img-holder.mask img {
  height: 100%;
  margin-top: 0;
  object-fit: cover;
}

@media screen and (max-width: 1900px) {
  .home-page .our-works {
    height: 2500px;
  }
}

@media screen and (max-width: 1799px) {
  .home-page .our-works {
    height: 2450px;
  }
}

@media screen and (max-width: 1699px) {
  .home-page .our-works {
    height: 2400px;
  }
}

@media screen and (max-width: 1599px) {
  .home-page .our-works {
    height: 2350px;
  }
}

@media screen and (max-width: 1499px) {
  .home-page .our-works {
    height: 2250px;
  }
}

@media screen and (max-width: 1399px) {
  .home-page .our-works {
    height: 2150px;
  }
}

@media screen and (max-width: 1299px) {
  .home-page .our-works {
    height: 2000px;
  }
}

@media screen and (max-width: 1199px) {
  .home-page .our-works {
    height: 1900px;
  }
}

@media screen and (max-width: 1099px) {
  .home-page .our-works {
    height: 1900px;
  }
}

@media screen and (max-width: 991px) {
  .home-page .our-works {
    height: 1800px;
  }
}

@media screen and (max-width: 767px) {
  .home-page .our-works {
    height: 2000px;
    margin-bottom: 150px;
  }
}

.home-page .our-projects {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: #161516;
}

.home-page .our-projects .click-area {
  position: absolute;
  height: 100%;
  width: 15%;
  z-index: 1;
  top: 0;
}

.home-page .our-projects .click-area.button-left {
  left: 0;
}

.home-page .our-projects .click-area.button-right {
  right: 0;
}

.home-page .our-projects .projects-list {
  position: absolute;
  top: 0;
  display: flex;
  width: 300vw;
  height: 100vh;
  align-items: center;
  will-change: translate;
}

.home-page .our-projects .projects-list.active {
  position: fixed;
  left: 0;
}

.home-page .our-projects .projects-list .single-project {
  display: flex;
  width: 100vw;
  overflow: hidden;
}

.home-page .our-projects .projects-list .single-project .text-wrapper {
  max-width: 685px;
  width: 100%;
  box-sizing: border-box;
  margin-left: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-page .our-projects .projects-list .single-project .text-wrapper .title {
  font-family: Gosha-b, Verdana;
  font-size: 77px;
  letter-spacing: 1.4px;
  -webkit-text-stroke: 1px #fff;
  color: rgba(255, 255, 255, 0);
  white-space: nowrap;
}

.home-page .our-projects .projects-list .single-project .text-wrapper .description {
  font-family: Gilroy-r, Verdana;
  font-size: 16px;
  margin: 65px 0 80px;
  line-height: 22px;
  position: relative;
  max-width: 627px;
}

.home-page .our-projects .projects-list .single-project .text-wrapper .description:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -27px;
  right: 0;
  height: 2px;
  width: 100vw;
  background: #fff;
}

.home-page .our-projects .projects-list .single-project .text-wrapper .more {
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  display: block;
  width: max-content;
}

.home-page .our-projects .projects-list .single-project .media-wrapper {
  width: 818px;
  height: 818px;
  margin: 0 0 0 auto;
  position: relative;
}

.home-page .our-projects .projects-list .single-project .media-wrapper .img-holder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-page .our-projects .projects-list .single-project .media-wrapper .img-holder video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1600px) {
  .home-page .our-projects .projects-list .single-project .text-wrapper {
    margin-left: 200px;
    max-width: 450px;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .title {
    font-size: 55px;
  }
  .home-page .our-projects .projects-list .single-project .media-wrapper {
    width: 700px;
    height: 700px;
  }
}

@media screen and (max-width: 1500px) {
  .home-page .our-projects .projects-list .single-project .text-wrapper {
    margin-left: 150px;
    max-width: 450px;
  }
  .home-page .our-projects .projects-list .single-project .media-wrapper {
    width: 630px;
    height: 630px;
  }
}

@media screen and (max-width: 1400px) {
  .home-page .our-projects .projects-list .single-project .text-wrapper {
    margin-left: 113px;
    margin-top: 70px;
    max-width: 460px;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .title {
    font-size: 51px;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .description {
    margin: 55px 0 80px;
  }
  .home-page .our-projects .projects-list .single-project .media-wrapper {
    width: 550px;
    height: 550px;
  }
}

@media screen and (max-width: 1230px) {
  .home-page .our-projects .projects-list .single-project .text-wrapper {
    margin-left: 0;
    margin-top: 70px;
    max-width: 430px;
  }
  .home-page .our-projects .projects-list .single-project .media-wrapper {
    width: 450px;
    height: 450px;
  }
}

@media screen and (max-width: 991px) {
  .home-page .our-projects {
    height: unset;
    min-height: 100vh;
  }
  .home-page .our-projects .container {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper {
    max-width: unset;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .title {
    color: #fff;
    -webkit-text-stroke: unset;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .description {
    margin: 37px 0;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .description::after {
    bottom: -20px;
    right: 50%;
    transform: translateX(50%);
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .more {
    margin-top: 38px;
    line-height: 1;
    height: 50px;
  }
  .home-page .our-projects .projects-list .single-project .media-wrapper {
    width: 818px;
    height: 818px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 908px) {
  .home-page .our-projects .projects-list .single-project .media-wrapper {
    width: 100%;
    height: unset;
  }
  .home-page .our-projects .projects-list .single-project .media-wrapper .img-holder {
    height: unset;
    position: unset;
    transform: none;
    left: unset;
    top: unset;
  }
  .home-page .our-projects .projects-list .single-project .media-wrapper video {
    height: auto;
  }
}

@media screen and (max-width: 575px) {
  .home-page .our-projects .projects-list .single-project .text-wrapper {
    margin-top: 20px;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .title {
    font-size: 36px;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .description {
    font-size: 14px;
    margin: 20px 0;
    line-height: 18px;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .description::after {
    bottom: -20px;
  }
  .home-page .our-projects .projects-list .single-project .text-wrapper .more {
    font-size: 16px;
    line-height: 20px;
    height: 20px;
    margin-top: 20px;
  }
}

.home-page .our-clients {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
  cursor: none;
  margin-top: 210px;
}

.home-page .our-clients:hover .back {
  opacity: 1;
}

.home-page .our-clients .back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  mix-blend-mode: difference;
  pointer-events: none;
  opacity: 0;
  transition: 0.4s;
}

.home-page .our-clients .logo-wrapper {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #fff;
  position: relative;
  box-sizing: border-box;
}

.home-page .our-clients .logo-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  height: 300px;
  width: calc(100% + 2px);
  left: -1px;
  background: #fff;
  top: 100%;
}

.home-page .our-clients .logo-wrapper .container {
  flex-wrap: wrap;
  margin-top: 260px;
  padding: 0 180px 155px;
}

.home-page .our-clients .logo-wrapper .single-logo {
  width: 25%;
  height: 104px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 95px;
}

.home-page .our-clients .logo-wrapper .single-logo:nth-child(4n + 5), .home-page .our-clients .logo-wrapper .single-logo:first-child {
  justify-content: flex-start;
}

.home-page .our-clients .logo-wrapper .single-logo:nth-child(4n+4) {
  justify-content: flex-end;
}

@media screen and (max-width: 1600px) {
  .home-page .our-clients {
    height: 824px;
  }
  .home-page .our-clients .logo-wrapper .container {
    margin-top: 190px;
    padding: 0 55px 140px;
  }
  .home-page .our-clients .logo-wrapper .single-logo {
    margin-bottom: 60px;
    transform: scale(0.87);
  }
}

@media screen and (max-width: 1300px) {
  .home-page .our-clients .logo-wrapper .container {
    margin-top: 150px;
    padding: 0 0 80px;
  }
}

@media screen and (max-width: 1200px) {
  .home-page .our-clients .logo-wrapper .single-logo img {
    transform: scale(0.7);
  }
}

@media screen and (max-width: 991px) {
  .home-page .our-clients {
    display: none;
  }
}

.home-page .contact-form {
  width: 100vw;
}

.home-page .contact-form .container {
  padding: 272px 0;
  background: #fff;
}

.home-page .contact-form .container .inner-wrapper {
  flex-wrap: wrap;
  padding: 0 230px;
  width: 100%;
}

.home-page .contact-form .title {
  color: #161516;
  font-size: 36px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 0;
  line-height: 49px;
}

.home-page .contact-form .feedback-wrapper {
  width: 100%;
  display: flex;
  color: #161516;
  margin-top: 61px;
  justify-content: space-between;
}

.home-page .contact-form .feedback-wrapper .form {
  display: flex;
  flex-wrap: wrap;
  width: 904px;
  margin: 7px -20px 0;
}

.home-page .contact-form .feedback-wrapper .form .input-holder {
  position: relative;
  height: 40px;
  display: flex;
  margin: 0 20px;
  width: calc(33.33% - 40px);
}

.home-page .contact-form .feedback-wrapper .form .input-holder label {
  position: absolute;
  left: 0;
  bottom: 12px;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
  transition: 0.3s;
}

.home-page .contact-form .feedback-wrapper .form .input-holder input {
  border: none;
  border-bottom: 2px solid #161516;
  margin: auto 0 0 0;
  width: 100%;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
}

.home-page .contact-form .feedback-wrapper .form .input-holder input.itsFull ~ label {
  font-size: 12px;
  bottom: 25px;
}

.home-page .contact-form .feedback-wrapper .form .input-holder input:focus ~ label {
  font-size: 12px;
  bottom: 25px;
}

.home-page .contact-form .feedback-wrapper .form .input-holder.textarea {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 77px;
}

.home-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid #161516;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
}

.home-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea.itsFull ~ label {
  font-size: 12px;
  bottom: 45px;
}

.home-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea:focus ~ label {
  font-size: 12px;
  bottom: 45px;
}

.home-page .contact-form .feedback-wrapper .form .input-holder.button {
  width: 150px;
  border: none;
  padding: 0;
}

.home-page .contact-form .feedback-wrapper .form .input-holder.button input {
  display: none;
}

.home-page .contact-form .feedback-wrapper .form .input-holder.button label {
  position: unset;
  color: #161516;
  text-transform: uppercase;
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: none;
}

.home-page .contact-form .feedback-wrapper .form .input-holder.button:hover {
  background: none;
  color: #161516;
}

.home-page .contact-form .feedback-wrapper .form .button {
  color: #161516;
  text-transform: uppercase;
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  margin: 0 20px;
  letter-spacing: 3.9px;
  border: 2px solid #161516;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 68px;
  background: inherit;
  transition: 0.5s;
  cursor: none;
}

.home-page .contact-form .feedback-wrapper .form .button:hover {
  background: #161516;
  color: #fff;
}

.home-page .contact-form .feedback-wrapper .form .button.upload {
  border: none;
  padding: 0;
}

.home-page .contact-form .feedback-wrapper .contacts {
  width: 324px;
}

.home-page .contact-form .feedback-wrapper .contacts .text {
  color: #161516;
  font-size: 16px;
  font-family: Gosha-r, Verdana;
  letter-spacing: -0.32px;
  margin-bottom: 34px;
  line-height: 23px;
}

.home-page .contact-form .feedback-wrapper .contacts a,
.home-page .contact-form .feedback-wrapper .contacts .locate {
  color: #161516;
  font-size: 16px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 0;
  margin-bottom: 10px;
  display: block;
}

.home-page .contact-form .feedback-wrapper .contacts .social {
  margin: 34px 0 18px;
}

.home-page .contact-form .feedback-wrapper .contacts .social-links {
  display: flex;
  margin: 0 -11px;
}

.home-page .contact-form .feedback-wrapper .contacts .social-links .single-link {
  margin: 0 11px;
}

@media screen and (max-width: 1800px) {
  .home-page .contact-form .feedback-wrapper .form {
    width: 850px;
  }
  .home-page .contact-form .feedback-wrapper .contacts {
    width: 240px;
  }
}

@media screen and (max-width: 1700px) {
  .home-page .contact-form .container .inner-wrapper {
    padding: 0 180px;
  }
  .home-page .contact-form .feedback-wrapper .form {
    width: 800px;
  }
}

@media screen and (max-width: 1700px) {
  .home-page .contact-form .container .inner-wrapper {
    padding: 0 130px;
  }
}

@media screen and (max-width: 1400px) {
  .home-page .contact-form {
    height: auto;
  }
  .home-page .contact-form .container {
    padding: 202px 0 217px;
  }
  .home-page .contact-form .container .inner-wrapper {
    padding: 0 100px;
  }
  .home-page .contact-form .container .inner-wrapper .title {
    font-size: 30px;
  }
  .home-page .contact-form .feedback-wrapper .form {
    width: 750px;
  }
  .home-page .contact-form .feedback-wrapper .contacts {
    margin-top: 14px;
    width: 268px;
  }
}

@media screen and (max-width: 1300px) {
  .home-page .contact-form .container {
    position: relative;
    padding: 202px 20px 217px;
    max-width: calc(100% - 130px);
  }
  .home-page .contact-form .container:after {
    content: '';
    display: block;
    width: calc(100vw - 90px);
    height: calc(100% + 1px);
    background: #fff;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .home-page .contact-form .container .inner-wrapper {
    padding: 0;
  }
  .home-page .contact-form .container .inner-wrapper .title br {
    display: none;
  }
  .home-page .contact-form .feedback-wrapper .form {
    width: calc(100% - 268px);
  }
  .home-page .contact-form .feedback-wrapper .contacts {
    margin-top: 40px;
  }
}

@media screen and (max-width: 991px) {
  .home-page .contact-form .feedback-wrapper {
    flex-direction: column;
  }
  .home-page .contact-form .feedback-wrapper .form {
    width: calc(100% + 40px);
  }
}

@media screen and (max-width: 767px) {
  .home-page .contact-form .feedback-wrapper .form {
    width: 100%;
    margin: 0;
  }
  .home-page .contact-form .feedback-wrapper .form .input-holder {
    width: 100%;
    margin: 0 0 40px;
  }
  .home-page .contact-form .feedback-wrapper .form .button {
    margin: 0 40px 0 0;
  }
}

@media screen and (max-width: 575px) {
  .home-page .contact-form .container {
    max-width: calc(100% - 48px);
    padding: 115px 0 90px;
  }
  .home-page .contact-form .container:after {
    width: 100vw;
    top: 0;
  }
  .home-page .contact-form .title {
    font-size: 30px;
  }
  .home-page .contact-form .feedback-wrapper {
    margin-top: 60px;
  }
  .home-page .contact-form .feedback-wrapper .contacts {
    width: 100%;
  }
  .home-page .contact-form .feedback-wrapper .form {
    flex-direction: column;
    align-items: center;
  }
  .home-page .contact-form .feedback-wrapper .form .button {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 0 15px 0;
  }
}

.works-page {
  background: #fff;
  padding-top: 133px;
}

.works-page header {
  position: absolute;
  left: 0;
  top: 48px;
  width: calc(100% - 90px);
  z-index: 2;
  padding: 0 45px;
}

.works-page header .logo p {
  font-family: Gosha-b, Verdana;
  color: #161516;
  font-size: 18px;
}

.works-page header .logo span {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #161516;
  margin-left: 9px;
  margin-bottom: -3px;
}

.works-page header .burger-menu {
  width: 46px;
  height: 46px;
  border: 2px solid #161516;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  z-index: 2;
}

.works-page header .burger-menu .rect {
  width: 16px;
  height: 16px;
  position: relative;
  transform: rotate(45deg);
}

.works-page header .burger-menu .dot {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background: #161516;
  display: block;
  transition: 1s cubic-bezier(0, 0.36, 1, 0.63);
}

.works-page header .burger-menu .dot:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.works-page header .burger-menu .dot:nth-child(2) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.works-page header .burger-menu .dot:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.works-page header .burger-menu .dot:nth-child(4) {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.works-page header .burger-menu.animate-cross {
  border: 2px solid #fff;
}

.works-page header .burger-menu.animate-cross .dot {
  background: #fff;
}

.works-page header .burger-menu.animate-cross .dot:nth-child(1) {
  height: 100%;
  width: 3px;
}

.works-page header .burger-menu.animate-cross .dot:nth-child(2) {
  width: 100%;
  height: 3px;
}

.works-page header .burger-menu.animate-cross .dot:nth-child(3) {
  height: 100%;
  width: 3px;
}

.works-page header .burger-menu.animate-cross .dot:nth-child(4) {
  width: 100%;
  height: 3px;
}

.works-page header .navigation {
  position: absolute;
  background: #161516;
  top: -50px;
  left: 200vw;
  width: 100vw;
  height: calc(100vh + 50px);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  transform: rotate(-180deg);
  transition: 1s ease-in-out;
}

.works-page header .navigation.show {
  left: 0;
}

.works-page header .navigation .logo {
  display: none;
}

.works-page header .navigation .close-cross {
  width: 46px;
  height: 46px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 50px;
  left: 50px;
  z-index: 2;
  box-sizing: border-box;
}

.works-page header .navigation .close-cross:after, .works-page header .navigation .close-cross:before {
  content: '';
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 1px;
}

.works-page header .navigation .close-cross:after {
  width: 16px;
  height: 4px;
}

.works-page header .navigation .close-cross:before {
  width: 4px;
  height: 16px;
}

.works-page header .navigation .link-wrapper {
  writing-mode: tb;
  margin: 46px 80px 0;
}

.works-page header .navigation .link-wrapper a {
  font-family: Gosha-b, Verdana;
  color: #fff;
  font-size: 180px;
  text-transform: capitalize;
  transition: 0.4s;
  position: relative;
}

.works-page header .navigation .link-wrapper a:after {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(100% + 140px);
  background: #fff;
  top: 0;
  right: 0;
}

.works-page header .navigation .link-wrapper a:hover {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px #fff;
}

.works-page header .navigation .link-wrapper:last-child {
  margin: 46px 80px 0 160px;
}

.works-page header .navigation .link-wrapper:last-child a:before {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(100% - 100px);
  background: #fff;
  top: 0;
  left: -160px;
}

@media screen and (max-width: 2000px) {
  .works-page header .navigation .link-wrapper a {
    font-size: 120px;
  }
}

@media screen and (max-width: 1800px) {
  .works-page header .navigation .link-wrapper {
    margin: 40px 50px 0;
  }
  .works-page header .navigation .link-wrapper a {
    font-size: 100px;
  }
  .works-page header .navigation .link-wrapper a:after {
    left: -50px;
    height: calc(100% + 80px);
  }
  .works-page header .navigation .link-wrapper a:last-child {
    margin: 40px 50px 0;
  }
  .works-page header .navigation .link-wrapper a:last-child a:before {
    left: -50px;
    height: calc(100% - 60px);
  }
  .works-page header .navigation .link-wrapper:last-child {
    margin: 40px 50px 160px;
  }
  .works-page header .navigation .link-wrapper:last-child a:before {
    left: -80px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 1400px) {
  .works-page header .navigation .link-wrapper a {
    font-size: 80px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 991px) {
  .works-page header .navigation .link-wrapper {
    margin: 40px 40px 0;
  }
  .works-page header .navigation .link-wrapper:last-child {
    margin: 40px 40px 0;
  }
  .works-page header .navigation .link-wrapper:last-child a:before {
    left: -40px;
  }
  .works-page header .navigation .link-wrapper a {
    font-size: 60px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 767px) {
  .works-page header .navigation {
    transform: rotate(0);
    flex-direction: column;
  }
  .works-page header .navigation .close-cross {
    bottom: unset;
    left: unset;
    top: 50px;
    right: 50px;
  }
  .works-page header .navigation .link-wrapper {
    writing-mode: initial;
    margin: 30px 0 30px 50px;
  }
  .works-page header .navigation .link-wrapper:last-child a:before {
    width: 100%;
    height: 1.5px;
    right: unset;
    left: 0;
    top: unset;
    bottom: -40px;
  }
  .works-page header .navigation .link-wrapper a:after {
    left: 0;
    right: unset;
    width: 120%;
    height: 1px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 575px) {
  .works-page header {
    top: 30px;
    width: calc(100% - 50px);
    padding: 0 25px;
  }
  .works-page header .navigation {
    overflow-y: auto;
    top: -30px;
  }
  .works-page header .navigation .logo {
    display: flex;
    margin: 0 0 70px 24px;
  }
  .works-page header .navigation .close-cross {
    top: 28px;
    right: 24px;
  }
  .works-page header .navigation .link-wrapper {
    margin: 20px 0 20px 24px;
    height: 70px;
  }
  .works-page header .navigation .link-wrapper a {
    height: 70px;
  }
  .works-page header .navigation .link-wrapper:last-child {
    margin: 20px 0 20px 24px;
  }
  .works-page header .navigation .link-wrapper:last-child a:before {
    bottom: -30px;
  }
  .works-page header .navigation .link-wrapper a {
    font-size: 48px;
  }
  .works-page header .navigation .link-wrapper:hover a {
    font-size: 58px;
  }
}

.works-page .title-page {
  font-size: 105px;
  color: #161516;
  font-family: Gosha-b, Verdana;
  padding-left: 222px;
  letter-spacing: 2px;
}

@media screen and (max-width: 1600px) {
  .works-page .title-page {
    padding-left: 200px;
    font-size: 80px;
  }
}

@media screen and (max-width: 1500px) {
  .works-page .title-page {
    padding-left: 150px;
  }
}

@media screen and (max-width: 1400px) {
  .works-page .title-page {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .works-page .title-page {
    padding-left: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .works-page .title-page {
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .works-page .title-page {
    max-width: 100%;
    font-size: 60px;
  }
}

.works-page .contact-form {
  width: 100vw;
}

.works-page .contact-form .container {
  padding: 272px 0;
  background: #161516;
}

.works-page .contact-form .container .inner-wrapper {
  flex-wrap: wrap;
  padding: 0 230px;
  width: 100%;
}

.works-page .contact-form .title {
  color: #fff;
  font-size: 36px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 0;
  line-height: 49px;
}

.works-page .contact-form .feedback-wrapper {
  width: 100%;
  display: flex;
  color: #161516;
  margin-top: 61px;
  justify-content: space-between;
}

.works-page .contact-form .feedback-wrapper .form {
  display: flex;
  flex-wrap: wrap;
  width: 904px;
  margin: 7px -20px 0;
}

.works-page .contact-form .feedback-wrapper .form .input-holder {
  position: relative;
  height: 40px;
  display: flex;
  margin: 0 20px;
  width: calc(33.33% - 40px);
}

.works-page .contact-form .feedback-wrapper .form .input-holder label {
  position: absolute;
  left: 0;
  bottom: 12px;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
  transition: 0.3s;
  color: #fff;
}

.works-page .contact-form .feedback-wrapper .form .input-holder input {
  color: #fff;
  background: #161516;
  border: none;
  border-bottom: 2px solid #fff;
  margin: auto 0 0 0;
  width: 100%;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
}

.works-page .contact-form .feedback-wrapper .form .input-holder input.itsFull ~ label {
  font-size: 12px;
  bottom: 25px;
}

.works-page .contact-form .feedback-wrapper .form .input-holder input:focus ~ label {
  font-size: 12px;
  bottom: 25px;
}

.works-page .contact-form .feedback-wrapper .form .input-holder.textarea {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 77px;
}

.works-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea {
  outline: none;
  color: #fff;
  background: #161516;
  width: 100%;
  border: none;
  border-bottom: 2px solid #fff;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
}

.works-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea.itsFull ~ label {
  font-size: 12px;
  bottom: 45px;
}

.works-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea:focus ~ label {
  font-size: 12px;
  bottom: 45px;
}

.works-page .contact-form .feedback-wrapper .form .input-holder.button {
  width: 150px;
  border: none;
  padding: 0;
}

.works-page .contact-form .feedback-wrapper .form .input-holder.button input {
  display: none;
}

.works-page .contact-form .feedback-wrapper .form .input-holder.button label {
  position: unset;
  color: #fff;
  text-transform: uppercase;
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: none;
}

.works-page .contact-form .feedback-wrapper .form .input-holder.button:hover {
  background: none;
  color: #161516;
}

.works-page .contact-form .feedback-wrapper .form .button {
  color: #fff;
  border: 2px solid #fff;
  text-transform: uppercase;
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  margin: 0 20px;
  letter-spacing: 3.9px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 68px;
  background: inherit;
  transition: 0.5s;
  cursor: none;
}

.works-page .contact-form .feedback-wrapper .form .button:hover {
  background: #161516;
  color: #fff;
}

.works-page .contact-form .feedback-wrapper .form .button.upload {
  border: none;
  padding: 0;
}

.works-page .contact-form .feedback-wrapper .contacts {
  width: 324px;
}

.works-page .contact-form .feedback-wrapper .contacts .text {
  color: #fff;
  font-size: 16px;
  font-family: Gosha-r, Verdana;
  letter-spacing: -0.32px;
  margin-bottom: 34px;
  line-height: 23px;
}

.works-page .contact-form .feedback-wrapper .contacts a,
.works-page .contact-form .feedback-wrapper .contacts .locate {
  color: #fff;
  font-size: 16px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 0;
  margin-bottom: 10px;
  display: block;
}

.works-page .contact-form .feedback-wrapper .contacts .social {
  margin: 34px 0 18px;
}

.works-page .contact-form .feedback-wrapper .contacts .social-links {
  display: flex;
  margin: 0 -11px;
}

.works-page .contact-form .feedback-wrapper .contacts .social-links .single-link {
  margin: 0 11px;
}

@media screen and (max-width: 1800px) {
  .works-page .contact-form .feedback-wrapper .form {
    width: 850px;
  }
  .works-page .contact-form .feedback-wrapper .contacts {
    width: 240px;
  }
}

@media screen and (max-width: 1700px) {
  .works-page .contact-form .container .inner-wrapper {
    padding: 0 180px;
  }
  .works-page .contact-form .feedback-wrapper .form {
    width: 800px;
  }
}

@media screen and (max-width: 1700px) {
  .works-page .contact-form .container .inner-wrapper {
    padding: 0 130px;
  }
}

@media screen and (max-width: 1400px) {
  .works-page .contact-form {
    height: auto;
  }
  .works-page .contact-form .container {
    padding: 202px 0 217px;
  }
  .works-page .contact-form .container .inner-wrapper {
    padding: 0 100px;
  }
  .works-page .contact-form .container .inner-wrapper .title {
    font-size: 30px;
  }
  .works-page .contact-form .feedback-wrapper .form {
    width: 750px;
  }
  .works-page .contact-form .feedback-wrapper .contacts {
    margin-top: 14px;
    width: 268px;
  }
}

@media screen and (max-width: 1300px) {
  .works-page .contact-form .container {
    position: relative;
    padding: 202px 20px 217px;
    max-width: calc(100% - 130px);
  }
  .works-page .contact-form .container:after {
    content: '';
    display: block;
    width: calc(100vw - 90px);
    height: calc(100% + 1px);
    background: #fff;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .works-page .contact-form .container .inner-wrapper {
    padding: 0;
  }
  .works-page .contact-form .container .inner-wrapper .title br {
    display: none;
  }
  .works-page .contact-form .feedback-wrapper .form {
    width: calc(100% - 268px);
  }
  .works-page .contact-form .feedback-wrapper .contacts {
    margin-top: 40px;
  }
}

@media screen and (max-width: 991px) {
  .works-page .contact-form .feedback-wrapper {
    flex-direction: column;
  }
  .works-page .contact-form .feedback-wrapper .form {
    width: calc(100% + 40px);
  }
}

@media screen and (max-width: 767px) {
  .works-page .contact-form .feedback-wrapper .form {
    width: 100%;
    margin: 0;
  }
  .works-page .contact-form .feedback-wrapper .form .input-holder {
    width: 100%;
    margin: 0 0 40px;
  }
  .works-page .contact-form .feedback-wrapper .form .button {
    margin: 0 40px 0 0;
  }
}

@media screen and (max-width: 575px) {
  .works-page .contact-form {
    background: #161516;
  }
  .works-page .contact-form .container {
    max-width: calc(100% - 48px);
    padding: 115px 0 90px;
  }
  .works-page .contact-form .container:after {
    width: 100vw;
    top: 0;
  }
  .works-page .contact-form .title {
    font-size: 30px;
  }
  .works-page .contact-form .feedback-wrapper {
    margin-top: 60px;
  }
  .works-page .contact-form .feedback-wrapper .contacts {
    width: 100%;
  }
  .works-page .contact-form .feedback-wrapper .form {
    flex-direction: column;
    align-items: center;
  }
  .works-page .contact-form .feedback-wrapper .form .button {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 0 15px 0;
  }
}

.works-page .works-list {
  margin-top: 155px;
  margin-bottom: 192px;
}

.works-page .works-list .works-wrapper {
  z-index: 1;
  max-width: 75%;
  width: 100%;
  margin-left: 226px;
}

.works-page .works-list .works-wrapper .single-work {
  transition: 0.6s;
  white-space: nowrap;
  height: unset;
  border-bottom: 3px solid #161516;
  margin-bottom: 28px;
  padding-bottom: 24px;
  position: relative;
}

.works-page .works-list .works-wrapper .single-work:last-child {
  margin-bottom: 0;
}

.works-page .works-list .works-wrapper .single-work .title {
  font-family: Gosha-b, Verdana;
  font-size: 60px;
  line-height: 65px;
  color: #161516;
  margin-bottom: 55px;
  transition: 0.5s;
}

.works-page .works-list .works-wrapper .single-work .subtitle {
  font-family: Gilroy-r, Verdana;
  font-size: 16px;
  color: #161516;
  transition: 1s;
  white-space: normal;
  max-width: 460px;
  margin-left: 5px;
}

.works-page .works-list .works-wrapper .single-work .single-work-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 718px;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
  width: 820px;
  height: 820px;
  z-index: 0;
  overflow: hidden;
}

.works-page .works-list .works-wrapper .single-work .single-work-img .title.second_title {
  position: absolute;
  top: calc(50% - 36px);
  left: -715px;
  transform: translateY(-50%);
  -webkit-text-stroke: 1px #fff;
}

.works-page .works-list .works-wrapper .single-work .single-work-img .subtitle.second_subtitle {
  display: none;
}

.works-page .works-list .works-wrapper .single-work .single-work-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.works-page .works-list .works-wrapper .single-work.active {
  z-index: 2;
}

.works-page .works-list .works-wrapper .single-work.active .title {
  position: relative;
  font-size: 98px;
  letter-spacing: -1.6px;
  line-height: 0.9;
  margin-bottom: 32px;
  -webkit-text-stroke: 1px #161516;
  color: rgba(0, 0, 0, 0);
}

.works-page .works-list .works-wrapper .single-work.active .single-work-img {
  z-index: 1;
  opacity: 1;
}

.works-page .works-list .single-work-img {
  position: absolute;
  top: 0;
  right: 45px;
  opacity: 1;
  transition: 0.2s;
  pointer-events: none;
  width: 820px;
  height: 820px;
}

.works-page .works-list .single-work-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1600px) {
  .works-page .works-list .works-wrapper {
    margin-left: 200px;
  }
  .works-page .works-list .works-wrapper .single-work .title {
    font-size: 50px;
    margin-bottom: 25px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img {
    left: 650px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img .title.second_title {
    left: -647px;
  }
  .works-page .works-list .works-wrapper .single-work.active .title {
    font-size: 80px;
  }
}

@media screen and (max-width: 1500px) {
  .works-page .works-list .works-wrapper {
    margin-left: 150px;
  }
}

@media screen and (max-width: 1400px) {
  .works-page .works-list .works-wrapper {
    margin-left: 100px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img {
    width: 620px;
    height: 620px;
  }
}

@media screen and (max-width: 1300px) {
  .works-page .works-list .works-wrapper {
    margin-left: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .works-page .works-list .works-wrapper {
    margin-left: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .works-page .works-list .works-wrapper .single-work .title {
    font-size: 45px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img {
    left: 550px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img .title.second_title {
    left: -550px;
  }
  .works-page .works-list .works-wrapper .single-work.active .title {
    font-size: 70px;
  }
}

@media screen and (max-width: 991px) {
  .works-page .works-list .works-wrapper .single-work .title {
    font-size: 40px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img {
    left: 450px;
    width: 500px;
    height: 500px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img .title.second_title {
    left: -450px;
  }
  .works-page .works-list .works-wrapper .single-work.active .title {
    font-size: 65px;
  }
}

@media screen and (max-width: 768px) {
  .works-page .works-list .works-wrapper .single-work .title {
    font-size: 40px;
    letter-spacing: -1.6px;
    line-height: 0.9;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img {
    left: 387px;
    top: calc(50% + 2px);
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img .title.second_title {
    color: #fff;
    left: -387px;
    -webkit-text-stroke: unset;
  }
  .works-page .works-list .works-wrapper .single-work.active .title {
    letter-spacing: -1.6px;
    line-height: 0.9;
    margin-bottom: 25px;
    font-size: 40px;
    color: #161516;
    -webkit-text-stroke: unset;
  }
}

@media screen and (max-width: 685px) {
  .works-page .works-list .works-wrapper .single-work .title {
    font-size: 20px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img {
    left: 270px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img .title.second_title {
    left: -270px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img .subtitle.second_subtitle {
    color: #fff;
    position: absolute;
    top: calc(50% + 8px);
    transform: translateY(-50%);
    left: -270px;
    display: block;
  }
  .works-page .works-list .works-wrapper .single-work.active .title {
    font-size: 20px;
  }
}

@media screen and (max-width: 475px) {
  .works-page .works-list {
    margin-top: 102px;
    margin-bottom: 108px;
  }
  .works-page .works-list .works-wrapper .single-work {
    margin-bottom: 70px;
  }
  .works-page .works-list .works-wrapper .single-work .title {
    width: 272px;
    letter-spacing: -0.1px;
  }
  .works-page .works-list .works-wrapper .single-work .subtitle {
    width: 272px;
    margin: 0;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img {
    width: 277px;
    height: 277px;
    left: 157px;
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img .title.second_title {
    left: -157px;
    top: calc(50% - 45.2px);
  }
  .works-page .works-list .works-wrapper .single-work .single-work-img .subtitle.second_subtitle {
    width: 272px;
    left: -157px;
  }
  .works-page .works-list .works-wrapper .single-work.active .title {
    letter-spacing: -0.1px;
  }
}

.works-page footer {
  padding: 167px 0 170px;
  background: #fff;
}

.works-page footer .container {
  justify-content: space-between;
  padding: 0 470px;
  width: calc(100% - 940px);
}

.works-page footer .container .left-side {
  display: flex;
}

.works-page footer .container .left-side .logo-copyright {
  padding-right: 94px;
  position: relative;
}

.works-page footer .container .left-side .logo-copyright:after {
  position: absolute;
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background-color: #161516;
  right: 0;
  bottom: 0;
}

.works-page footer .container .left-side .logo-copyright p {
  margin-top: 50px;
  font-size: 16px;
  font-family: Gosha-r, Verdana;
}

.works-page footer .container .left-side .logo-copyright .logo {
  font-weight: 600;
}

.works-page footer .container .left-side .logo-copyright .logo p {
  color: #161516;
  margin: 0;
  font-size: 32px;
  letter-spacing: 0.7px;
}

.works-page footer .container .left-side .logo-copyright .logo span {
  width: 28px;
  height: 28px;
  border: 2px solid #161516;
  margin-bottom: -7px;
}

.works-page footer .container .left-side .navigation {
  margin-left: 90px;
  margin-top: 5px;
}

.works-page footer .container .left-side .navigation li {
  margin-bottom: 15px;
}

.works-page footer .container .left-side .navigation li:last-child {
  margin: 0;
}

.works-page footer .container .left-side .navigation li a {
  font-size: 18px;
  font-family: Gosha-r, Verdana;
  color: #161516;
}

.works-page footer .container .social-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
}

.works-page footer .container .social-block a,
.works-page footer .container .social-block .locate {
  font-family: Gosha-r, Verdana;
  color: #161516;
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
}

.works-page footer .container .social-block .social-links {
  display: flex;
  margin: 30px -15px 0;
}

.works-page footer .container .social-block .social-links .single-link {
  margin: 0 15px;
}

@media screen and (max-width: 2000px) {
  .works-page footer .container {
    padding: 0;
    width: calc(100% - 90px);
  }
  .works-page footer .container .left-side {
    margin-left: 230px;
  }
  .works-page footer .container .social-block {
    margin-right: 230px;
  }
}

@media screen and (max-width: 1700px) {
  .works-page footer .container .left-side {
    margin-left: 130px;
  }
  .works-page footer .container .social-block {
    margin-right: 130px;
  }
}

@media screen and (max-width: 1500px) {
  .works-page footer {
    padding: 142px 0 136px;
  }
}

@media screen and (max-width: 1400px) {
  .works-page footer .container .left-side {
    margin-left: 100px;
  }
  .works-page footer .container .social-block {
    margin-right: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .works-page footer .container .left-side,
  .works-page footer .container .social-block {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .works-page footer {
    display: none;
  }
}

.about-page {
  background: #fff;
  padding-top: 133px;
}

.about-page header {
  position: absolute;
  left: 0;
  top: 48px;
  width: calc(100% - 90px);
  z-index: 2;
  padding: 0 45px;
}

.about-page header .logo p {
  font-family: Gosha-b, Verdana;
  color: #161516;
  font-size: 18px;
}

.about-page header .logo span {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #161516;
  margin-left: 9px;
  margin-bottom: -3px;
}

.about-page header .burger-menu {
  width: 46px;
  height: 46px;
  border: 2px solid #161516;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  z-index: 2;
}

.about-page header .burger-menu .rect {
  width: 16px;
  height: 16px;
  position: relative;
  transform: rotate(45deg);
}

.about-page header .burger-menu .dot {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background: #161516;
  display: block;
  transition: 1s cubic-bezier(0, 0.36, 1, 0.63);
}

.about-page header .burger-menu .dot:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.about-page header .burger-menu .dot:nth-child(2) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.about-page header .burger-menu .dot:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.about-page header .burger-menu .dot:nth-child(4) {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.about-page header .burger-menu.animate-cross {
  border: 2px solid #fff;
}

.about-page header .burger-menu.animate-cross .dot {
  background: #fff;
}

.about-page header .burger-menu.animate-cross .dot:nth-child(1) {
  height: 100%;
  width: 3px;
}

.about-page header .burger-menu.animate-cross .dot:nth-child(2) {
  width: 100%;
  height: 3px;
}

.about-page header .burger-menu.animate-cross .dot:nth-child(3) {
  height: 100%;
  width: 3px;
}

.about-page header .burger-menu.animate-cross .dot:nth-child(4) {
  width: 100%;
  height: 3px;
}

.about-page header .navigation {
  position: absolute;
  background: #161516;
  top: -50px;
  left: 200vw;
  width: 100vw;
  height: calc(100vh + 50px);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  transform: rotate(-180deg);
  transition: 1s ease-in-out;
}

.about-page header .navigation.show {
  left: 0;
}

.about-page header .navigation .logo {
  display: none;
}

.about-page header .navigation .close-cross {
  width: 46px;
  height: 46px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 50px;
  left: 50px;
  z-index: 2;
  box-sizing: border-box;
}

.about-page header .navigation .close-cross:after, .about-page header .navigation .close-cross:before {
  content: '';
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 1px;
}

.about-page header .navigation .close-cross:after {
  width: 16px;
  height: 4px;
}

.about-page header .navigation .close-cross:before {
  width: 4px;
  height: 16px;
}

.about-page header .navigation .link-wrapper {
  writing-mode: tb;
  margin: 46px 80px 0;
}

.about-page header .navigation .link-wrapper a {
  font-family: Gosha-b, Verdana;
  color: #fff;
  font-size: 180px;
  text-transform: capitalize;
  transition: 0.4s;
  position: relative;
}

.about-page header .navigation .link-wrapper a:after {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(100% + 140px);
  background: #fff;
  top: 0;
  right: 0;
}

.about-page header .navigation .link-wrapper a:hover {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px #fff;
}

.about-page header .navigation .link-wrapper:last-child {
  margin: 46px 80px 0 160px;
}

.about-page header .navigation .link-wrapper:last-child a:before {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(100% - 100px);
  background: #fff;
  top: 0;
  left: -160px;
}

@media screen and (max-width: 2000px) {
  .about-page header .navigation .link-wrapper a {
    font-size: 120px;
  }
}

@media screen and (max-width: 1800px) {
  .about-page header .navigation .link-wrapper {
    margin: 40px 50px 0;
  }
  .about-page header .navigation .link-wrapper a {
    font-size: 100px;
  }
  .about-page header .navigation .link-wrapper a:after {
    left: -50px;
    height: calc(100% + 80px);
  }
  .about-page header .navigation .link-wrapper a:last-child {
    margin: 40px 50px 0;
  }
  .about-page header .navigation .link-wrapper a:last-child a:before {
    left: -50px;
    height: calc(100% - 60px);
  }
  .about-page header .navigation .link-wrapper:last-child {
    margin: 40px 50px 160px;
  }
  .about-page header .navigation .link-wrapper:last-child a:before {
    left: -80px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 1400px) {
  .about-page header .navigation .link-wrapper a {
    font-size: 80px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 991px) {
  .about-page header .navigation .link-wrapper {
    margin: 40px 40px 0;
  }
  .about-page header .navigation .link-wrapper:last-child {
    margin: 40px 40px 0;
  }
  .about-page header .navigation .link-wrapper:last-child a:before {
    left: -40px;
  }
  .about-page header .navigation .link-wrapper a {
    font-size: 60px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 767px) {
  .about-page header .navigation {
    transform: rotate(0);
    flex-direction: column;
  }
  .about-page header .navigation .close-cross {
    bottom: unset;
    left: unset;
    top: 50px;
    right: 50px;
  }
  .about-page header .navigation .link-wrapper {
    writing-mode: initial;
    margin: 30px 0 30px 50px;
  }
  .about-page header .navigation .link-wrapper:last-child a:before {
    width: 100%;
    height: 1.5px;
    right: unset;
    left: 0;
    top: unset;
    bottom: -40px;
  }
  .about-page header .navigation .link-wrapper a:after {
    left: 0;
    right: unset;
    width: 120%;
    height: 1px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 575px) {
  .about-page header {
    top: 30px;
    width: calc(100% - 50px);
    padding: 0 25px;
  }
  .about-page header .navigation {
    overflow-y: auto;
    top: -30px;
  }
  .about-page header .navigation .logo {
    display: flex;
    margin: 0 0 70px 24px;
  }
  .about-page header .navigation .close-cross {
    top: 28px;
    right: 24px;
  }
  .about-page header .navigation .link-wrapper {
    margin: 20px 0 20px 24px;
    height: 70px;
  }
  .about-page header .navigation .link-wrapper a {
    height: 70px;
  }
  .about-page header .navigation .link-wrapper:last-child {
    margin: 20px 0 20px 24px;
  }
  .about-page header .navigation .link-wrapper:last-child a:before {
    bottom: -30px;
  }
  .about-page header .navigation .link-wrapper a {
    font-size: 48px;
  }
  .about-page header .navigation .link-wrapper:hover a {
    font-size: 58px;
  }
}

.about-page .title-page {
  font-size: 105px;
  color: #161516;
  font-family: Gosha-b, Verdana;
  padding-left: 222px;
  letter-spacing: 2px;
}

@media screen and (max-width: 1600px) {
  .about-page .title-page {
    padding-left: 200px;
    font-size: 80px;
  }
}

@media screen and (max-width: 1500px) {
  .about-page .title-page {
    padding-left: 150px;
  }
}

@media screen and (max-width: 1400px) {
  .about-page .title-page {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .about-page .title-page {
    padding-left: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .about-page .title-page {
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .about-page .title-page {
    max-width: 100%;
    font-size: 60px;
  }
}

.about-page .contact-form {
  width: 100vw;
}

.about-page .contact-form .container {
  padding: 272px 0;
  background: #161516;
}

.about-page .contact-form .container .inner-wrapper {
  flex-wrap: wrap;
  padding: 0 230px;
  width: 100%;
}

.about-page .contact-form .title {
  color: #fff;
  font-size: 36px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 0;
  line-height: 49px;
}

.about-page .contact-form .feedback-wrapper {
  width: 100%;
  display: flex;
  color: #161516;
  margin-top: 61px;
  justify-content: space-between;
}

.about-page .contact-form .feedback-wrapper .form {
  display: flex;
  flex-wrap: wrap;
  width: 904px;
  margin: 7px -20px 0;
}

.about-page .contact-form .feedback-wrapper .form .input-holder {
  position: relative;
  height: 40px;
  display: flex;
  margin: 0 20px;
  width: calc(33.33% - 40px);
}

.about-page .contact-form .feedback-wrapper .form .input-holder label {
  position: absolute;
  left: 0;
  bottom: 12px;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
  transition: 0.3s;
  color: #fff;
}

.about-page .contact-form .feedback-wrapper .form .input-holder input {
  color: #fff;
  background: #161516;
  border: none;
  border-bottom: 2px solid #fff;
  margin: auto 0 0 0;
  width: 100%;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
}

.about-page .contact-form .feedback-wrapper .form .input-holder input.itsFull ~ label {
  font-size: 12px;
  bottom: 25px;
}

.about-page .contact-form .feedback-wrapper .form .input-holder input:focus ~ label {
  font-size: 12px;
  bottom: 25px;
}

.about-page .contact-form .feedback-wrapper .form .input-holder.textarea {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 77px;
}

.about-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea {
  outline: none;
  color: #fff;
  background: #161516;
  width: 100%;
  border: none;
  border-bottom: 2px solid #fff;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
}

.about-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea.itsFull ~ label {
  font-size: 12px;
  bottom: 45px;
}

.about-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea:focus ~ label {
  font-size: 12px;
  bottom: 45px;
}

.about-page .contact-form .feedback-wrapper .form .input-holder.button {
  width: 150px;
  border: none;
  padding: 0;
}

.about-page .contact-form .feedback-wrapper .form .input-holder.button input {
  display: none;
}

.about-page .contact-form .feedback-wrapper .form .input-holder.button label {
  position: unset;
  color: #fff;
  text-transform: uppercase;
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: none;
}

.about-page .contact-form .feedback-wrapper .form .input-holder.button:hover {
  background: none;
  color: #161516;
}

.about-page .contact-form .feedback-wrapper .form .button {
  color: #fff;
  border: 2px solid #fff;
  text-transform: uppercase;
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  margin: 0 20px;
  letter-spacing: 3.9px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 68px;
  background: inherit;
  transition: 0.5s;
  cursor: none;
}

.about-page .contact-form .feedback-wrapper .form .button:hover {
  background: #161516;
  color: #fff;
}

.about-page .contact-form .feedback-wrapper .form .button.upload {
  border: none;
  padding: 0;
}

.about-page .contact-form .feedback-wrapper .contacts {
  width: 324px;
}

.about-page .contact-form .feedback-wrapper .contacts .text {
  color: #fff;
  font-size: 16px;
  font-family: Gosha-r, Verdana;
  letter-spacing: -0.32px;
  margin-bottom: 34px;
  line-height: 23px;
}

.about-page .contact-form .feedback-wrapper .contacts a,
.about-page .contact-form .feedback-wrapper .contacts .locate {
  color: #fff;
  font-size: 16px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 0;
  margin-bottom: 10px;
  display: block;
}

.about-page .contact-form .feedback-wrapper .contacts .social {
  margin: 34px 0 18px;
}

.about-page .contact-form .feedback-wrapper .contacts .social-links {
  display: flex;
  margin: 0 -11px;
}

.about-page .contact-form .feedback-wrapper .contacts .social-links .single-link {
  margin: 0 11px;
}

@media screen and (max-width: 1800px) {
  .about-page .contact-form .feedback-wrapper .form {
    width: 850px;
  }
  .about-page .contact-form .feedback-wrapper .contacts {
    width: 240px;
  }
}

@media screen and (max-width: 1700px) {
  .about-page .contact-form .container .inner-wrapper {
    padding: 0 180px;
  }
  .about-page .contact-form .feedback-wrapper .form {
    width: 800px;
  }
}

@media screen and (max-width: 1700px) {
  .about-page .contact-form .container .inner-wrapper {
    padding: 0 130px;
  }
}

@media screen and (max-width: 1400px) {
  .about-page .contact-form {
    height: auto;
  }
  .about-page .contact-form .container {
    padding: 202px 0 217px;
  }
  .about-page .contact-form .container .inner-wrapper {
    padding: 0 100px;
  }
  .about-page .contact-form .container .inner-wrapper .title {
    font-size: 30px;
  }
  .about-page .contact-form .feedback-wrapper .form {
    width: 750px;
  }
  .about-page .contact-form .feedback-wrapper .contacts {
    margin-top: 14px;
    width: 268px;
  }
}

@media screen and (max-width: 1300px) {
  .about-page .contact-form .container {
    position: relative;
    padding: 202px 20px 217px;
    max-width: calc(100% - 130px);
  }
  .about-page .contact-form .container:after {
    content: '';
    display: block;
    width: calc(100vw - 90px);
    height: calc(100% + 1px);
    background: #fff;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .about-page .contact-form .container .inner-wrapper {
    padding: 0;
  }
  .about-page .contact-form .container .inner-wrapper .title br {
    display: none;
  }
  .about-page .contact-form .feedback-wrapper .form {
    width: calc(100% - 268px);
  }
  .about-page .contact-form .feedback-wrapper .contacts {
    margin-top: 40px;
  }
}

@media screen and (max-width: 991px) {
  .about-page .contact-form .feedback-wrapper {
    flex-direction: column;
  }
  .about-page .contact-form .feedback-wrapper .form {
    width: calc(100% + 40px);
  }
}

@media screen and (max-width: 767px) {
  .about-page .contact-form .feedback-wrapper .form {
    width: 100%;
    margin: 0;
  }
  .about-page .contact-form .feedback-wrapper .form .input-holder {
    width: 100%;
    margin: 0 0 40px;
  }
  .about-page .contact-form .feedback-wrapper .form .button {
    margin: 0 40px 0 0;
  }
}

@media screen and (max-width: 575px) {
  .about-page .contact-form {
    background: #161516;
  }
  .about-page .contact-form .container {
    max-width: calc(100% - 48px);
    padding: 115px 0 90px;
  }
  .about-page .contact-form .container:after {
    width: 100vw;
    top: 0;
  }
  .about-page .contact-form .title {
    font-size: 30px;
  }
  .about-page .contact-form .feedback-wrapper {
    margin-top: 60px;
  }
  .about-page .contact-form .feedback-wrapper .contacts {
    width: 100%;
  }
  .about-page .contact-form .feedback-wrapper .form {
    flex-direction: column;
    align-items: center;
  }
  .about-page .contact-form .feedback-wrapper .form .button {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 0 15px 0;
  }
}

.about-page footer {
  padding: 167px 0 170px;
  background: #fff;
}

.about-page footer .container {
  justify-content: space-between;
  padding: 0 470px;
  width: calc(100% - 940px);
}

.about-page footer .container .left-side {
  display: flex;
}

.about-page footer .container .left-side .logo-copyright {
  padding-right: 94px;
  position: relative;
}

.about-page footer .container .left-side .logo-copyright:after {
  position: absolute;
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background-color: #161516;
  right: 0;
  bottom: 0;
}

.about-page footer .container .left-side .logo-copyright p {
  margin-top: 50px;
  font-size: 16px;
  font-family: Gosha-r, Verdana;
}

.about-page footer .container .left-side .logo-copyright .logo {
  font-weight: 600;
}

.about-page footer .container .left-side .logo-copyright .logo p {
  color: #161516;
  margin: 0;
  font-size: 32px;
  letter-spacing: 0.7px;
}

.about-page footer .container .left-side .logo-copyright .logo span {
  width: 28px;
  height: 28px;
  border: 2px solid #161516;
  margin-bottom: -7px;
}

.about-page footer .container .left-side .navigation {
  margin-left: 90px;
  margin-top: 5px;
}

.about-page footer .container .left-side .navigation li {
  margin-bottom: 15px;
}

.about-page footer .container .left-side .navigation li:last-child {
  margin: 0;
}

.about-page footer .container .left-side .navigation li a {
  font-size: 18px;
  font-family: Gosha-r, Verdana;
  color: #161516;
}

.about-page footer .container .social-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
}

.about-page footer .container .social-block a,
.about-page footer .container .social-block .locate {
  font-family: Gosha-r, Verdana;
  color: #161516;
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
}

.about-page footer .container .social-block .social-links {
  display: flex;
  margin: 30px -15px 0;
}

.about-page footer .container .social-block .social-links .single-link {
  margin: 0 15px;
}

@media screen and (max-width: 2000px) {
  .about-page footer .container {
    padding: 0;
    width: calc(100% - 90px);
  }
  .about-page footer .container .left-side {
    margin-left: 230px;
  }
  .about-page footer .container .social-block {
    margin-right: 230px;
  }
}

@media screen and (max-width: 1700px) {
  .about-page footer .container .left-side {
    margin-left: 130px;
  }
  .about-page footer .container .social-block {
    margin-right: 130px;
  }
}

@media screen and (max-width: 1500px) {
  .about-page footer {
    padding: 142px 0 136px;
  }
}

@media screen and (max-width: 1400px) {
  .about-page footer .container .left-side {
    margin-left: 100px;
  }
  .about-page footer .container .social-block {
    margin-right: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .about-page footer .container .left-side,
  .about-page footer .container .social-block {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .about-page footer {
    display: none;
  }
}

.about-page .main-photo {
  position: relative;
  margin-top: 50px;
  margin-bottom: 270px;
}

.about-page .main-photo .decoration-title {
  position: absolute;
  color: rgba(0, 0, 0, 0);
  font-size: 380px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 2px;
  right: -50px;
  bottom: -100px;
  -webkit-text-stroke: 2px #FFED00;
}

.about-page .main-photo .img-holder {
  width: 1645px;
  height: 796px;
  margin-left: 267px;
  overflow: hidden;
}

.about-page .main-photo .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1700px) {
  .about-page .main-photo .img-holder {
    margin-left: 245px;
  }
}

@media screen and (max-width: 1500px) {
  .about-page .main-photo .img-holder {
    margin-left: 200px;
  }
}

@media screen and (max-width: 1400px) {
  .about-page .main-photo .img-holder {
    margin-left: 150px;
  }
}

@media screen and (max-width: 1300px) {
  .about-page .main-photo {
    margin-bottom: 230px;
  }
  .about-page .main-photo .img-holder {
    margin-left: 130px;
    width: calc(100% - 130px);
    height: calc((100% - 130px)/2);
  }
  .about-page .main-photo .decoration-title {
    font-size: 300px;
  }
}

@media screen and (max-width: 1200px) {
  .about-page .main-photo .img-holder {
    margin-left: 45px;
    width: calc(100% - 45px);
    height: calc((100% - 45px)/2);
  }
  .about-page .main-photo .decoration-title {
    font-size: 250px;
  }
}

@media screen and (max-width: 991px) {
  .about-page .main-photo .decoration-title {
    font-size: 200px;
    bottom: -60px;
    -webkit-text-stroke: 1px #FFED00;
  }
}

@media screen and (max-width: 767px) {
  .about-page .main-photo .decoration-title {
    font-size: 150px;
    bottom: -45px;
  }
}

@media screen and (max-width: 575px) {
  .about-page .main-photo {
    margin-bottom: 100px;
  }
  .about-page .main-photo .img-holder {
    margin-left: 25px;
    width: calc(100% - 25px);
    height: calc((100% - 25px)/2);
  }
  .about-page .main-photo .decoration-title {
    font-size: 110px;
    bottom: -30px;
    right: -25px;
  }
}

@media screen and (max-width: 425px) {
  .about-page .main-photo .decoration-title {
    font-size: 67px;
    bottom: -20px;
    right: -15px;
  }
}

.about-page .short-desc {
  margin-bottom: 282px;
}

.about-page .short-desc .container {
  padding-left: 222px;
}

.about-page .short-desc .title {
  font-size: 72px;
  color: #161516;
  font-family: Gosha-b, Verdana;
  letter-spacing: 2px;
  margin-bottom: 80px;
}

.about-page .short-desc .list {
  display: flex;
  justify-content: space-between;
}

.about-page .short-desc .list .list-item {
  color: #161516;
  width: 410px;
  margin-right: 100px;
}

.about-page .short-desc .list .list-item .number {
  font-size: 18px;
  margin: 0 0 33px;
  line-height: 1;
}

.about-page .short-desc .list .list-item .item-title {
  font-size: 40px;
  margin: 0 0 27px;
  line-height: 1;
}

.about-page .short-desc .list .list-item .text {
  font-size: 16px;
  margin: 0;
  line-height: 24px;
}

@media screen and (max-width: 1700px) {
  .about-page .short-desc .list .list-item {
    width: 360px;
  }
}

@media screen and (max-width: 1600px) {
  .about-page .short-desc .container {
    padding-left: 200px;
  }
  .about-page .short-desc .list {
    justify-content: flex-start;
  }
  .about-page .short-desc .list .list-item {
    margin-right: 80px;
    width: calc((100% - 440px) / 3);
  }
}

@media screen and (max-width: 1500px) {
  .about-page .short-desc .container {
    padding-left: 150px;
  }
  .about-page .short-desc .list .list-item {
    margin-right: 58px;
    width: calc((100% - 240px) / 3);
  }
}

@media screen and (max-width: 1400px) {
  .about-page .short-desc .container {
    padding-left: 100px;
  }
  .about-page .short-desc .list .list-item {
    margin-right: 40px;
    width: calc((100% - 220px) / 3);
  }
}

@media screen and (max-width: 1300px) {
  .about-page .short-desc .container {
    padding-left: 80px;
  }
  .about-page .short-desc .list .list-item {
    margin-right: 40px;
    width: calc((100% - 200px) / 3);
  }
  .about-page .short-desc .list .list-item .item-title {
    font-size: 35px;
  }
}

@media screen and (max-width: 1200px) {
  .about-page .short-desc .container {
    padding-left: 0;
  }
  .about-page .short-desc .list {
    justify-content: space-between;
  }
  .about-page .short-desc .list .list-item {
    margin-right: 0;
    width: calc((100% - 90px) / 3);
  }
}

@media screen and (max-width: 991px) {
  .about-page .short-desc .title {
    margin-bottom: 50px;
    font-size: 60px;
  }
  .about-page .short-desc .list {
    flex-direction: column;
  }
  .about-page .short-desc .list .list-item {
    margin-bottom: 30px;
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .about-page .short-desc .title {
    font-size: 45px;
  }
  .about-page .short-desc .list .list-item {
    margin-bottom: 30px;
    width: 80%;
  }
}

@media screen and (max-width: 575px) {
  .about-page .short-desc {
    margin-bottom: 100px;
  }
  .about-page .short-desc .title {
    margin-bottom: 50px;
    font-size: 30px;
  }
  .about-page .short-desc .list .list-item {
    width: 100%;
    margin-bottom: 40px;
  }
  .about-page .short-desc .list .list-item .number {
    margin-bottom: 25px;
    font-size: 10px;
  }
  .about-page .short-desc .list .list-item .item-title {
    margin-bottom: 25px;
    font-size: 20px;
  }
  .about-page .short-desc .list .list-item .text {
    font-size: 16px;
  }
}

.about-page .our-team .container {
  padding-left: 222px;
}

.about-page .our-team .title {
  font-size: 72px;
  color: #161516;
  font-family: Gosha-b, Verdana;
  letter-spacing: 2px;
  margin-bottom: 80px;
}

.about-page .our-team .team-list-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 882px;
  width: 100%;
}

.about-page .our-team .team-list-wrapper .click-area {
  position: absolute;
  width: 10%;
  height: 100%;
  top: 0;
  box-sizing: border-box;
  z-index: 10;
}

.about-page .our-team .team-list-wrapper .click-area.button-left {
  left: 0;
}

.about-page .our-team .team-list-wrapper .click-area.button-right {
  right: 0;
}

.about-page .our-team .team-list-wrapper .team-list {
  transition: .3s;
  user-select: none;
  overflow-x: scroll;
  top: 0;
  left: 0;
  display: flex;
  max-height: 772px;
  padding-bottom: 100px;
  padding-left: 272px;
  padding-right: 50px;
}

.about-page .our-team .team-list-wrapper .team-list::-webkit-scrollbar {
  display: none;
}

.about-page .our-team .team-list-wrapper .team-list .member {
  pointer-events: none;
  position: relative;
  margin: 0 25px;
  width: 434px;
  height: 576px;
  transition: 0.4s;
}

.about-page .our-team .team-list-wrapper .team-list .member:first-child {
  width: 514px;
  height: 682px;
}

.about-page .our-team .team-list-wrapper .team-list .member:first-child .img-holder {
  width: 514px;
  height: 682px;
}

.about-page .our-team .team-list-wrapper .team-list .member:nth-child(3) {
  margin-top: 100px;
}

.about-page .our-team .team-list-wrapper .team-list .member .img-holder {
  width: 434px;
  height: 576px;
}

.about-page .our-team .team-list-wrapper .team-list .member .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-page .our-team .team-list-wrapper .team-list .member .text-wrapper {
  position: absolute;
  bottom: -32px;
  transform: translateY(100%);
  left: 0;
}

.about-page .our-team .team-list-wrapper .team-list .member .text-wrapper h6, .about-page .our-team .team-list-wrapper .team-list .member .text-wrapper p {
  color: #161516;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.about-page .our-team .team-list-wrapper .team-list .member .text-wrapper h6 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 14px;
}

.about-page .our-team .team-list-wrapper .team-list .member .text-wrapper p {
  font-size: 16px;
}

@media screen and (max-width: 1700px) {
  .about-page .our-team .container {
    padding-left: 200px;
  }
  .about-page .our-team .team-list-wrapper .team-list {
    padding-left: 220px;
  }
}

@media screen and (max-width: 1500px) {
  .about-page .our-team .container {
    padding-left: 150px;
  }
  .about-page .our-team .team-list-wrapper .team-list {
    padding-left: 170px;
  }
}

@media screen and (max-width: 1400px) {
  .about-page .our-team .container {
    padding-left: 100px;
  }
  .about-page .our-team .team-list-wrapper .team-list {
    padding-left: 120px;
  }
}

@media screen and (max-width: 1300px) {
  .about-page .our-team .container {
    padding-left: 80px;
  }
  .about-page .our-team .team-list-wrapper .team-list {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .about-page .our-team .container {
    padding-left: 0;
  }
  .about-page .our-team .team-list-wrapper .team-list {
    padding-left: 25px;
  }
}

@media screen and (max-width: 991px) {
  .about-page .our-team .title {
    font-size: 60px;
    margin-bottom: 60px;
  }
  .about-page .our-team .team-list-wrapper {
    min-height: 740px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member {
    width: 434px;
    height: 576px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member:first-child {
    width: 434px;
    height: 576px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member:first-child .img-holder {
    width: 434px;
    height: 576px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member:nth-child(3) {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .about-page .our-team .title {
    font-size: 60px;
    margin-bottom: 60px;
  }
  .about-page .our-team .team-list-wrapper {
    min-height: 640px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member {
    width: 350px;
    height: 450px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member .img-holder {
    width: 350px;
    height: 450px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member:first-child {
    width: 350px;
    height: 450px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member:first-child .img-holder {
    width: 350px;
    height: 450px;
  }
}

@media screen and (max-width: 575px) {
  .about-page .our-team .title {
    font-size: 30px;
    margin-bottom: 60px;
  }
  .about-page .our-team .team-list-wrapper {
    min-height: 500px;
  }
  .about-page .our-team .team-list-wrapper .team-list {
    margin: 0 -15px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member {
    width: 250px;
    height: 363px;
    margin: 0 15px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member .img-holder {
    width: 250px;
    height: 363px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member:first-child {
    width: 250px;
    height: 363px;
  }
  .about-page .our-team .team-list-wrapper .team-list .member:first-child .img-holder {
    width: 250px;
    height: 363px;
  }
}

.about-page .numbers {
  margin: 80px 0 200px;
  display: flex;
  align-items: center;
}

.about-page .numbers .img-holder {
  width: 850px;
  height: 850px;
  position: relative;
}

.about-page .numbers .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-page .numbers .img-holder .decoration-title {
  position: absolute;
  color: rgba(0, 0, 0, 0);
  font-size: 280px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 2px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  -webkit-text-stroke: 2px #FFED00;
}

.about-page .numbers .content-wrapper {
  margin-left: 80px;
  width: 780px;
}

.about-page .numbers .content-wrapper .title {
  font-size: 72px;
  color: #161516;
  font-family: Gosha-b, Verdana;
  letter-spacing: 2px;
  margin-bottom: 100px;
}

.about-page .numbers .content-wrapper .num-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  height: 300px;
  justify-content: space-between;
  align-content: space-between;
}

.about-page .numbers .content-wrapper .num-list .num-item {
  width: 200px;
}

.about-page .numbers .content-wrapper .num-list .num-item h6, .about-page .numbers .content-wrapper .num-list .num-item p {
  color: #161516;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.about-page .numbers .content-wrapper .num-list .num-item h6 {
  font-size: 48px;
  margin-bottom: 35px;
}

.about-page .numbers .content-wrapper .num-list .num-item h6 span {
  font-size: 30px;
}

.about-page .numbers .content-wrapper .num-list .num-item p {
  font-size: 16px;
}

@media screen and (max-width: 1700px) {
  .about-page .numbers {
    padding-right: 30px;
  }
  .about-page .numbers .content-wrapper {
    margin-left: 30px;
    width: unset;
  }
}

@media screen and (max-width: 1400px) {
  .about-page .numbers .img-holder .decoration-title {
    font-size: 200px;
    bottom: 130px;
  }
}

@media screen and (max-width: 1300px) {
  .about-page .numbers {
    display: flex;
    flex-wrap: wrap;
  }
  .about-page .numbers .content-wrapper {
    margin-top: 50px;
  }
  .about-page .numbers .img-holder .decoration-title {
    font-size: 180px;
    transform: unset;
    left: 30px;
    bottom: 60px;
  }
}

@media screen and (max-width: 991px) {
  .about-page .numbers .img-holder {
    width: 100%;
    height: 100vw;
  }
  .about-page .numbers .img-holder .decoration-title {
    bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  .about-page .numbers .img-holder .decoration-title {
    bottom: 100px;
    font-size: 200px;
  }
  .about-page .numbers .content-wrapper .num-list {
    height: 420px;
  }
}

@media screen and (max-width: 575px) {
  .about-page .numbers {
    margin-bottom: 70px;
  }
  .about-page .numbers .img-holder .decoration-title {
    bottom: 50px;
    font-size: 100px;
  }
  .about-page .numbers .content-wrapper .title {
    font-size: 30px;
    margin-bottom: 60px;
  }
  .about-page .numbers .content-wrapper .num-list {
    height: unset;
  }
  .about-page .numbers .content-wrapper .num-list .num-item {
    margin-bottom: 55px;
  }
  .about-page .numbers .content-wrapper .num-list .num-item h6 {
    font-size: 48px;
    margin-bottom: 35px;
  }
  .about-page .numbers .content-wrapper .num-list .num-item h6 span {
    font-size: 30px;
  }
  .about-page .numbers .content-wrapper .num-list .num-item p {
    font-size: 16px;
  }
}

.about-page .projects {
  margin-bottom: 285px;
  position: relative;
}

.about-page .projects .click-area {
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  z-index: 10;
}

.about-page .projects .click-area.button-left {
  left: 0;
}

.about-page .projects .click-area.button-right {
  right: 0;
}

.about-page .projects .project-list {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
}

.about-page .projects .project-list::-webkit-scrollbar {
  display: none;
}

.about-page .projects .project-list .project-item {
  overflow: hidden;
  min-width: calc(100vw - 90px);
  padding: 0 45px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.about-page .projects .project-list .project-item .left-side {
  padding-left: 230px;
  margin-right: 56px;
}

.about-page .projects .project-list .project-item .left-side .subtitle {
  display: flex;
  margin-bottom: 60px;
}

.about-page .projects .project-list .project-item .left-side .subtitle p {
  color: #161516;
  font-size: 18px;
}

.about-page .projects .project-list .project-item .left-side .subtitle p:first-child {
  margin-right: 100px;
}

.about-page .projects .project-list .project-item .left-side .title, .about-page .projects .project-list .project-item .left-side .text, .about-page .projects .project-list .project-item .left-side .button {
  color: #161516;
}

.about-page .projects .project-list .project-item .left-side .title {
  margin-bottom: 65px;
  font-size: 60px;
}

.about-page .projects .project-list .project-item .left-side .text {
  margin-bottom: 70px;
  font-size: 16px;
  line-height: 1.6;
}

.about-page .projects .project-list .project-item .left-side .button {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 25px 34px;
  border: 2px solid #161516;
}

.about-page .projects .project-list .project-item .right-side .img-holder {
  width: 820px;
  height: 820px;
  position: relative;
}

.about-page .projects .project-list .project-item .right-side .img-holder img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}

.about-page .projects .project-list .project-item .right-side .img-holder img.decoration {
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 106%;
  height: 106%;
}

@media screen and (max-width: 1500px) {
  .about-page .projects .project-list .project-item .left-side {
    padding-left: 160px;
  }
}

@media screen and (max-width: 1400px) {
  .about-page .projects .project-list .project-item .left-side {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .about-page .projects .project-list .project-item .left-side {
    box-sizing: border-box;
    width: 50%;
    margin-right: 0;
    padding-right: 50px;
  }
  .about-page .projects .project-list .project-item .right-side {
    width: 50%;
  }
  .about-page .projects .project-list .project-item .right-side .img-holder {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .about-page .projects .project-list .project-item .left-side {
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .about-page .projects .project-list .project-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .about-page .projects .project-list .project-item .left-side,
  .about-page .projects .project-list .project-item .right-side {
    width: 100%;
  }
  .about-page .projects .project-list .project-item .left-side {
    margin-top: 55px;
  }
  .about-page .projects .project-list .project-item .left-side .subtitle {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .about-page .projects {
    margin-bottom: 80px;
  }
  .about-page .projects .project-list .project-item {
    padding: 0 24px;
    min-width: calc(100vw - 48px);
  }
  .about-page .projects .project-list .project-item .left-side {
    margin-top: 45px;
  }
  .about-page .projects .project-list .project-item .left-side .title {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .about-page .projects .project-list .project-item .left-side .text {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 18px;
  }
  .about-page .projects .project-list .project-item .left-side .button {
    font-size: 10px;
  }
}

.development-page {
  background: #fff;
  padding-top: 133px;
}

.development-page header {
  position: absolute;
  left: 0;
  top: 48px;
  width: calc(100% - 90px);
  z-index: 2;
  padding: 0 45px;
}

.development-page header .logo p {
  font-family: Gosha-b, Verdana;
  color: #161516;
  font-size: 18px;
}

.development-page header .logo span {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #161516;
  margin-left: 9px;
  margin-bottom: -3px;
}

.development-page header .burger-menu {
  width: 46px;
  height: 46px;
  border: 2px solid #161516;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  z-index: 2;
}

.development-page header .burger-menu .rect {
  width: 16px;
  height: 16px;
  position: relative;
  transform: rotate(45deg);
}

.development-page header .burger-menu .dot {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background: #161516;
  display: block;
  transition: 1s cubic-bezier(0, 0.36, 1, 0.63);
}

.development-page header .burger-menu .dot:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.development-page header .burger-menu .dot:nth-child(2) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.development-page header .burger-menu .dot:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.development-page header .burger-menu .dot:nth-child(4) {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.development-page header .burger-menu.animate-cross {
  border: 2px solid #fff;
}

.development-page header .burger-menu.animate-cross .dot {
  background: #fff;
}

.development-page header .burger-menu.animate-cross .dot:nth-child(1) {
  height: 100%;
  width: 3px;
}

.development-page header .burger-menu.animate-cross .dot:nth-child(2) {
  width: 100%;
  height: 3px;
}

.development-page header .burger-menu.animate-cross .dot:nth-child(3) {
  height: 100%;
  width: 3px;
}

.development-page header .burger-menu.animate-cross .dot:nth-child(4) {
  width: 100%;
  height: 3px;
}

.development-page header .navigation {
  position: absolute;
  background: #161516;
  top: -50px;
  left: 200vw;
  width: 100vw;
  height: calc(100vh + 50px);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  transform: rotate(-180deg);
  transition: 1s ease-in-out;
}

.development-page header .navigation.show {
  left: 0;
}

.development-page header .navigation .logo {
  display: none;
}

.development-page header .navigation .close-cross {
  width: 46px;
  height: 46px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 50px;
  left: 50px;
  z-index: 2;
  box-sizing: border-box;
}

.development-page header .navigation .close-cross:after, .development-page header .navigation .close-cross:before {
  content: '';
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 1px;
}

.development-page header .navigation .close-cross:after {
  width: 16px;
  height: 4px;
}

.development-page header .navigation .close-cross:before {
  width: 4px;
  height: 16px;
}

.development-page header .navigation .link-wrapper {
  writing-mode: tb;
  margin: 46px 80px 0;
}

.development-page header .navigation .link-wrapper a {
  font-family: Gosha-b, Verdana;
  color: #fff;
  font-size: 180px;
  text-transform: capitalize;
  transition: 0.4s;
  position: relative;
}

.development-page header .navigation .link-wrapper a:after {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(100% + 140px);
  background: #fff;
  top: 0;
  right: 0;
}

.development-page header .navigation .link-wrapper a:hover {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px #fff;
}

.development-page header .navigation .link-wrapper:last-child {
  margin: 46px 80px 0 160px;
}

.development-page header .navigation .link-wrapper:last-child a:before {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(100% - 100px);
  background: #fff;
  top: 0;
  left: -160px;
}

@media screen and (max-width: 2000px) {
  .development-page header .navigation .link-wrapper a {
    font-size: 120px;
  }
}

@media screen and (max-width: 1800px) {
  .development-page header .navigation .link-wrapper {
    margin: 40px 50px 0;
  }
  .development-page header .navigation .link-wrapper a {
    font-size: 100px;
  }
  .development-page header .navigation .link-wrapper a:after {
    left: -50px;
    height: calc(100% + 80px);
  }
  .development-page header .navigation .link-wrapper a:last-child {
    margin: 40px 50px 0;
  }
  .development-page header .navigation .link-wrapper a:last-child a:before {
    left: -50px;
    height: calc(100% - 60px);
  }
  .development-page header .navigation .link-wrapper:last-child {
    margin: 40px 50px 160px;
  }
  .development-page header .navigation .link-wrapper:last-child a:before {
    left: -80px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 1400px) {
  .development-page header .navigation .link-wrapper a {
    font-size: 80px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 991px) {
  .development-page header .navigation .link-wrapper {
    margin: 40px 40px 0;
  }
  .development-page header .navigation .link-wrapper:last-child {
    margin: 40px 40px 0;
  }
  .development-page header .navigation .link-wrapper:last-child a:before {
    left: -40px;
  }
  .development-page header .navigation .link-wrapper a {
    font-size: 60px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 767px) {
  .development-page header .navigation {
    transform: rotate(0);
    flex-direction: column;
  }
  .development-page header .navigation .close-cross {
    bottom: unset;
    left: unset;
    top: 50px;
    right: 50px;
  }
  .development-page header .navigation .link-wrapper {
    writing-mode: initial;
    margin: 30px 0 30px 50px;
  }
  .development-page header .navigation .link-wrapper:last-child a:before {
    width: 100%;
    height: 1.5px;
    right: unset;
    left: 0;
    top: unset;
    bottom: -40px;
  }
  .development-page header .navigation .link-wrapper a:after {
    left: 0;
    right: unset;
    width: 120%;
    height: 1px;
  }
}

@media screen and (max-width: 1800px) and (max-width: 575px) {
  .development-page header {
    top: 30px;
    width: calc(100% - 50px);
    padding: 0 25px;
  }
  .development-page header .navigation {
    overflow-y: auto;
    top: -30px;
  }
  .development-page header .navigation .logo {
    display: flex;
    margin: 0 0 70px 24px;
  }
  .development-page header .navigation .close-cross {
    top: 28px;
    right: 24px;
  }
  .development-page header .navigation .link-wrapper {
    margin: 20px 0 20px 24px;
    height: 70px;
  }
  .development-page header .navigation .link-wrapper a {
    height: 70px;
  }
  .development-page header .navigation .link-wrapper:last-child {
    margin: 20px 0 20px 24px;
  }
  .development-page header .navigation .link-wrapper:last-child a:before {
    bottom: -30px;
  }
  .development-page header .navigation .link-wrapper a {
    font-size: 48px;
  }
  .development-page header .navigation .link-wrapper:hover a {
    font-size: 58px;
  }
}

.development-page .contact-form {
  width: 100vw;
}

.development-page .contact-form .container {
  padding: 272px 0;
  background: #161516;
}

.development-page .contact-form .container .inner-wrapper {
  flex-wrap: wrap;
  padding: 0 230px;
  width: 100%;
}

.development-page .contact-form .title {
  color: #fff;
  font-size: 36px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 0;
  line-height: 49px;
}

.development-page .contact-form .feedback-wrapper {
  width: 100%;
  display: flex;
  color: #161516;
  margin-top: 61px;
  justify-content: space-between;
}

.development-page .contact-form .feedback-wrapper .form {
  display: flex;
  flex-wrap: wrap;
  width: 904px;
  margin: 7px -20px 0;
}

.development-page .contact-form .feedback-wrapper .form .input-holder {
  position: relative;
  height: 40px;
  display: flex;
  margin: 0 20px;
  width: calc(33.33% - 40px);
}

.development-page .contact-form .feedback-wrapper .form .input-holder label {
  position: absolute;
  left: 0;
  bottom: 12px;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
  transition: 0.3s;
  color: #fff;
}

.development-page .contact-form .feedback-wrapper .form .input-holder input {
  color: #fff;
  background: #161516;
  border: none;
  border-bottom: 2px solid #fff;
  margin: auto 0 0 0;
  width: 100%;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
}

.development-page .contact-form .feedback-wrapper .form .input-holder input.itsFull ~ label {
  font-size: 12px;
  bottom: 25px;
}

.development-page .contact-form .feedback-wrapper .form .input-holder input:focus ~ label {
  font-size: 12px;
  bottom: 25px;
}

.development-page .contact-form .feedback-wrapper .form .input-holder.textarea {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 77px;
}

.development-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea {
  outline: none;
  color: #fff;
  background: #161516;
  width: 100%;
  border: none;
  border-bottom: 2px solid #fff;
  font-family: Gosha-r, Verdana;
  font-size: 18px;
}

.development-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea.itsFull ~ label {
  font-size: 12px;
  bottom: 45px;
}

.development-page .contact-form .feedback-wrapper .form .input-holder.textarea textarea:focus ~ label {
  font-size: 12px;
  bottom: 45px;
}

.development-page .contact-form .feedback-wrapper .form .input-holder.button {
  width: 150px;
  border: none;
  padding: 0;
}

.development-page .contact-form .feedback-wrapper .form .input-holder.button input {
  display: none;
}

.development-page .contact-form .feedback-wrapper .form .input-holder.button label {
  position: unset;
  color: #fff;
  text-transform: uppercase;
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: none;
}

.development-page .contact-form .feedback-wrapper .form .input-holder.button:hover {
  background: none;
  color: #161516;
}

.development-page .contact-form .feedback-wrapper .form .button {
  color: #fff;
  border: 2px solid #fff;
  text-transform: uppercase;
  font-family: Gosha-b, Verdana;
  font-size: 16px;
  margin: 0 20px;
  letter-spacing: 3.9px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 68px;
  background: inherit;
  transition: 0.5s;
  cursor: none;
}

.development-page .contact-form .feedback-wrapper .form .button:hover {
  background: #161516;
  color: #fff;
}

.development-page .contact-form .feedback-wrapper .form .button.upload {
  border: none;
  padding: 0;
}

.development-page .contact-form .feedback-wrapper .contacts {
  width: 324px;
}

.development-page .contact-form .feedback-wrapper .contacts .text {
  color: #fff;
  font-size: 16px;
  font-family: Gosha-r, Verdana;
  letter-spacing: -0.32px;
  margin-bottom: 34px;
  line-height: 23px;
}

.development-page .contact-form .feedback-wrapper .contacts a,
.development-page .contact-form .feedback-wrapper .contacts .locate {
  color: #fff;
  font-size: 16px;
  font-family: Gosha-b, Verdana;
  letter-spacing: 0;
  margin-bottom: 10px;
  display: block;
}

.development-page .contact-form .feedback-wrapper .contacts .social {
  margin: 34px 0 18px;
}

.development-page .contact-form .feedback-wrapper .contacts .social-links {
  display: flex;
  margin: 0 -11px;
}

.development-page .contact-form .feedback-wrapper .contacts .social-links .single-link {
  margin: 0 11px;
}

@media screen and (max-width: 1800px) {
  .development-page .contact-form .feedback-wrapper .form {
    width: 850px;
  }
  .development-page .contact-form .feedback-wrapper .contacts {
    width: 240px;
  }
}

@media screen and (max-width: 1700px) {
  .development-page .contact-form .container .inner-wrapper {
    padding: 0 180px;
  }
  .development-page .contact-form .feedback-wrapper .form {
    width: 800px;
  }
}

@media screen and (max-width: 1700px) {
  .development-page .contact-form .container .inner-wrapper {
    padding: 0 130px;
  }
}

@media screen and (max-width: 1400px) {
  .development-page .contact-form {
    height: auto;
  }
  .development-page .contact-form .container {
    padding: 202px 0 217px;
  }
  .development-page .contact-form .container .inner-wrapper {
    padding: 0 100px;
  }
  .development-page .contact-form .container .inner-wrapper .title {
    font-size: 30px;
  }
  .development-page .contact-form .feedback-wrapper .form {
    width: 750px;
  }
  .development-page .contact-form .feedback-wrapper .contacts {
    margin-top: 14px;
    width: 268px;
  }
}

@media screen and (max-width: 1300px) {
  .development-page .contact-form .container {
    position: relative;
    padding: 202px 20px 217px;
    max-width: calc(100% - 130px);
  }
  .development-page .contact-form .container:after {
    content: '';
    display: block;
    width: calc(100vw - 90px);
    height: calc(100% + 1px);
    background: #fff;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .development-page .contact-form .container .inner-wrapper {
    padding: 0;
  }
  .development-page .contact-form .container .inner-wrapper .title br {
    display: none;
  }
  .development-page .contact-form .feedback-wrapper .form {
    width: calc(100% - 268px);
  }
  .development-page .contact-form .feedback-wrapper .contacts {
    margin-top: 40px;
  }
}

@media screen and (max-width: 991px) {
  .development-page .contact-form .feedback-wrapper {
    flex-direction: column;
  }
  .development-page .contact-form .feedback-wrapper .form {
    width: calc(100% + 40px);
  }
}

@media screen and (max-width: 767px) {
  .development-page .contact-form .feedback-wrapper .form {
    width: 100%;
    margin: 0;
  }
  .development-page .contact-form .feedback-wrapper .form .input-holder {
    width: 100%;
    margin: 0 0 40px;
  }
  .development-page .contact-form .feedback-wrapper .form .button {
    margin: 0 40px 0 0;
  }
}

@media screen and (max-width: 575px) {
  .development-page .contact-form {
    background: #161516;
  }
  .development-page .contact-form .container {
    max-width: calc(100% - 48px);
    padding: 115px 0 90px;
  }
  .development-page .contact-form .container:after {
    width: 100vw;
    top: 0;
  }
  .development-page .contact-form .title {
    font-size: 30px;
  }
  .development-page .contact-form .feedback-wrapper {
    margin-top: 60px;
  }
  .development-page .contact-form .feedback-wrapper .contacts {
    width: 100%;
  }
  .development-page .contact-form .feedback-wrapper .form {
    flex-direction: column;
    align-items: center;
  }
  .development-page .contact-form .feedback-wrapper .form .button {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 0 15px 0;
  }
}

.development-page footer {
  padding: 167px 0 170px;
  background: #fff;
}

.development-page footer .container {
  justify-content: space-between;
  padding: 0 470px;
  width: calc(100% - 940px);
}

.development-page footer .container .left-side {
  display: flex;
}

.development-page footer .container .left-side .logo-copyright {
  padding-right: 94px;
  position: relative;
}

.development-page footer .container .left-side .logo-copyright:after {
  position: absolute;
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background-color: #161516;
  right: 0;
  bottom: 0;
}

.development-page footer .container .left-side .logo-copyright p {
  margin-top: 50px;
  font-size: 16px;
  font-family: Gosha-r, Verdana;
}

.development-page footer .container .left-side .logo-copyright .logo {
  font-weight: 600;
}

.development-page footer .container .left-side .logo-copyright .logo p {
  color: #161516;
  margin: 0;
  font-size: 32px;
  letter-spacing: 0.7px;
}

.development-page footer .container .left-side .logo-copyright .logo span {
  width: 28px;
  height: 28px;
  border: 2px solid #161516;
  margin-bottom: -7px;
}

.development-page footer .container .left-side .navigation {
  margin-left: 90px;
  margin-top: 5px;
}

.development-page footer .container .left-side .navigation li {
  margin-bottom: 15px;
}

.development-page footer .container .left-side .navigation li:last-child {
  margin: 0;
}

.development-page footer .container .left-side .navigation li a {
  font-size: 18px;
  font-family: Gosha-r, Verdana;
  color: #161516;
}

.development-page footer .container .social-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
}

.development-page footer .container .social-block a,
.development-page footer .container .social-block .locate {
  font-family: Gosha-r, Verdana;
  color: #161516;
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
}

.development-page footer .container .social-block .social-links {
  display: flex;
  margin: 30px -15px 0;
}

.development-page footer .container .social-block .social-links .single-link {
  margin: 0 15px;
}

@media screen and (max-width: 2000px) {
  .development-page footer .container {
    padding: 0;
    width: calc(100% - 90px);
  }
  .development-page footer .container .left-side {
    margin-left: 230px;
  }
  .development-page footer .container .social-block {
    margin-right: 230px;
  }
}

@media screen and (max-width: 1700px) {
  .development-page footer .container .left-side {
    margin-left: 130px;
  }
  .development-page footer .container .social-block {
    margin-right: 130px;
  }
}

@media screen and (max-width: 1500px) {
  .development-page footer {
    padding: 142px 0 136px;
  }
}

@media screen and (max-width: 1400px) {
  .development-page footer .container .left-side {
    margin-left: 100px;
  }
  .development-page footer .container .social-block {
    margin-right: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .development-page footer .container .left-side,
  .development-page footer .container .social-block {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .development-page footer {
    display: none;
  }
}

.development-page .main-banner {
  margin-bottom: 150px;
  margin-top: 20px;
}

.development-page .main-banner .banner-wrapper {
  min-width: calc(100vw - 90px);
  padding: 0 45px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-height: 780px;
}

.development-page .main-banner .banner-wrapper .left-side {
  padding-left: 180px;
  margin-right: 56px;
  height: 360px;
}

.development-page .main-banner .banner-wrapper .left-side .title, .development-page .main-banner .banner-wrapper .left-side .text, .development-page .main-banner .banner-wrapper .left-side .button {
  color: #161516;
}

.development-page .main-banner .banner-wrapper .left-side .title {
  margin-bottom: 8px;
  margin-left: -2px;
  font-size: 112px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px #161516;
}

.development-page .main-banner .banner-wrapper .left-side .text {
  margin-bottom: 60px;
  font-size: 24px;
  line-height: 1.6;
}

.development-page .main-banner .banner-wrapper .left-side .button {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 25px 34px;
  border: 2px solid #161516;
}

.development-page .main-banner .banner-wrapper .right-side {
  position: relative;
}

.development-page .main-banner .banner-wrapper .right-side .img-holder {
  z-index: -1;
  width: 1174px;
  height: 1174px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.development-page .main-banner .banner-wrapper .right-side .img-holder img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}

.development-page .main-banner .banner-wrapper .right-side .img-holder img.decoration {
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 106%;
  height: 106%;
}

@media screen and (max-width: 1700px) {
  .development-page .main-banner {
    margin-bottom: 130px;
  }
  .development-page .main-banner .banner-wrapper .right-side .img-holder {
    width: 1000px;
    height: 1000px;
  }
}

@media screen and (max-width: 1600px) {
  .development-page .main-banner .banner-wrapper .right-side .img-holder {
    width: 900px;
    height: 900px;
  }
}

@media screen and (max-width: 1500px) {
  .development-page .main-banner .banner-wrapper .left-side {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1400px) {
  .development-page .main-banner .banner-wrapper .left-side {
    padding-left: 0;
  }
  .development-page .main-banner .banner-wrapper .right-side .img-holder {
    width: 800px;
    height: 800px;
  }
}

@media screen and (max-width: 1300px) {
  .development-page .main-banner {
    margin-bottom: 110px;
  }
  .development-page .main-banner .banner-wrapper .left-side .title {
    font-size: 90px;
  }
  .development-page .main-banner .banner-wrapper .left-side .text {
    font-size: 20px;
  }
  .development-page .main-banner .banner-wrapper .right-side .img-holder {
    width: 750px;
    height: 750px;
  }
}

@media screen and (max-width: 1200px) {
  .development-page .main-banner .banner-wrapper {
    min-height: 600px;
  }
  .development-page .main-banner .banner-wrapper .left-side .title {
    font-size: 85px;
  }
  .development-page .main-banner .banner-wrapper .left-side .text {
    font-size: 18px;
  }
  .development-page .main-banner .banner-wrapper .right-side .img-holder {
    right: -25px;
    width: 700px;
    height: 700px;
  }
}

@media screen and (max-width: 991px) {
  .development-page .main-banner {
    margin-bottom: 100px;
  }
  .development-page .main-banner .banner-wrapper {
    min-height: 550px;
    padding: 0;
  }
  .development-page .main-banner .banner-wrapper .left-side {
    height: 250px;
  }
  .development-page .main-banner .banner-wrapper .left-side .title {
    font-size: 70px;
  }
  .development-page .main-banner .banner-wrapper .left-side .text {
    font-size: 16px;
  }
  .development-page .main-banner .banner-wrapper .right-side .img-holder {
    right: -110px;
    width: 650px;
    height: 560px;
  }
}

@media screen and (max-width: 767px) {
  .development-page .main-banner .banner-wrapper {
    flex-direction: column;
    min-height: unset;
    padding: 0;
    align-items: unset;
  }
  .development-page .main-banner .banner-wrapper .left-side {
    height: unset;
    margin: 0;
  }
  .development-page .main-banner .banner-wrapper .right-side .img-holder {
    position: relative;
    right: unset;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100vw;
  }
}

@media screen and (max-width: 575px) {
  .development-page .main-banner {
    margin-bottom: 80px;
  }
  .development-page .main-banner .banner-wrapper .left-side .title {
    font-size: 47px;
  }
  .development-page .main-banner .banner-wrapper .left-side .text {
    font-size: 16px;
  }
  .development-page .main-banner .banner-wrapper .left-side .button {
    width: calc(100% - 72px);
    text-align: center;
  }
}

.development-page .departments {
  margin-bottom: 100px;
  padding: 180px 0;
  background: #fff;
}

.development-page .departments #black-circle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  mix-blend-mode: difference;
  pointer-events: none;
  opacity: 0;
  transition: 0.4s;
}

.development-page .departments .departments-wrapper {
  position: relative;
  width: 1370px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.development-page .departments .departments-wrapper .single-department {
  width: 400px;
  height: 400px;
  border: 2px solid #000;
  padding: 160px 60px 60px;
  display: flex;
  box-sizing: border-box;
}

.development-page .departments .departments-wrapper .single-department .text {
  color: #161516;
}

.development-page .departments .departments-wrapper .single-department .text .count {
  font-size: 18px;
  margin-bottom: 30px;
}

.development-page .departments .departments-wrapper .single-department .text .title {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px;
}

.development-page .departments .departments-wrapper .single-department .text .description {
  font-size: 16px;
}

@media screen and (max-width: 1350px) {
  .development-page .departments {
    margin-bottom: 250px;
  }
  .development-page .departments .departments-wrapper {
    width: 100%;
  }
  .development-page .departments .departments-wrapper .single-department {
    padding: 100px 30px 30px;
    width: 320px;
    height: 320px;
  }
  .development-page .departments .departments-wrapper .single-department .text .count, .development-page .departments .departments-wrapper .single-department .text .title {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .development-page .departments {
    margin-bottom: 200px;
  }
  .development-page .departments .departments-wrapper .single-department {
    padding: 120px 20px 20px;
    width: 300px;
    height: 300px;
  }
  .development-page .departments .departments-wrapper .single-department .text .count, .development-page .departments .departments-wrapper .single-department .text .title {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .development-page .departments {
    margin-bottom: 150px;
  }
  .development-page .departments .departments-wrapper {
    flex-direction: column;
  }
  .development-page .departments .departments-wrapper .single-department {
    padding: 100px 30px 30px;
    width: 100%;
    height: 320px;
    margin-bottom: 30px;
  }
  .development-page .departments .departments-wrapper .single-department .text .count, .development-page .departments .departments-wrapper .single-department .text .title {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 575px) {
  .development-page .departments {
    margin-bottom: 80px;
  }
  .development-page .departments #black-circle {
    display: none;
  }
  .development-page .departments .departments-wrapper .single-department {
    padding: 80px 30px 30px;
    height: 270px;
  }
  .development-page .departments .departments-wrapper .single-department .text .count {
    font-size: 10px;
    margin-bottom: 40px;
  }
  .development-page .departments .departments-wrapper .single-department .text .title {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.development-page .ticker {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 277px;
}

.development-page .ticker .single-row {
  display: flex;
  animation: ticker 100s linear 0s infinite alternate;
  margin-top: 10px;
  width: fit-content;
}

.development-page .ticker .single-row:hover {
  animation-play-state: paused;
}

.development-page .ticker .single-row:nth-child(odd) {
  animation: ticker 90s linear 0s infinite alternate-reverse;
}

.development-page .ticker .single-row:nth-child(odd):hover {
  animation-play-state: paused;
}

.development-page .ticker .single-row:nth-child(3) {
  animation: ticker 150s linear 0s infinite alternate-reverse;
}

.development-page .ticker .single-row:nth-child(3):hover {
  animation-play-state: paused;
}

.development-page .ticker .single-row span {
  font-family: Gosha-b, Verdana;
  font-size: 200px;
  font-weight: 300;
  line-height: 165px;
  -webkit-text-stroke: 1px #161516;
  margin: 0 40px;
  display: inline-block;
  white-space: nowrap;
  text-transform: uppercase;
  transition: 0.5s;
}

.development-page .ticker .single-row span:hover {
  color: #161516;
}

@media screen and (max-width: 1200px) {
  .development-page .ticker .single-row span {
    font-size: 180px;
    line-height: 150px;
  }
}

@media screen and (max-width: 991px) {
  .development-page .ticker .single-row span {
    font-size: 160px;
    line-height: 140px;
  }
}

@media screen and (max-width: 767px) {
  .development-page .ticker {
    height: auto;
    margin: 0 0 170px;
  }
  .development-page .ticker .single-row span {
    font-size: 130px;
    line-height: 120px;
    margin: 0 30px;
  }
}

@media screen and (max-width: 575px) {
  .development-page .ticker {
    margin: 0 0 80px;
  }
  .development-page .ticker .single-row {
    animation: ticker 35s linear 0s infinite alternate;
  }
  .development-page .ticker .single-row span {
    font-size: 80px;
    line-height: 70px;
    margin: 0 20px;
  }
  .development-page .ticker .single-row:nth-child(odd) {
    animation: ticker 50s linear 0s infinite alternate-reverse;
  }
  .development-page .ticker .single-row:nth-child(3) {
    animation: ticker 75s linear 0s infinite alternate-reverse;
  }
}

.development-page .milestones {
  margin-bottom: 140px;
}

.development-page .milestones .title {
  font-size: 72px;
  color: #161516;
  padding-left: 230px;
}

.development-page .milestones .wrapper {
  padding-left: 100px;
  color: #161516;
  height: 640px;
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: scroll;
}

.development-page .milestones .wrapper::-webkit-scrollbar {
  display: none;
}

.development-page .milestones .wrapper .img-holder {
  width: 1617px;
  height: 311px;
  margin: 180px 0 0 auto;
}

.development-page .milestones .wrapper .item {
  display: inline-block;
  width: 200px;
  text-align: center;
  position: absolute;
  pointer-events: none;
  user-select: none;
}

.development-page .milestones .wrapper .item.item-1 {
  top: 220px;
}

.development-page .milestones .wrapper .item.item-1 .num {
  font-size: 56px;
}

.development-page .milestones .wrapper .item.item-2 {
  top: 306px;
}

.development-page .milestones .wrapper .item.item-2 .num {
  font-size: 68px;
}

.development-page .milestones .wrapper .item.item-3 {
  top: 190px;
}

.development-page .milestones .wrapper .item.item-3 .num {
  font-size: 74px;
}

.development-page .milestones .wrapper .item.item-4 {
  top: 300px;
}

.development-page .milestones .wrapper .item.item-4 .num {
  font-size: 96px;
}

.development-page .milestones .wrapper .item.item-5 {
  top: 150px;
}

.development-page .milestones .wrapper .item.item-5 .num {
  font-size: 109px;
}

.development-page .milestones .wrapper .item.item-6 {
  top: 320px;
}

.development-page .milestones .wrapper .item.item-6 .num {
  font-size: 140px;
}

.development-page .milestones .wrapper .item.item-7 {
  top: 60px;
}

.development-page .milestones .wrapper .item.item-7 .num {
  font-size: 162px;
}

.development-page .milestones .wrapper .item .num {
  display: inline-block;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px #161516;
  transition: 0.3s;
}

.development-page .milestones .wrapper .item .text {
  display: inline-block;
  line-height: 1;
  margin-top: 35px;
  font-size: 18px;
}

.development-page .milestones .wrapper .item.filled .num {
  color: #161516;
}

@media screen and (max-width: 1500px) {
  .development-page .milestones .title {
    padding-left: 150px;
    font-size: 60px;
  }
}

@media screen and (max-width: 1400px) {
  .development-page .milestones .title {
    padding-left: 45px;
  }
}

@media screen and (max-width: 991px) {
  .development-page .milestones .title {
    padding-left: 0;
    font-size: 40px;
  }
}

@media screen and (max-width: 575px) {
  .development-page .milestones .title {
    font-size: 20px;
  }
}

.development-page .projects {
  margin-bottom: 285px;
  position: relative;
}

.development-page .projects .click-area {
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  z-index: 10;
}

.development-page .projects .click-area.button-left {
  left: 0;
}

.development-page .projects .click-area.button-right {
  right: 0;
}

.development-page .projects .project-list {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
}

.development-page .projects .project-list::-webkit-scrollbar {
  display: none;
}

.development-page .projects .project-list .project-item {
  overflow: hidden;
  min-width: calc(100vw - 90px);
  padding: 0 45px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.development-page .projects .project-list .project-item .left-side {
  padding-left: 230px;
  margin-right: 56px;
}

.development-page .projects .project-list .project-item .left-side .subtitle {
  display: flex;
  margin-bottom: 60px;
}

.development-page .projects .project-list .project-item .left-side .subtitle p {
  color: #161516;
  font-size: 18px;
}

.development-page .projects .project-list .project-item .left-side .subtitle p:first-child {
  margin-right: 100px;
}

.development-page .projects .project-list .project-item .left-side .title, .development-page .projects .project-list .project-item .left-side .text, .development-page .projects .project-list .project-item .left-side .button {
  color: #161516;
}

.development-page .projects .project-list .project-item .left-side .title {
  margin-bottom: 65px;
  font-size: 60px;
}

.development-page .projects .project-list .project-item .left-side .text {
  margin-bottom: 70px;
  font-size: 16px;
  line-height: 1.6;
}

.development-page .projects .project-list .project-item .left-side .button {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 25px 34px;
  border: 2px solid #161516;
}

.development-page .projects .project-list .project-item .right-side .img-holder {
  width: 820px;
  height: 820px;
  position: relative;
}

.development-page .projects .project-list .project-item .right-side .img-holder img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}

.development-page .projects .project-list .project-item .right-side .img-holder img.decoration {
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 106%;
  height: 106%;
}

@media screen and (max-width: 1500px) {
  .development-page .projects .project-list .project-item .left-side {
    padding-left: 160px;
  }
}

@media screen and (max-width: 1400px) {
  .development-page .projects .project-list .project-item .left-side {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .development-page .projects .project-list .project-item .left-side {
    box-sizing: border-box;
    width: 50%;
    margin-right: 0;
    padding-right: 50px;
  }
  .development-page .projects .project-list .project-item .right-side {
    width: 50%;
  }
  .development-page .projects .project-list .project-item .right-side .img-holder {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .development-page .projects .project-list .project-item .left-side {
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .development-page .projects .project-list .project-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .development-page .projects .project-list .project-item .left-side,
  .development-page .projects .project-list .project-item .right-side {
    width: 100%;
  }
  .development-page .projects .project-list .project-item .left-side {
    margin-top: 55px;
  }
  .development-page .projects .project-list .project-item .left-side .subtitle {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .development-page .projects {
    margin-bottom: 80px;
  }
  .development-page .projects .project-list .project-item {
    padding: 0 24px;
    min-width: calc(100vw - 48px);
  }
  .development-page .projects .project-list .project-item .left-side {
    margin-top: 45px;
  }
  .development-page .projects .project-list .project-item .left-side .title {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .development-page .projects .project-list .project-item .left-side .text {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 18px;
  }
  .development-page .projects .project-list .project-item .left-side .button {
    font-size: 10px;
  }
}

.development-page .customer-review {
  margin-bottom: 210px;
}

.development-page .customer-review .title {
  font-size: 180px;
  color: #161516;
  text-transform: uppercase;
  white-space: nowrap;
  padding-left: 230px;
  margin-bottom: 120px;
}

.development-page .customer-review .main-wrapper {
  position: relative;
}

.development-page .customer-review .main-wrapper:after {
  content: "";
  display: block;
  height: 100%;
  width: 20vw;
  position: absolute;
  left: 80vw;
  top: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 30%, white 100%);
}

.development-page .customer-review .reviews-wrapper {
  padding-left: 230px;
  padding-bottom: 50px;
  overflow-x: scroll;
}

.development-page .customer-review .reviews-wrapper::-webkit-scrollbar {
  display: none;
}

.development-page .customer-review .reviews-wrapper .reviews {
  display: flex;
  width: max-content;
}

.development-page .customer-review .reviews-wrapper .reviews .single-review {
  width: 555px;
  color: #161516;
  margin-right: 130px;
}

.development-page .customer-review .reviews-wrapper .reviews .single-review .avatar {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 25px;
}

.development-page .customer-review .reviews-wrapper .reviews .single-review .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.development-page .customer-review .reviews-wrapper .reviews .single-review .position,
.development-page .customer-review .reviews-wrapper .reviews .single-review .review,
.development-page .customer-review .reviews-wrapper .reviews .single-review .link {
  font-size: 16px;
}

.development-page .customer-review .reviews-wrapper .reviews .single-review .name {
  font-size: 36px;
  margin: 0 0 20px;
}

.development-page .customer-review .reviews-wrapper .reviews .single-review .position {
  margin-bottom: 30px;
}

.development-page .customer-review .reviews-wrapper .reviews .single-review .review {
  margin-bottom: 40px;
}

.development-page .customer-review .reviews-wrapper .reviews .single-review .link {
  color: #161516;
}

@media screen and (max-width: 1500px) {
  .development-page .customer-review .title {
    padding-left: 145px;
  }
  .development-page .customer-review .reviews-wrapper {
    padding-left: 145px;
  }
}

@media screen and (max-width: 1400px) {
  .development-page .customer-review {
    margin-bottom: 200px;
  }
  .development-page .customer-review .title {
    padding-left: 45px;
  }
  .development-page .customer-review .reviews-wrapper {
    padding-left: 45px;
  }
}

@media screen and (max-width: 1200px) {
  .development-page .customer-review .title {
    font-size: 120px;
  }
}

@media screen and (max-width: 991px) {
  .development-page .customer-review {
    margin-bottom: 150px;
  }
  .development-page .customer-review .title {
    padding-left: 0;
    font-size: 100px;
  }
  .development-page .customer-review .reviews-wrapper {
    padding-left: 24px;
  }
  .development-page .customer-review .reviews-wrapper .reviews .single-review {
    width: 400px;
  }
}

@media screen and (max-width: 575px) {
  .development-page .customer-review {
    margin-bottom: 70px;
  }
  .development-page .customer-review .title {
    font-size: 67px;
    margin-bottom: 70px;
  }
  .development-page .customer-review .main-wrapper {
    position: relative;
  }
  .development-page .customer-review .main-wrapper:after {
    display: none;
  }
  .development-page .customer-review .reviews-wrapper .reviews .single-review {
    width: 270px;
    margin-right: 30px;
  }
}
