header {
  position: absolute;
  left: 0;
  top: 48px;
  width: calc(100% - 90px);
  z-index: 1;
  padding: 0 45px;

  .burger-menu {
    width: 46px;
    height: 46px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    z-index: 2;
    //cursor: pointer;

    .rect {
      width: 16px;
      height: 16px;
      position: relative;
      transform: rotate(45deg);
    }

    .dot {
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 50px;
      background: #fff;
      display: block;
      transition: 1s cubic-bezier(0, .36, 1, .63);

      &:nth-child(1) {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(2) {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &:nth-child(3) {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-child(4) {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }

    &.animate-cross {
      .dot {
        &:nth-child(1) {
          height: 100%;
          width: 3px;
        }

        &:nth-child(2) {
          width: 100%;
          height: 3px;
        }

        &:nth-child(3) {
          height: 100%;
          width: 3px;
        }

        &:nth-child(4) {
          width: 100%;
          height: 3px;
        }
      }
    }
  }

  .navigation {
    position: absolute;
    background: $black;
    top: -50px;
    left: 200vw;
    width: 100vw;
    height: calc(100vh + 50px);
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    transform: rotate(-180deg);
    transition: 1s ease-in-out;

    &.show {
      left: 0;
    }

    .logo {
      display: none;
    }

    .close-cross {
      width: 46px;
      height: 46px;
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      bottom: 50px;
      left: 50px;
      z-index: 2;
      box-sizing: border-box;

      &:after,
      &:before {
        content: '';
        position: absolute;
        background: $white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        border-radius: 1px;
      }

      &:after {
        width: 16px;
        height: 4px;
      }

      &:before {
        width: 4px;
        height: 16px;
      }
    }

    .link-wrapper {
      writing-mode: tb;
      margin: 46px 80px 0;

      a {
        font-family: $Gosha-b;
        color: $white;
        font-size: 180px;
        text-transform: capitalize;
        transition: 0.4s;
        position: relative;

        &:after {
          content: '';
          width: 2px;
          position: absolute;
          height: calc(100% + 140px);
          background: $white;
          top: 0;
          right: 0;
        }

        &:hover {
          color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 1px $white;
        }
      }

      &:last-child {
        margin: 46px 80px 0 160px;

        a {
          &:before {
            content: '';
            width: 2px;
            position: absolute;
            height: calc(100% - 100px);
            background: $white;
            top: 0;
            left: -160px;
          }
        }
      }
    }
  }

  @media screen and(max-width: 2000px) {
    .navigation {
      .link-wrapper {
        a {
          font-size: 120px;
        }
      }
    }
  }

  @media screen and(max-width: 1800px) {
    .navigation {
      .link-wrapper {
        margin: 40px 50px 0;

        a {
          font-size: 100px;

          &:after {
            left: -50px;
            height: calc(100% + 80px);
          }

          &:last-child {
            margin: 40px 50px 0;

            a {
              &:before {
                left: -50px;
                height: calc(100% - 60px);
              }
            }
          }
        }

        &:last-child {
          margin: 40px 50px 160px;
          a {
            &:before {
              left: -80px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 1400px) {
      .navigation {
        .link-wrapper {
          a {
            font-size: 80px;
          }
        }
      }
    }

    @media screen and(max-width: 991px) {
      .navigation {
        .link-wrapper {
          margin: 40px 40px 0;

          &:last-child {
            margin: 40px 40px 0;

            a {
              &:before {
                left: -40px;
              }
            }
          }

          a {
            font-size: 60px;
          }
        }
      }
    }

    @media screen and(max-width: 767px) {
      .navigation {
        transform: rotate(0);
        flex-direction: column;

        .close-cross {
          bottom: unset;
          left: unset;
          top: 50px;
          right: 50px;
        }

        .link-wrapper {
          writing-mode: initial;
          margin: 30px 0 30px 50px;

          &:last-child {
            a {
              &:before {
                width: 100%;
                height: 1.5px;
                right: unset;
                left: 0;
                top: unset;
                bottom: -40px;
              }
            }
          }

          a {
            &:after {
              left: 0;
              right: unset;
              width: 120%;
              height: 1px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 575px) {
      top: 30px;
      width: calc(100% - 50px);
      padding: 0 25px;

      .navigation {
        overflow-y: auto;
        top: -30px;

        .logo {
          display: flex;
          margin: 0 0 70px 24px;
        }

        .close-cross {
          top: 28px;
          right: 24px;
        }

        .link-wrapper {
          margin: 20px 0 20px 24px;
          height: 70px;

          a {
            height: 70px;
          }

          &:last-child {
            margin: 20px 0 20px 24px;

            a {
              &:before {
                bottom: -30px;
              }
            }
          }

          a {
            font-size: 48px;
          }

          &:hover {
            a {
              font-size: 58px;
            }
          }
        }
      }
    }
  }
}


.white {
  header {
    .burger-menu {
      border: 2px solid #000;
      .rect{
        .dot {
          background: #000;
        }
      }
    }
  }
}
