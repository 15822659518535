@keyframes glitch-it-before {
  $steps: 15;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(115) + px, 824px, random(115) + px, 0px);
      opacity: 1;
    }
  }
}

@keyframes glitch-it-after {
  $steps: 10;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(115) + px, 824px, random(115) + px, 0px);
      opacity: 1;
    }
  }
}

@keyframes start-show-text {
  0% {
    opacity: 0;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%) scale(0.5);
  }
  7% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(1.2);
  }
  17% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(1);
  }
  100% {
    opacity: 1;
    bottom: 56px;
    left: 45px;
    transform: translate(0, 0) scale(1);
  }
}

@keyframes start-show-text-mobile {
  0% {
    opacity: 0;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%) scale(0.5);
  }
  7% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(1.2);
  }
  17% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(1);
  }
  100% {
    opacity: 1;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%) scale(1);
  }
}

@keyframes changeImg {
  0% {
    opacity: 0.3;
    transform: translateY(-50%) scale(0.99);
  }
  50% {
    transform: translateY(-50%) scale(1.01);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

@keyframes ticker {
  0% {transform: translateX(0%)}
  100% {transform: translateX(-83%)}
}

//image animation
@keyframes image-glitch-before-dev {
  $steps: 20;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(1060) + px, 1196px, random(1060) + px, 0px);
    }
  }
}

@keyframes image-glitch-after-dev {
  $steps: 20;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(1060) + px, 1169px, random(1060) + px, 0px);
    }
  }
}

@keyframes image-glitch-before-des {
  $steps: 20;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(1060) + px, 1239px, random(1060) + px, 0px);
    }
  }
}

@keyframes image-glitch-after-des {
  $steps: 20;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(1060) + px, 1239px, random(1060) + px, 0px);
    }
  }
}

@keyframes image-glitch-before-mar {
  $steps: 20;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(984) + px, 1282px, random(984) + px, 0px);
    }
  }
}

@keyframes image-glitch-after-mar {
  $steps: 20;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(984) + px, 1282px, random(984) + px, 0px);
    }
  }
}

@keyframes image-glitch-before-con {
  $steps: 20;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(997) + px, 1195px, random(997) + px, 0px);
    }
  }
}

@keyframes image-glitch-after-con {
  $steps: 20;
  @for $i from 0 to $steps {
    #{ percentage($i * ( 1 / $steps )) } {
      clip: rect(random(997) + px, 1195px, random(997) + px, 0px);
    }
  }
}
