.about-page {
  background: #fff;
  padding-top: 133px;

  header {
    position: absolute;
    left: 0;
    top: 48px;
    width: calc(100% - 90px);
    z-index: 2;
    padding: 0 45px;

    .logo {
      p {
        font-family: $Gosha-b;
        color: $black;
        font-size: 18px;
      }

      span {
        width: 16px;
        height: 16px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid $black;
        margin-left: 9px;
        margin-bottom: -3px;
      }
    }

    .burger-menu {
      width: 46px;
      height: 46px;
      border: 2px solid $black;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      z-index: 2;

      .rect {
        width: 16px;
        height: 16px;
        position: relative;
        transform: rotate(45deg);
      }

      .dot {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50px;
        background: $black;
        display: block;
        transition: 1s cubic-bezier(0, .36, 1, .63);

        &:nth-child(1) {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(2) {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        &:nth-child(3) {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(4) {
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      &.animate-cross {
        border: 2px solid $white;
        .dot {
          background: $white;
          &:nth-child(1) {
            height: 100%;
            width: 3px;
          }

          &:nth-child(2) {
            width: 100%;
            height: 3px;
          }

          &:nth-child(3) {
            height: 100%;
            width: 3px;
          }

          &:nth-child(4) {
            width: 100%;
            height: 3px;
          }
        }
      }
    }

    .navigation {
      position: absolute;
      background: $black;
      top: -50px;
      left: 200vw;
      width: 100vw;
      height: calc(100vh + 50px);
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      transform: rotate(-180deg);
      transition: 1s ease-in-out;

      &.show {
        left: 0;
      }

      .logo {
        display: none;
      }

      .close-cross {
        width: 46px;
        height: 46px;
        border: 2px solid #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 50px;
        left: 50px;
        z-index: 2;
        box-sizing: border-box;

        &:after,
        &:before {
          content: '';
          position: absolute;
          background: $white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          border-radius: 1px;
        }

        &:after {
          width: 16px;
          height: 4px;
        }

        &:before {
          width: 4px;
          height: 16px;
        }
      }

      .link-wrapper {
        writing-mode: tb;
        margin: 46px 80px 0;

        a {
          font-family: $Gosha-b;
          color: $white;
          font-size: 180px;
          text-transform: capitalize;
          transition: 0.4s;
          position: relative;

          &:after {
            content: '';
            width: 2px;
            position: absolute;
            height: calc(100% + 140px);
            background: $white;
            top: 0;
            right: 0;
          }

          &:hover {
            color: rgba(255, 255, 255, 0);
            -webkit-text-stroke: 1px $white;
          }
        }

        &:last-child {
          margin: 46px 80px 0 160px;

          a {
            &:before {
              content: '';
              width: 2px;
              position: absolute;
              height: calc(100% - 100px);
              background: $white;
              top: 0;
              left: -160px;
            }
          }
        }
      }
    }

    @media screen and(max-width: 2000px) {
      .navigation {
        .link-wrapper {
          a {
            font-size: 120px;
          }
        }
      }
    }

    @media screen and(max-width: 1800px) {
      .navigation {
        .link-wrapper {
          margin: 40px 50px 0;

          a {
            font-size: 100px;

            &:after {
              left: -50px;
              height: calc(100% + 80px);
            }

            &:last-child {
              margin: 40px 50px 0;

              a {
                &:before {
                  left: -50px;
                  height: calc(100% - 60px);
                }
              }
            }
          }

          &:last-child {
            margin: 40px 50px 160px;
            a {
              &:before {
                left: -80px;
              }
            }
          }
        }
      }

      @media screen and(max-width: 1400px) {
        .navigation {
          .link-wrapper {
            a {
              font-size: 80px;
            }
          }
        }
      }

      @media screen and(max-width: 991px) {
        .navigation {
          .link-wrapper {
            margin: 40px 40px 0;

            &:last-child {
              margin: 40px 40px 0;

              a {
                &:before {
                  left: -40px;
                }
              }
            }

            a {
              font-size: 60px;
            }
          }
        }
      }

      @media screen and(max-width: 767px) {
        .navigation {
          transform: rotate(0);
          flex-direction: column;

          .close-cross {
            bottom: unset;
            left: unset;
            top: 50px;
            right: 50px;
          }

          .link-wrapper {
            writing-mode: initial;
            margin: 30px 0 30px 50px;

            &:last-child {
              a {
                &:before {
                  width: 100%;
                  height: 1.5px;
                  right: unset;
                  left: 0;
                  top: unset;
                  bottom: -40px;
                }
              }
            }

            a {
              &:after {
                left: 0;
                right: unset;
                width: 120%;
                height: 1px;
              }
            }
          }
        }
      }

      @media screen and(max-width: 575px) {
        top: 30px;
        width: calc(100% - 50px);
        padding: 0 25px;

        .navigation {
          overflow-y: auto;
          top: -30px;

          .logo {
            display: flex;
            margin: 0 0 70px 24px;
          }

          .close-cross {
            top: 28px;
            right: 24px;
          }

          .link-wrapper {
            margin: 20px 0 20px 24px;
            height: 70px;

            a {
              height: 70px;
            }

            &:last-child {
              margin: 20px 0 20px 24px;

              a {
                &:before {
                  bottom: -30px;
                }
              }
            }

            a {
              font-size: 48px;
            }

            &:hover {
              a {
                font-size: 58px;
              }
            }
          }
        }
      }
    }
  }

  .title-page {
    font-size: 105px;
    color: $black;
    font-family: Gosha-b, Verdana;
    padding-left: 222px;
    letter-spacing: 2px;
    @media screen and(max-width: 1600px) {
      padding-left: 200px;
      font-size: 80px;
    }
    @media screen and(max-width: 1500px) {
      padding-left: 150px;
    }
    @media screen and(max-width: 1400px) {
      padding-left: 100px;
    }
    @media screen and(max-width: 1300px) {
      padding-left: 80px;
    }
    @media screen and(max-width: 1200px) {
      padding-left: 0;
    }
    @media screen and(max-width: 991px) {
      max-width: 100%;
      font-size: 60px;
    }
  }

  .contact-form {
    width: 100vw;

    .container {
      padding: 272px 0;
      background: $black;

      .inner-wrapper {
        flex-wrap: wrap;
        padding: 0 230px;
        width: 100%;
      }
    }

    .title {
      color: $white;
      font-size: 36px;
      font-family: $Gosha-b;
      letter-spacing: 0;
      line-height: 49px;
    }

    .feedback-wrapper {
      width: 100%;
      display: flex;
      color: $black;
      margin-top: 61px;
      justify-content: space-between;

      .form {
        display: flex;
        flex-wrap: wrap;
        width: 904px;
        margin: 7px -20px 0;

        .input-holder {
          position: relative;
          height: 40px;
          display: flex;
          margin: 0 20px;
          width: calc(33.33% - 40px);

          label {
            position: absolute;
            left: 0;
            bottom: 12px;
            font-family: $Gosha-r;
            font-size: 18px;
            transition: 0.3s;
            color: $white;
          }

          input {
            color: $white;
            background: $black;
            border: none;
            border-bottom: 2px solid $white;
            margin: auto 0 0 0;
            width: 100%;
            font-family: $Gosha-r;
            font-size: 18px;
            &.itsFull {
              ~ label {
                font-size: 12px;
                bottom: 25px;
              }
            }
            &:focus {
              ~ label {
                font-size: 12px;
                bottom: 25px;
              }
            }
          }

          &.textarea {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 77px;

            textarea {
              outline: none;
              color: $white;
              background: $black;
              width: 100%;
              border: none;
              border-bottom: 2px solid $white;
              font-family: $Gosha-r;
              font-size: 18px;
              &.itsFull {
                ~ label {
                  font-size: 12px;
                  bottom: 45px;
                }
              }
              &:focus {
                ~ label {
                  font-size: 12px;
                  bottom: 45px;
                }
              }
            }
          }

          &.button {
            width: 150px;
            input {
              display: none;
            }

            label {
              position: unset;
              color: $white;
              text-transform: uppercase;
              font-family: $Gosha-b;
              font-size: 16px;
              letter-spacing: 2px;
              cursor: none;
            }

            border: none;
            padding: 0;

            &:hover {
              background: none;
              color: $black;
            }
          }
        }

        .button {
          color: $white;
          border: 2px solid $white;
          text-transform: uppercase;
          font-family: $Gosha-b;
          font-size: 16px;
          margin: 0 20px;
          letter-spacing: 3.9px;
          display: flex;
          align-items: center;
          padding: 0 30px;
          height: 68px;
          background: inherit;
          transition: 0.5s;
          cursor: none;

          &:hover {
            background: $black;
            color: $white
          }

          &.upload {
            border: none;
            padding: 0;
          }
        }
      }

      .contacts {
        width: 324px;

        .text {
          color: $white;
          font-size: 16px;
          font-family: $Gosha-r;
          letter-spacing: -0.32px;
          margin-bottom: 34px;
          line-height: 23px;
        }

        a,
        .locate {
          color: $white;
          font-size: 16px;
          font-family: $Gosha-b;
          letter-spacing: 0;
          margin-bottom: 10px;
          display: block;
        }

        .social {
          margin: 34px 0 18px;
        }

        .social-links {
          display: flex;
          margin: 0 -11px;

          .single-link {
            margin: 0 11px;
          }
        }
      }
    }

    @media screen and(max-width: 1800px) {
      .feedback-wrapper {
        .form {
          width: 850px;
        }

        .contacts {
          width: 240px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .inner-wrapper {
          padding: 0 180px;
        }
      }

      .feedback-wrapper {
        .form {
          width: 800px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .inner-wrapper {
          padding: 0 130px;
        }
      }
    }

    @media screen and(max-width: 1400px) {
      height: auto;

      .container {
        padding: 202px 0 217px;

        .inner-wrapper {
          padding: 0 100px;

          .title {
            font-size: 30px;
          }
        }
      }

      .feedback-wrapper {
        .form {
          width: 750px;
        }

        .contacts {
          margin-top: 14px;
          width: 268px;
        }
      }
    }

    @media screen and(max-width: 1300px) {
      .container {
        position: relative;
        padding: 202px 20px 217px;
        max-width: calc(100% - 130px);

        &:after {
          content: '';
          display: block;
          width: calc(100vw - 90px);
          height: calc(100% + 1px);
          background: $white;
          position: absolute;
          top: -1px;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
        .inner-wrapper {
          padding: 0;

          .title {
            br {
              display: none;
            }
          }
        }
      }

      .feedback-wrapper {
        .form {
          width: calc(100% - 268px);
        }

        .contacts {
          margin-top: 40px;
        }
      }
    }

    @media screen and(max-width: 991px) {
      .feedback-wrapper {
        flex-direction: column;

        .form {
          width: calc(100% + 40px);
        }
      }
    }
    @media screen and(max-width: 767px) {
      .feedback-wrapper {
        .form {
          width: 100%;
          margin: 0;

          .input-holder {
            width: 100%;
            margin: 0 0 40px;
          }

          .button {
            margin: 0 40px 0 0;
          }
        }
      }
    }

    @media screen and(max-width: 575px) {
      background: $black;

      .container {
        max-width: calc(100% - 48px);
        padding: 115px 0 90px;

        &:after {
          width: 100vw;
          top: 0;
        }
      }

      .title {
        font-size: 30px;
      }

      .feedback-wrapper {
        margin-top: 60px;

        .contacts {
          width: 100%;
        }

        .form {
          flex-direction: column;
          align-items: center;

          .button {
            width: 100%;
            align-items: center;
            justify-content: center;
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }

  footer {
    padding: 167px 0 170px;
    background: $white;

    .container {
      justify-content: space-between;
      padding: 0 470px;
      width: calc(100% - 940px);

      .left-side {
        display: flex;

        .logo-copyright {
          padding-right: 94px;
          position: relative;

          &:after {
            position: absolute;
            content: '';
            display: block;
            width: 2px;
            height: 100%;
            background-color: $black;
            right: 0;
            bottom: 0;
          }
          p {
            margin-top: 50px;
            font-size: 16px;
            font-family: $Gosha-r;
          }
          .logo {
            font-weight: 600;
            p {
              color: $black;
              margin: 0;
              font-size: 32px;
              letter-spacing: 0.7px;
            }

            span {
              width: 28px;
              height: 28px;
              border: 2px solid $black;
              margin-bottom: -7px;
            }
          }
        }

        .navigation {
          margin-left: 90px;
          margin-top: 5px;

          li {
            margin-bottom: 15px;

            &:last-child {
              margin: 0;
            }

            a {
              font-size: 18px;
              font-family: $Gosha-r;
              color: $black;
            }
          }
        }
      }

      .social-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 5px;

        a,
        .locate {
          font-family: $Gosha-r;
          color: $black;
          font-size: 18px;
          display: block;
          margin-bottom: 15px;
        }

        .social-links {
          display: flex;
          margin: 30px -15px 0;

          .single-link {
            margin: 0 15px;
          }
        }

      }
    }

    @media screen and(max-width: 2000px) {
      .container {
        padding: 0;
        width: calc(100% - 90px);

        .left-side {
          margin-left: 230px;
        }

        .social-block {
          margin-right: 230px;
        }
      }
    }

    @media screen and(max-width: 1700px) {
      .container {
        .left-side {
          margin-left: 130px;
        }

        .social-block {
          margin-right: 130px;
        }
      }
    }

    @media screen and(max-width: 1500px) {
      padding: 142px 0 136px;
    }

    @media screen and(max-width: 1400px) {
      .container {
        .left-side {
          margin-left: 100px;
        }

        .social-block {
          margin-right: 100px;
        }
      }
    }

    @media screen and(max-width: 1300px) {
      .container {
        .left-side,
        .social-block {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    @media screen and(max-width: 991px) {
      display: none;
    }
  }

  .main-photo {
    position: relative;
    margin-top: 50px;
    margin-bottom: 270px;
    .decoration-title {
      position: absolute;
      color: rgba(0,0,0,0);
      font-size: 380px;
      font-family: Gosha-b, Verdana;
      letter-spacing: 2px;
      right: -50px;
      bottom: -100px;
      -webkit-text-stroke: 2px #FFED00;
    }
    .img-holder {
      width: 1645px;
      height: 796px;
      margin-left: 267px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @media screen and(max-width: 1700px) {
      .img-holder {
        margin-left: 245px;
      }
    }
    @media screen and(max-width: 1500px) {
      .img-holder {
        margin-left: 200px;
      }
    }
    @media screen and(max-width: 1400px) {
      .img-holder {
        margin-left: 150px;
      }
    }
    @media screen and(max-width: 1300px) {
      margin-bottom: 230px;
      .img-holder {
        margin-left: 130px;
        width: calc(100% - 130px);
        height: calc((100% - 130px)/2);
      }
      .decoration-title {
        font-size: 300px;
      }
    }
    @media screen and(max-width: 1200px) {
      .img-holder {
        margin-left: 45px;
        width: calc(100% - 45px);
        height: calc((100% - 45px)/2);
      }
      .decoration-title {
        font-size: 250px;
      }
    }
    @media screen and(max-width: 991px) {
      .decoration-title {
        font-size: 200px;
        bottom: -60px;
        -webkit-text-stroke: 1px #FFED00;
      }
    }
    @media screen and(max-width: 767px) {
      .decoration-title {
        font-size: 150px;
        bottom: -45px;
      }
    }
    @media screen and(max-width: 575px) {
      margin-bottom: 100px;

      .img-holder {
        margin-left: 25px;
        width: calc(100% - 25px);
        height: calc((100% - 25px)/2);
      }

      .decoration-title {
        font-size: 110px;
        bottom: -30px;
        right: -25px;
      }
    }
    @media screen and(max-width: 425px) {
      .decoration-title {
        font-size: 67px;
        bottom: -20px;
        right: -15px;
      }
    }
  }

  .short-desc {
    margin-bottom: 282px;
    .container {
      padding-left: 222px;
    }
    .title {
      font-size: 72px;
      color: $black;
      font-family: Gosha-b, Verdana;
      letter-spacing: 2px;
      margin-bottom: 80px;
    }
    .list {
      display: flex;
      justify-content: space-between;
      .list-item {
        color: $black;
        width: 410px;
        margin-right: 100px;
        .number {
          font-size: 18px;
          margin: 0 0 33px;
          line-height: 1;
        }
        .item-title {
          font-size: 40px;
          margin: 0 0 27px;
          line-height: 1;
        }
        .text {
          font-size: 16px;
          margin: 0;
          line-height: 24px;
        }
      }
    }
    @media screen and(max-width: 1700px) {
      .list {
        .list-item {
          width: 360px;
        }
      }
    }
    @media screen and(max-width: 1600px) {
      .container {
        padding-left: 200px;
      }
      .list {
        justify-content: flex-start;
        .list-item {
          margin-right: 80px;
          width: calc((100% - 440px) / 3);
        }
      }
    }
    @media screen and(max-width: 1500px) {
      .container {
        padding-left: 150px;
      }
      .list {
        .list-item {
          margin-right: 58px;
          width: calc((100% - 240px) / 3);
        }
      }
    }
    @media screen and(max-width: 1400px) {
      .container {
        padding-left: 100px;
      }
      .list {
        .list-item {
          margin-right: 40px;
          width: calc((100% - 220px) / 3);
        }
      }
    }
    @media screen and(max-width: 1300px) {
      .container {
        padding-left: 80px;
      }
      .list {
        .list-item {
          margin-right: 40px;
          width: calc((100% - 200px) / 3);
          .item-title {
            font-size: 35px;
          }
        }
      }
    }
    @media screen and(max-width: 1200px) {
      .container {
        padding-left: 0;
      }
      .list {
        justify-content: space-between;
        .list-item {
          margin-right: 0;
          width: calc((100% - 90px) / 3);
        }
      }
    }
    @media screen and(max-width: 991px) {
      .title {
        margin-bottom: 50px;
        font-size: 60px;
      }
      .list {
        flex-direction: column;
        .list-item {
          margin-bottom: 30px;
          width: 50%;
        }
      }
    }
    @media screen and(max-width: 767px) {
      .title {
        font-size: 45px;
      }
      .list {
        .list-item {
          margin-bottom: 30px;
          width: 80%;
        }
      }
    }
    @media screen and(max-width: 575px) {
      margin-bottom: 100px;
      .title {
        margin-bottom: 50px;
        font-size: 30px;
      }
      .list {
        .list-item {
          width: 100%;
          margin-bottom: 40px;
          .number {
            margin-bottom: 25px;
            font-size: 10px;
          }
          .item-title {
            margin-bottom: 25px;
            font-size: 20px;
          }
          .text {
            font-size: 16px;
          }
        }
      }
    }
  }

  .our-team {
    .container {
      padding-left: 222px;
    }
    .title {
      font-size: 72px;
      color: $black;
      font-family: Gosha-b, Verdana;
      letter-spacing: 2px;
      margin-bottom: 80px;
    }

    .team-list-wrapper {
      position: relative;
      overflow: hidden;
      min-height: 882px;
      width: 100%;
      .click-area {
        position: absolute;
        width: 10%;
        height: 100%;
        top: 0;
        box-sizing: border-box;
        z-index: 10;
        &.button-left {
          left: 0;
        }
        &.button-right {
          right: 0;
        }
      }
      .team-list {
        transition: .3s;
        user-select: none;
        overflow-x: scroll;
        top: 0;
        left: 0;
        display: flex;
        max-height: 772px;
        padding-bottom: 100px;
        padding-left: 272px;
        padding-right: 50px;
        &::-webkit-scrollbar {
          display: none;
        }
        .member {
          pointer-events: none;
          position: relative;
          margin: 0 25px;
          width: 434px;
          height: 576px;
          transition: 0.4s;
          &:first-child {
            width: 514px;
            height: 682px;
            .img-holder {
              width: 514px;
              height: 682px;
            }
          }
          &:nth-child(3) {
            margin-top: 100px;
          }
          .img-holder {
            width: 434px;
            height: 576px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .text-wrapper {
            position: absolute;
            bottom: -32px;
            transform: translateY(100%);
            left: 0;
            h6,p {
              color: $black;
              margin: 0;
              padding: 0;
              line-height: 1;
            }
            h6 {
              font-weight: 600;
              font-size: 30px;
              margin-bottom: 14px;
            }
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
    @media screen and(max-width: 1700px) {
      .container {
        padding-left: 200px;
      }
      .team-list-wrapper {
        .team-list {
          padding-left: 220px;
        }
      }
    }
    @media screen and(max-width: 1500px) {
      .container {
        padding-left: 150px;
      }
      .team-list-wrapper {
        .team-list {
          padding-left: 170px;
        }
      }
    }
    @media screen and(max-width: 1400px) {
      .container {
        padding-left: 100px;
      }
      .team-list-wrapper {
        .team-list {
          padding-left: 120px;
        }
      }
    }
    @media screen and(max-width: 1300px) {
      .container {
        padding-left: 80px;
      }
      .team-list-wrapper {
        .team-list {
          padding-left: 100px;
        }
      }
    }
    @media screen and(max-width: 1200px) {
      .container {
        padding-left: 0;
      }
      .team-list-wrapper {
        .team-list {
          padding-left: 25px;
        }
      }
    }
    @media screen and(max-width: 991px) {
      .title {
        font-size: 60px;
        margin-bottom: 60px;
      }
      .team-list-wrapper {
        min-height: 740px;
        .team-list {
          .member {
            width: 434px;
            height: 576px;

            &:first-child {
              width: 434px;
              height: 576px;

              .img-holder {
                width: 434px;
                height: 576px;
              }
            }

            &:nth-child(3) {
              margin-top: 0;
            }
          }
        }
      }
    }
    @media screen and(max-width: 767px) {
      .title {
        font-size: 60px;
        margin-bottom: 60px;
      }
      .team-list-wrapper {
        min-height: 640px;
        .team-list {
          .member {
            width: 350px;
            height: 450px;

            .img-holder {
              width: 350px;
              height: 450px;
            }

            &:first-child {
              width: 350px;
              height: 450px;

              .img-holder {
                width: 350px;
                height: 450px;
              }
            }
          }
        }
      }
    }
    @media screen and(max-width: 575px) {
      .title {
        font-size: 30px;
        margin-bottom: 60px;
      }
      .team-list-wrapper {
        min-height: 500px;
        .team-list {
          margin: 0 -15px;
          .member {
            width: 250px;
            height: 363px;
            margin: 0 15px;

            .img-holder {
              width: 250px;
              height: 363px;
            }

            &:first-child {
              width: 250px;
              height: 363px;

              .img-holder {
                width: 250px;
                height: 363px;
              }
            }
          }
        }
      }
    }
  }

  .numbers {
    margin: 80px 0 200px;
    display: flex;
    align-items: center;
    .img-holder {
      width: 850px;
      height: 850px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .decoration-title {
        position: absolute;
        color: rgba(0,0,0,0);
        font-size: 280px;
        font-family: Gosha-b, Verdana;
        letter-spacing: 2px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 100px;
        -webkit-text-stroke: 2px #FFED00;
      }
    }
    .content-wrapper {
      margin-left: 80px;
      width: 780px;
      .title {
        font-size: 72px;
        color: $black;
        font-family: Gosha-b, Verdana;
        letter-spacing: 2px;
        margin-bottom: 100px;
      }
      .num-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;
        height: 300px;
        justify-content: space-between;
        align-content: space-between;
        .num-item {
          width: 200px;
          h6,p {
            color: $black;
            margin: 0;
            padding: 0;
            line-height: 1;
          }
          h6 {
            font-size: 48px;
            margin-bottom: 35px;
            span {
              font-size: 30px;
            }
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
    @media screen and(max-width: 1700px) {
      padding-right: 30px;
      .content-wrapper {
        margin-left: 30px;
        width: unset;
      }
    }
    @media screen and(max-width: 1400px) {
      .img-holder {
        .decoration-title {
          font-size: 200px;
          bottom: 130px;
        }
      }
    }
    @media screen and(max-width: 1300px) {
      display: flex;
      flex-wrap: wrap;
      .content-wrapper {
        margin-top: 50px;
      }
      .img-holder {
        .decoration-title {
          font-size: 180px;
          transform: unset;
          left: 30px;
          bottom: 60px;
        }
      }
    }
    @media screen and(max-width: 991px) {
      .img-holder {
        width: 100%;
        height: 100vw;
        .decoration-title {
          bottom: 100px;
        }
      }
    }
    @media screen and(max-width: 767px) {
      .img-holder {
        .decoration-title {
          bottom: 100px;
          font-size: 200px;
        }
      }
      .content-wrapper {
        .num-list {
          height: 420px;
        }
      }
    }
    @media screen and(max-width: 575px) {
      margin-bottom: 70px;

      .img-holder {
        .decoration-title {
          bottom: 50px;
          font-size: 100px;
        }
      }
      .content-wrapper {
        .title {
          font-size: 30px;
          margin-bottom: 60px;
        }
        .num-list {
          height: unset;
          .num-item {
            margin-bottom: 55px;
            h6 {
              font-size: 48px;
              margin-bottom: 35px;
              span {
                font-size: 30px;
              }
            }
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .projects {
    margin-bottom: 285px;
    position: relative;
    .click-area {
      position: absolute;
      top: 0;
      width: 10%;
      height: 100%;
      z-index: 10;
      &.button-left {
        left: 0;
      }
      &.button-right {
        right: 0;
      }
    }
    .project-list {
      display: flex;
      justify-content: space-between;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .project-item {
        overflow: hidden;
        min-width: calc(100vw - 90px);
        padding: 0 45px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        .left-side {
          padding-left: 230px;
          margin-right: 56px;
          .subtitle {
            display: flex;
            margin-bottom: 60px;
            p {
              color: $black;
              font-size: 18px;
              &:first-child {
                margin-right: 100px;
              }
            }
          }
          .title, .text, .button {
            color: $black;
          }
          .title {
            margin-bottom: 65px;
            font-size: 60px;
          }
          .text {
            margin-bottom: 70px;
            font-size: 16px;
            line-height: 1.6;
          }
          .button {
            display: inline-block;
            font-size: 16px;
            line-height: 1;
            font-weight: 600;
            letter-spacing: 3px;
            text-transform: uppercase;
            padding: 25px 34px;
            border: 2px solid $black;
          }
        }
        .right-side {
          .img-holder {
            width: 820px;
            height: 820px;
            position: relative;
            img {
              position: relative;
              width: 100%;
              height: 100%;
              object-fit: contain;
              z-index: 1;
              &.decoration {
                z-index: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 106%;
                height: 106%;
              }
            }
          }
        }
      }
    }
    @media screen and(max-width: 1500px) {
      .project-list {
        .project-item {
          .left-side {
            padding-left: 160px;
          }
        }
      }
    }
    @media screen and(max-width: 1400px) {
      .project-list {
        .project-item {
          .left-side {
            padding-left: 100px;
          }
        }
      }
    }
    @media screen and(max-width: 1300px) {
      .project-list {
        .project-item {
          .left-side {
            box-sizing: border-box;
            width: 50%;
            margin-right: 0;
            padding-right: 50px;
          }
          .right-side {
            width: 50%;
            .img-holder {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    @media screen and(max-width: 1200px) {
      .project-list {
        .project-item {
          .left-side {
            padding-left: 0;
          }
        }
      }
    }
    @media screen and(max-width: 991px) {
      .project-list {
        .project-item {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column-reverse;
          .left-side,
          .right-side {
            width: 100%;
          }
          .left-side {
            margin-top: 55px;
            .subtitle {
              display: none;
            }
          }
        }
      }
    }
    @media screen and(max-width: 575px) {
      margin-bottom: 80px;
      .project-list {
        .project-item {
          padding: 0 24px;
          min-width: calc(100vw - 48px);
          .left-side {
            margin-top: 45px;
            .title {
              font-size: 30px;
              margin-bottom: 40px;
            }
            .text {
              font-size: 16px;
              margin-bottom: 30px;
              line-height: 18px;
            }
            .button {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
