.our-works {
  width: 100vw;
  margin: 0 auto;
  height: 3000px;
  position: relative;
  overflow: hidden;
  background: $black;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    &.active {
      position: fixed;
    }
  }

  .img-holder {
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;

    img {
      height: 98%;
      margin-top: 1px;
      object-fit: cover;
    }

    &.mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition: 1s transform;
      will-change: transform, top;
      transform: translate3d(0,0,0);

      svg,
      img {
        height: 100%;
        margin-top: 0;
        object-fit: cover;
      }
    }
  }

  @media screen and(max-width: 1900px) {
    height: 2500px;
  }

  @media screen and(max-width: 1799px) {
    height: 2450px;
  }

  @media screen and(max-width: 1699px) {
    height: 2400px;
  }

  @media screen and(max-width: 1599px) {
    height: 2350px;
  }

  @media screen and(max-width: 1499px) {
    height: 2250px;
  }

  @media screen and(max-width: 1399px) {
    height: 2150px;
  }

  @media screen and(max-width: 1299px) {
    height: 2000px;
  }

  @media screen and(max-width: 1199px) {
    height: 1900px;
  }

  @media screen and(max-width: 1099px) {
    height: 1900px;
  }


  @media screen and(max-width: 991px) {
    height: 1800px;
  }

  @media screen and(max-width: 767px) {
    height: 2000px;
    margin-bottom: 150px;
  }

  @media screen and(max-width: 575px) {
  }
}

